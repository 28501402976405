import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { toDecimal } from '../../helper/calculation.helper';

@Injectable({
    providedIn: 'root',
})
export class CalculationValuesValidator {

    public maxInstalment(biggerOrEqual: boolean = true): ValidatorFn {
        return (formGroup: UntypedFormGroup) => {
            if (!formGroup.parent) {
                return null;
            }
            // @ts-ignore
            const totalValue = formGroup.parent.parent.controls?.basic.get('total_leasing_value')?.value;
            const instalmentValue = formGroup?.value;

            if (!totalValue || !instalmentValue) {
                return null;
            }

            if (instalmentValue < totalValue) {
                return null;
            }

            if (instalmentValue > totalValue && !biggerOrEqual) {
                return { maxInstalment: true };
            }

            if (instalmentValue >= totalValue && biggerOrEqual) {
                return { maxInstalment: true };
            }

            return null;
        };
    }

    public maxDownPayment(maxValue: number): ValidatorFn {
        return (formGroup: UntypedFormGroup) => {
            if (!formGroup.parent) {
                return null;
            }
            // @ts-ignore
            const totalValue = formGroup.parent.parent.controls?.basic.get('total_leasing_value')?.value;
            const downPaymentValue = formGroup?.value;
            const maxDownPaymentValue = toDecimal(
                ((maxValue) * totalValue), 2);

            if (!totalValue || !downPaymentValue) {
                return null;
            }

            if (downPaymentValue > maxDownPaymentValue) {
                return { maxDownPayment: true };
            }

            return null;
        };
    }

    public minResidualValue(minValue: number): ValidatorFn {
        return (formGroup: UntypedFormGroup) => {
            if (!formGroup.parent) {
                return null;
            }
            // @ts-ignore
            const totalValue = formGroup.parent.parent.controls?.basic.get('total_leasing_value')?.value;
            const residualValue = formGroup?.value;
            const minResidualValue = toDecimal(
                ((minValue) * totalValue), 2);

            if (!totalValue || !residualValue) {
                return null;
            }

            if (residualValue < minResidualValue) {
                return { minResidualValue: true };
            }

            return null;
        };
    }

}
