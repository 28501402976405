/* libs/quote/src/Components/calculation-form/CalculationFormComponent.scss */
:host .function-resolve span {
  font-size: 14px;
  margin-right: 16px;
}
:host .no-margin {
  margin-bottom: 0 !important;
}
:host mat-radio-button {
  margin-right: 16px;
}
:host l7-summary {
  max-height: 54px;
  padding: 8px;
}
/*# sourceMappingURL=CalculationFormComponent.css.map */
