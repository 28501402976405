/**
 *    Component with (open) quotes belonging to a specific lessee
 *    Lessee UUID must be provided on initial lifecycle to receive the quotes on load.
 */
import { ILeasingQuoteOverviewDto, ILeasingQuoteOverviewVersionDto } from '@abcfinlab/api/global';
import { BusyBoxComponent } from '@abcfinlab/ui';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { concatMap, finalize } from 'rxjs/operators';
import { FetchQuoteByUUID, SetQuoteID } from '../../../../../apps/l7/src/app/actions/Quote.actions';
import { LeasingQuoteOverviewDTO } from '../../../../../apps/l7/src/app/models/LeasingQuoteOverviewDTO.interface';
import { LeasingQuoteOverviewVersionDTO } from '../../../../../apps/l7/src/app/models/LeasingQuoteOverviewVersionDTO.interface';
import { DialogService } from '../../../../../apps/l7/src/app/private/services/dialog/dialog.service';
import { LesseeService } from '../../../../../apps/l7/src/app/private/services/lessee/lessee.service';
import { QuoteService } from '../../../../../apps/l7/src/app/private/services/quote/quote.service';
import { DeleteConfirmationDialogComponent } from '../../../../../apps/l7/src/app/shared/modals/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { QuoteState } from '../../../../../apps/l7/src/app/state/Quote.state';

@UntilDestroy()
@Component({
    selector: 'l7-quote-list',
    templateUrl: './quote-list.component.html',
    styleUrls: ['./quote-list.component.scss'],
    standalone: false,
})
export class QuoteListComponent implements OnInit {

    @Input() overviewDto: ILeasingQuoteOverviewDto;
    public activePanel: number = 0;

    public activeView: 'CARD' | 'TABLE' = 'TABLE';

    public quoteList$: Observable<LeasingQuoteOverviewDTO>;

    public isMobile: boolean;

    public constructor(
        private readonly _quoteService: QuoteService,
        private readonly _router: Router,
        private readonly _store: Store,
        private readonly _lesseeService: LesseeService,
        private readonly _dialog: DialogService,
        private readonly _breakPointObserver: BreakpointObserver,
        private readonly _activatedRoute: ActivatedRoute,
    ) {
        this.quoteList$ = this._store.select(QuoteState.getQuotesListForLessee);
    }

    public ngOnInit(): void {
        const message = 'Bitte haben Sie einen Moment Geduld.';
        const config = {
            closeOnNavigation: true,
            disableClose: true,
            width: '400px',
            minHeight: '200px',
        };
        const data = {
            title: '',
            message: message,
            options: null,
        };
        const dialogRef = this._dialog.openDialog(BusyBoxComponent, config, data);
        const quoteList$ = this._quoteService.fetchQuotesListByLesseeUUID(this._lesseeService.getSelectedLesseeID());
        this._quoteService.resetSelectedQuoteID().pipe(
            concatMap(() => quoteList$),
            finalize(() => dialogRef.close()),
            untilDestroyed(this),
        ).subscribe();
        this._breakPointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
            .pipe(untilDestroyed(this))
            .subscribe((x) => {
                if (x.matches) {
                    this.isMobile = true;
                    this.activeView = 'CARD';
                } else {
                    this.isMobile = false;
                    this.activeView = 'TABLE';
                }
            });
    }

    /**
* Open the detail view for a selected quote. Sets UUID of quote in quote-store.
* @param listItem    The quote list item DTO
*/
    public goToDetailView(listItem: LeasingQuoteOverviewVersionDTO | ILeasingQuoteOverviewVersionDto): void {
        this._store.dispatch(new FetchQuoteByUUID(listItem.id, listItem.quote_calculation.contract_type))
            .pipe(untilDestroyed(this))
            .subscribe((x) => {
                this._store.dispatch(new SetQuoteID(listItem.id));
                void this._router.navigate(['../detail'], {
                    relativeTo: this._activatedRoute,
                });
            });
    }

    /**
     * Open the vendor search page for a selected quote. Sets UUID of quote in quote-store.
     * @param listItem    The quote list item DTO
     */
    public goToVendorSearch(listItem: LeasingQuoteOverviewVersionDTO | ILeasingQuoteOverviewVersionDto): void {
        this._store.dispatch(new FetchQuoteByUUID(listItem.id, listItem.quote_calculation.contract_type))
            .pipe(untilDestroyed(this))
            .subscribe((x) => {
                void this._router.navigate(['../search-vendor'], {
                    relativeTo: this._activatedRoute,
                });
            });
    }

    public deleteQuote(quoteId: string): void {
        const dialogConfig = {
            autoFocus: true,
            closeOnNavigation: false,
            disableClose: false,
            width: '45wv',
            minHeight: '230px',
        };

        const data = {
            quoteId: quoteId,
            deleteType: 'ALL_VERSIONS',
        };

        this._dialog.openDialog(DeleteConfirmationDialogComponent, dialogConfig, data);
    }

    public changeViewType(type: 'CARD' | 'TABLE'): void {
        this.activeView = type;
    }

}
