/* libs/quote/src/Components/quote-versions-table/quote-versions-table.component.scss */
:host l7-quote-version-menu {
  width: 100%;
}
:host .mat-mdc-cell:nth-child(1),
:host .mat-mdc-header-cell:nth-child(1) {
  flex: 0 0 14%;
}
:host .mat-mdc-cell:nth-child(2),
:host .mat-mdc-header-cell:nth-child(2),
:host .mat-mdc-cell:nth-child(4),
:host .mat-mdc-header-cell:nth-child(4),
:host .mat-mdc-cell:nth-child(6),
:host .mat-mdc-header-cell:nth-child(6) {
  flex: 0 0 10%;
}
:host .mat-mdc-cell:nth-child(3),
:host .mat-mdc-header-cell:nth-child(3) {
  flex: 0 0 20%;
}
:host .mat-mdc-cell:nth-child(5),
:host .mat-mdc-header-cell:nth-child(5) {
  flex: 0 0 10%;
}
:host .mat-mdc-cell:nth-child(7),
:host .mat-mdc-header-cell:nth-child(7),
:host .mat-mdc-cell:nth-child(8),
:host .mat-mdc-header-cell:nth-child(8) {
  flex: 0 0 6%;
}
:host mat-cell:last-of-type {
  justify-content: flex-end;
}
/*# sourceMappingURL=quote-versions-table.component.css.map */
