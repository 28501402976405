import ObjectMapper from 'object-mapper';
import { InsuranceType } from '../enums/InsuranceType.enum';

const map = {
    contract_type: 'contract_type',
    dealer_commission: 'dealer_commission',
    dealer_commission_percent: {
        key: 'dealer_commission_percent',
        default: 0,
    },
    down_payment: 'down_payment',
    handling_fee: 'handling_fee',
    handling_fee_value: 'handling_fee_value',
    instalment: 'instalment',
    first_instalment: {
        key: 'first_instalment',
        default: 0,
    },
    first_instalment_percent: {
        key: 'first_instalment_percent',
        default: 0,
    },
    instalment_percent: 'instalment_percent',
    last_instalment: {
        key: 'last_instalment',
        default: 0,
    },
    last_instalment_percent: {
        key: 'last_instalment_percent',
        default: 0,
    },
    total_claim: 'total_claim',
    total_claim_taxes: 'total_claim_taxes',
    insurance_total_claim: 'insurance_total_claim',
    insurance_total_claim_taxes: 'insurance_total_claim_taxes',
    insurance_type: {
        key: 'insurance_type',
        default: InsuranceType.NO,
    },
    insurance: 'insurance',
    insurance_fee: 'insurance_fee',
    refinancing_rate: 'refinancing_rate',
    residual_value: 'residual_value',
    total_leasing_value: 'total_leasing_value',
    total_terms: 'total_terms',
    value_to_calculate: 'value_to_calculate',
    yearly_interest: 'yearly_interest',
};

export class LeasingQuoteCalculationMapper {

    public values;
    constructor(formValues: any) {
        this.values = formValues;
    }

    public getValues() {
        const values = new ObjectMapper(this.values, map);
        return values;
    }

}
