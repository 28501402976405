/* libs/quote/src/Components/retailer-quote-versions-table/retailer-quote-versions-table.component.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host .mat-mdc-cell:nth-child(1),
:host .mat-mdc-header-cell:nth-child(1) {
  flex: 0 0 16%;
}
:host .mat-mdc-cell:nth-child(2),
:host .mat-mdc-header-cell:nth-child(2),
:host .mat-mdc-cell:nth-child(3),
:host .mat-mdc-header-cell:nth-child(3) .mat-mdc-cell:nth-child(4),
:host .mat-mdc-header-cell:nth-child(4),
:host .mat-mdc-cell:nth-child(5),
:host .mat-mdc-header-cell:nth-child(5) .mat-mdc-cell:nth-child(6),
:host .mat-mdc-header-cell:nth-child(6) {
  flex: 0 0 12%;
}
:host .mat-mdc-cell:nth-child(8),
:host .mat-mdc-header-cell:nth-child(8),
:host .mat-mdc-cell:nth-child(7),
:host .mat-mdc-header-cell:nth-child(7) {
  flex: 0 0 8%;
}
:host mat-cell:last-of-type {
  justify-content: flex-end;
}
.quote-state {
  height: 14px;
  font-size: 14px;
}
.quote-state-PENDING {
  color: #8028bf !important;
  background-color: rgba(128, 40, 191, 0.2) !important;
}
.quote-state-PENDING mat-icon,
.quote-state-PENDING l7-text-block {
  color: #8028bf !important;
}
.quote-state-OPEN {
  color: #1b7e9a !important;
  background-color: #e4f0f3 !important;
}
.quote-state-OPEN mat-icon,
.quote-state-OPEN l7-text-block {
  color: #1b7e9a !important;
}
.quote-state-COMPLETED {
  color: #02cc83 !important;
  background-color: rgba(30, 188, 131, 0.2) !important;
}
.quote-state-COMPLETED mat-icon,
.quote-state-COMPLETED l7-text-block {
  color: #02cc83 !important;
}
.quote-state-ERROR,
.quote-state-DECLINED {
  color: #ad0149 !important;
  background-color: rgba(173, 1, 73, 0.2) !important;
}
.quote-state-ERROR mat-icon,
.quote-state-ERROR l7-text-block,
.quote-state-DECLINED mat-icon,
.quote-state-DECLINED l7-text-block {
  color: #ad0149 !important;
}
.quote-state mat-icon {
  font-size: 14px;
  height: 16px;
  width: 16px;
}
/*# sourceMappingURL=retailer-quote-versions-table.component.css.map */
