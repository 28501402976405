<ng-template #shareholderTemplate let-beneficialOwner='beneficialOwner' let-i = 'i'>
    <ng-container [formGroup]="beneficialOwnersForm">
        <ng-container [formArrayName]="'beneficialOwners'">
            <div [formGroupName]="i" fxLayout="row wrap" fxLayoutGap="16px">
                <mat-form-field fxFlex
                                appearance="outline">
                    <mat-label>{{'beneficialOwner.person.nationality' | translate}}</mat-label>
                    <mat-select [formControlName]="'nationality'"
                                l7MarkAsTouched
                                [attr.test-id]="'Nationality' + i">
                        <mat-option>
                            <ngx-mat-select-search noEntriesFoundLabel="Keine Land gefunden ..."
                                                   (keyup)="filterCountries($event, i)"
                                                   [formControlName]="'countryFilter'">
                            </ngx-mat-select-search>
                        </mat-option>
                        <ng-container *ngIf="countries.length && (activeIndex | async) === i; else listOfCountries">
                            <mat-option *ngFor="let country of filteredCountries$ | async"
                                        [value]="country.alpha3Code">
                                {{country.name}}
                            </mat-option>
                        </ng-container>
                        <ng-template #listOfCountries>
                            <mat-option *ngFor="let country of countries"
                                        [value]="country.alpha3Code">
                                {{country.name}}
                            </mat-option>
                        </ng-template>
                    </mat-select>
                    <mat-error *ngIf="beneficialOwner.get('nationality').hasError('required') && beneficialOwner.get('nationality').touched">
                        <p>{{ 'error.field_required' | translate }}</p>
                    </mat-error>
                </mat-form-field>
                <l7-date-picker fxFlex
                                [label]="'beneficialOwner.person.birthday'"
                                [controlName]="'dateOfBirth'"
                                [uniqueId]="'boBirthDate' + i"
                                [parentFormGroup]="beneficialOwner">
                </l7-date-picker>
                <mat-form-field fxFlex
                                appearance="outline">
                    <mat-label>{{ 'beneficialOwner.person.city' | translate }}</mat-label>
                    <input matInput
                           l7MarkAsTouched
                           type="text"
                           [attr.test-id]="'bOLastName' + i"
                           [formControlName]="'city'">
                    <mat-error *ngIf="beneficialOwner.get('city').hasError('required')">
                        <p>{{ 'error.field_required' | translate }}</p>
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex
                                appearance="outline">
                    <mat-label>{{ 'beneficialOwner.person.participationRate' | translate }}</mat-label>
                    <input matInput
                           l7MarkAsTouched
                           currencyMask
                           [options]="{suffix: ' %',  precision: 0}"
                           type="text"
                           [attr.test-id]="'participationRate' + i"
                           [formControlName]="'participationRate'">
                    <mat-error *ngIf="beneficialOwner.get('participationRate').hasError('required')">
                        <p>{{ 'error.field_required' | translate }}</p>
                    </mat-error>
                    <mat-error *ngIf="beneficialOwner.get('participationRate').hasError('min')">
                        <p>{{ 'beneficialOwner.person.minParticipationRate' | translate }}</p>
                    </mat-error>
                    <mat-error *ngIf="beneficialOwner.get('participationRate').hasError('max')">
                        <p>{{ 'beneficialOwner.person.maxParticipationRate' | translate }}</p>
                    </mat-error>
                </mat-form-field>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
<ng-template #complementaryTemplate let-beneficialOwner='beneficialOwner' let-i = 'i'>
    <ng-container [formGroup]="beneficialOwnersForm">
        <ng-container [formArrayName]="'beneficialOwners'">
            <div [formGroupName]="i" fxLayout="row wrap" fxLayoutGap="16px">
                <mat-form-field fxFlex
                                appearance="outline">
                    <mat-label>{{'beneficialOwner.person.nationality' | translate}}</mat-label>
                    <mat-select [formControlName]="'nationality'"
                                l7MarkAsTouched
                                [attr.test-id]="'Nationality' + i">
                        <mat-option>
                            <ngx-mat-select-search noEntriesFoundLabel="Keine Land gefunden ..."
                                                   (keyup)="filterCountries($event, i)"
                                                   [formControlName]="'countryFilter'">
                            </ngx-mat-select-search>
                        </mat-option>
                        <ng-container *ngIf="countries.length && (activeIndex | async) === i; else listOfCountries">
                            <mat-option *ngFor="let country of filteredCountries$ | async"
                                        [value]="country.alpha3Code">
                                {{country.name}}
                            </mat-option>
                        </ng-container>
                        <ng-template #listOfCountries>
                            <mat-option *ngFor="let country of countries"
                                        [value]="country.alpha3Code">
                                {{country.name}}
                            </mat-option>
                        </ng-template>
                    </mat-select>
                    <mat-error *ngIf="beneficialOwner.get('nationality').hasError('required') && beneficialOwner.get('nationality').touched">
                        <p>{{ 'error.field_required' | translate }}</p>
                    </mat-error>
                </mat-form-field>
                <l7-date-picker fxFlex
                                [label]="'beneficialOwner.person.birthday'"
                                [controlName]="'dateOfBirth'"
                                [uniqueId]="'boBirthDate' + i"
                                [parentFormGroup]="beneficialOwner">
                </l7-date-picker>
                <mat-form-field fxFlex
                                appearance="outline">
                    <mat-label>{{ 'beneficialOwner.person.city' | translate }}</mat-label>
                    <input matInput
                           l7MarkAsTouched
                           type="text"
                           [attr.test-id]="'bOLastName' + i"
                           [formControlName]="'city'">
                    <mat-error *ngIf="beneficialOwner.get('city').hasError('required')">
                        <p>{{ 'error.field_required' | translate }}</p>
                    </mat-error>
                </mat-form-field>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #fictitiousTemplate let-beneficialOwner='beneficialOwner' let-i = 'i'>
    <ng-container [formGroup]="beneficialOwnersForm">
        <ng-container [formArrayName]="'beneficialOwners'">
            <div [formGroupName]="i" fxLayout="row wrap" fxLayoutGap="16px">
                <div fxLayout="column" [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'" [fxFlex.lt-md]="100">
                    <l7-date-picker fxFlex
                                    [label]="'beneficialOwner.person.birthday'"
                                    [controlName]="'dateOfBirth'"
                                    [uniqueId]="'boBirthDate' + i"
                                    [parentFormGroup]="beneficialOwner">
                    </l7-date-picker>
                </div>

            </div>
        </ng-container>
    </ng-container>
</ng-template>

<mat-card appearance="outlined" class="mat-card-outlined">
    <mat-card-header>
        <mat-card-title>
            {{ 'beneficialOwner.title' | translate }} <ng-container *ngIf="lessee"> {{' der' | translate}} {{lessee.name}}</ng-container>
            <mat-icon [fontSet]="'l7'"
                      [fontIcon]="'Alert_Circle_Filled'"
                      [color]="'primary'"
                      [matTooltip]="'quote.retailers.quoteDetails.tasks.identification.beneficialOwnerInfo' | translate">
            </mat-icon>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ng-container [formGroup]="beneficialOwnersForm">
            <ng-container [formArrayName]="'beneficialOwners'">
                <div fxLayout="row wrap"
                     fxLayoutGap="8px"
                     fxLayout.lt-sm="column"
                     fxLayoutAlign="flex-start baseline"
                     *ngFor="let beneficialOwner of beneficialOwnersFields.controls; let i = index">
                    <ng-container [formGroupName]="i"
                                  *ngIf="beneficialOwner.getRawValue().id !== null; else newBeneficialOwners">
                        <div fxLayout="column" fxFlex="95">
                            <div fxLayout="row wrap" fxLayoutGap="16px">
                                <mat-form-field fxFlex
                                                appearance="outline">
                                    <mat-label>{{ 'beneficialOwner.person.name' | translate }}</mat-label>
                                    <input matInput
                                           type="text"
                                           [attr.test-id]="'bOFirstName' + i"
                                           [formControlName]="'firstName'">
                                    <mat-error *ngIf="beneficialOwner.get('firstName').hasError('required')">
                                        <p>{{ 'error.field_required' | translate }}</p>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field fxFlex
                                                appearance="outline">
                                    <mat-label>{{ 'beneficialOwner.person.lastName' | translate }}</mat-label>
                                    <input matInput
                                           [attr.test-id]="'bOLastName' + i"
                                           type="text"
                                           [formControlName]="'lastName'">
                                    <mat-error *ngIf="beneficialOwner.get('lastName').hasError('required')">
                                        <p>{{ 'error.field_required' | translate }}</p>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field fxFlex
                                                appearance="outline">
                                    <mat-label>{{ 'beneficialOwner.person.type' | translate }}</mat-label>
                                    <mat-select (selectionChange)="beneficialOwnerTypeChanged(i, $event)"
                                                [attr.test-id]="'beneficialOwnerType' + i"
                                                formControlName="beneficialOwnerType"
                                                id="beneficialOwnerType">
                                        <mat-option *ngFor="let beneficialOwnerType of getAvailableOptions(i);"
                                                    [value]="beneficialOwnerType">
                                            {{'beneficialOwner.type.' + beneficialOwnerType | translate}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="beneficialOwner.get('beneficialOwnerType').hasError('required')">
                                        <p>{{ 'error.field_required' | translate }}</p>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <ng-container [ngSwitch]="beneficialOwner.controls.beneficialOwnerType.value">
                                <ng-container *ngSwitchCase="'SHAREHOLDER'">
                                    <ng-container *ngTemplateOutlet="shareholderTemplate; context: { beneficialOwner: beneficialOwner, i:i }"></ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'COMPLEMENTARY'">
                                    <ng-container *ngTemplateOutlet="complementaryTemplate; context: { beneficialOwner: beneficialOwner, i:i }"></ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'BOARD'">
                                    <ng-container *ngTemplateOutlet="fictitiousTemplate; context: { beneficialOwner: beneficialOwner, i:i }"></ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'MANAGING_DIRECTOR'">
                                    <ng-container *ngTemplateOutlet="fictitiousTemplate; context: { beneficialOwner: beneficialOwner, i:i }"></ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div fxFlexAlign="center" fxFlex="4">
                            <button mat-icon-button
                                    [attr.test-id]="'deleteBO' + i"
                                    type="button"
                                    (click)="deleteBeneficialOwner(beneficialOwner.getRawValue().id, i)">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="'Trash'">
                                </mat-icon>
                            </button>
                        </div>
                    </ng-container>

                    <ng-template #newBeneficialOwners>
                        <ng-container [formGroupName]="i"
                                      *ngIf="(hideNewBeneficialOwnersFields | async)">
                            <mat-form-field fxFlex
                                            appearance="outline">
                                <mat-label>{{ 'beneficialOwner.person.name' | translate }}</mat-label>
                                <input matInput
                                       l7MarkAsTouched
                                       [attr.test-id]="'bOFirstName' + i"
                                       type="text"
                                       [formControlName]="'firstName'">
                                <mat-error *ngIf="beneficialOwner.get('firstName').hasError('required')">
                                    <p>{{ 'error.field_required' | translate }}</p>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex
                                            appearance="outline">
                                <mat-label>{{ 'beneficialOwner.person.lastName' | translate }}</mat-label>
                                <input matInput
                                       l7MarkAsTouched
                                       type="text"
                                       [attr.test-id]="'bOLastName' + i"
                                       [formControlName]="'lastName'">
                                <mat-error *ngIf="beneficialOwner.get('lastName').hasError('required')">
                                    <p>{{ 'error.field_required' | translate }}</p>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex
                                            appearance="outline">
                                <mat-label>{{'beneficialOwner.person.nationality' | translate}}</mat-label>
                                <mat-select [formControlName]="'nationality'"
                                            l7MarkAsTouched
                                            [attr.test-id]="'Nationality' + i">
                                    <mat-option>
                                        <ngx-mat-select-search noEntriesFoundLabel="Keine Land gefunden ..."
                                                               (keyup)="filterCountries($event, i)"
                                                               [formControlName]="'countryFilter'">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <ng-container *ngIf="countries.length && (activeIndex | async) === i; else listOfCountries">
                                        <mat-option *ngFor="let country of filteredCountries$ | async"
                                                    [value]="country.alpha3Code">
                                            {{country.name}}
                                        </mat-option>
                                    </ng-container>
                                    <ng-template #listOfCountries>
                                        <mat-option *ngFor="let country of countries"
                                                    [value]="country.alpha3Code">
                                            {{country.name}}
                                        </mat-option>
                                    </ng-template>
                                </mat-select>
                                <mat-error *ngIf="beneficialOwner.get('nationality').hasError('required') && beneficialOwner.get('nationality').touched">
                                    <p>{{ 'error.field_required' | translate }}</p>
                                </mat-error>
                            </mat-form-field>
                            <l7-date-picker fxFlex
                                            [label]="'beneficialOwner.person.birthday'"
                                            [controlName]="'dateOfBirth'"
                                            [uniqueId]="'boBirthDate' + i"
                                            [parentFormGroup]="beneficialOwner">
                            </l7-date-picker>
                            <button mat-icon-button
                                    type="button"
                                    [attr.test-id]="'deleteBO' + i"
                                    (click)="deleteBeneficialOwner(beneficialOwner.getRawValue().id, i)">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="'Trash'">
                                </mat-icon>
                            </button>
                        </ng-container>

                    </ng-template>

                </div>
            </ng-container>
            <ng-container *ngIf="!beneficialOwnersFields.length">
                <l7-banner [color]="'warn'"
                           [appearance]="'textual'"
                           [attr.test-id]="'noBeneficialOwner'"
                           [iconFontSet]="'l7'"
                           [icon]="'Alert_Triangle'"
                           [content]="'beneficialOwner.selectorView.subTitle' | translate">
                </l7-banner>
            </ng-container>
        </ng-container>
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px"
             *ngIf="!isboardMemberSelected">
            <button mat-flat-button
                    class="more-button"
                    type="button"
                    [attr.test-id]="'btnAddBO'"
                    (click)="addBeneficialOwner()">
                {{'+ ' + 'weiteren wirtschaftlich Berechtigten hinzufügen' | translate}}
            </button>
        </div>
        <l7-spacer [thickness]="'bottom'"
                   [multiplier]="24">
        </l7-spacer>
        <div fxLayout="row" [formGroup]="beneficialOwnersForm">
            <div fxLayout="column">
                <mat-checkbox l7MandatoryCheckboxWithError [formControlName]="'confirmationCheckBox'"
                              [attr.test-id]="'confirmationCheckBox'" #checkBeneficialOwners [triggerValidation]="validateConfirmationCheckBoxManually">
                    {{'Ich habe den wirtschaftlich Berechtigten im Kundengespräch erfragt.*' | translate}}
                </mat-checkbox>
            </div>
        </div>
        <l7-spacer [thickness]="'bottom'"
                   [multiplier]="24">
        </l7-spacer>
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px"
             *ngIf="isboardMemberSelected">
            <l7-banner [icon]="'Alert_Triangle'"
                       [iconFontSet]="'l7'"
                       [color]="'warning'"
                       [content]=" 'beneficialOwner.fiktivBanner'| translate"
                       [appearance]="'outlined'">
            </l7-banner>
        </div>
    </mat-card-content>
</mat-card>

