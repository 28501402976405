// #region Imports

import { format } from '@abcfinlab/core';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// #endregion

/**
 * The `CustomerCreationRequestService` class.
 *
 * @public
 */
@Injectable()
export class CustomerCreationRequestService {

    // #region Fields

    private readonly _sanitizer: DomSanitizer;
    private readonly _subject: string = 'Anfrage zur Anlage von Leasingnehmerkontakt in CREFO für {0}';
    private readonly _body: string = 'Sehr geehrte Damen und Herren,%0D%0A'
        + 'hiermit frage ich die Anlage eines neuen Leasingnehmerkontakts mit nachfolgenden Unternehmensdaten an:%0D%0A%0D%0A'
        + '- Firmenname (Pflichtangabe): ' + '%0D%0A'
        + '- Straße und Hausnummer (Pflichtangabe): ' + '%0D%0A'
        + '- Postleitzahl (Pflichtangabe): ' + '%0D%0A'
        + '- Stadt (Pflichtangabe): ' + '%0D%0A'
        + '- Anfrage wurde übermittelt von: {0}';

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CustomerCreationRequestService` class.
     *
     * @public
     */
    public constructor(sanitizer: DomSanitizer) {
        this._sanitizer = sanitizer;
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public buildRequestMail(init: { officeServiceMail: string; retailerCompany: string; salerUserMail: string }): string {
        const subject = format(this._subject, init.retailerCompany);
        const body = format(this._body, init.salerUserMail);
        const url = `mailto:${init.officeServiceMail}?subject=${subject}&body=${body}`;

        return url;
    }

    // #endregion

}
