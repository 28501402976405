<l7-page>
    <l7-page-pre-content *ngIf="!isMobile"
                         fxLayoutAlign="end">
        <l7-page-section>
            <button mat-icon-button
                    [color]="activeView === 'CARD' ? 'accent' : ''"
                    (click)="changeViewType('CARD')">
                <mat-icon [fontSet]="'l7'"
                          [fontIcon]="'Elements'"></mat-icon>
            </button>
            <button mat-icon-button
                    (click)="changeViewType('TABLE')">
                <mat-icon [color]="activeView === 'TABLE' ? 'accent' : ''"
                          [fontSet]="'l7'"
                          [fontIcon]="'Grid_15'"></mat-icon>
            </button>
        </l7-page-section>
    </l7-page-pre-content>
    <l7-page-content>
        <l7-page-section fxLayout="column">
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let quote of (quoteList$ | async)?.quotes; let i = index"
                                     [expanded]="i === 0"
                                     [attr.test-id]="'expansionPanel'+ quote.nav_info?.quote_no">
                    <mat-expansion-panel-header matRipple>
                        <mat-panel-title fxLayoutAlign="start center"
                                         fxLayoutGap="16px">
                            <p>
                                <span>{{ (quote | quoteName) }}</span>
                                <ng-container *ngIf="quote.nav_info?.quote_no">{{' | ' + quote.nav_info?.quote_no}}</ng-container>
                            </p>
                            <l7-delete-quote [versions]="quote.versions"
                                             (delete)="deleteQuote($event)"></l7-delete-quote>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <l7-quote-versions-table *ngIf="activeView === 'TABLE'"
                                             [versions]="quote.versions"
                                             (addVendor)="goToVendorSearch($event)"
                                             (goToVersionDetailView)="goToDetailView($event)">
                    </l7-quote-versions-table>
                    <l7-quote-versions-cards *ngIf="activeView === 'CARD'"
                                             [versions]="quote.versions"
                                             (addVendor)="goToVendorSearch($event)"
                                             (goToVersionDetailView)="goToDetailView($event)">
                    </l7-quote-versions-cards>
                </mat-expansion-panel>
            </mat-accordion>
        </l7-page-section>
    </l7-page-content>
</l7-page>
