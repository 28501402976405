/* libs/quote/src/Components/quote-summary-card/quote-summary-card.component.scss */
:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
:host mat-icon.warning {
  color: #fa7601;
  font-size: 18px;
  margin-left: 7px;
}
/*# sourceMappingURL=quote-summary-card.component.css.map */
