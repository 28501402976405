// #region Imports

import { IUserInfoDto } from '@abcfinlab/api/login';
import { IUserGroupLoginsDto } from '@abcfinlab/auth';
import { RoleManager, RoleModule, SESSION_AVAILABLE_TOKEN, TranslationFacade, TranslationModule, USER_INFO_TOKEN } from '@abcfinlab/core';
import { PresenterService } from '@abcfinlab/layout';
import { Inject, NgModule } from '@angular/core';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { QUOTE_DETAIL_ROUTE_PATH, QUOTE_LIST_ROUTE_PATH } from '../../../quote/src/Routing/RoutePaths';
import { LESSEES_OVERVIEW_ROUTE_PATH } from './ContactsRoutingModule';

// #endregion

@NgModule({
    imports: [
        TranslationModule,
        RoleModule,
    ],
})
export class ContactsNavigationModule {

    // #region Ctor

    /**
     * Constructs a new instance of the `ContactsNavigationModule` class.
     *
     * @public
     */
    public constructor(@Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>, presenterService: PresenterService, roleManager: RoleManager, translationFacade: TranslationFacade) {
        // TODO: check permissions here

        combineLatest([sessionAvailable, userInfo])
            .pipe(first())
            .subscribe(([_, user]) => {
                if (user.groups.includes(IUserGroupLoginsDto.Sales) || user.groups.includes(IUserGroupLoginsDto.SalesAssistance)) {
                    presenterService.setNavigation({
                        label: translationFacade.instant('Kalkulierte Angebote'),
                        icon: 'File_Calculator',
                        order: 30,
                        route: LESSEES_OVERVIEW_ROUTE_PATH,
                        relatedTo: [
                            { link: `/presentation/${QUOTE_LIST_ROUTE_PATH}` },
                            { link: '/presentation/gwg/signer-data' },
                            { link: `/presentation/${QUOTE_DETAIL_ROUTE_PATH}` },
                            { link: '/presentation/quotes/slb-details', options: { paths: 'subset' } },
                        ],
                    });
                }
            });
    }

    // #endregion

}
