import { IPageContactSearchResultEntryDto } from '@abcfinlab/api/contact';
import { IRetailerCalculationRequestDto, IRetailerCalculationResultDto } from '@abcfinlab/api/global';

export class SetLesseeListFromSearchResult {

    static readonly type = '[RETAILER CALCULATION] Set lessee list from search result';
    constructor(public payload: IPageContactSearchResultEntryDto) {
    }

}

export class SetCalculationValuesForRequest {

    static readonly type = '[RETAILER CALCULATION] Set calculation values for request';
    constructor(public payload: IRetailerCalculationRequestDto) {
    }

}

export class SetCalculationSelectionForRequest {

    static readonly type = '[RETAILER CALCULATION] Set calculation rate for request';
    constructor(public payload: IRetailerCalculationResultDto) {
    }

}

export class SetCurrentStep {

    static readonly type = '[RETAILER CALCULATION] Set current step';
    constructor(public payload: number) {
    }

}

export class SetObjectValues {

    static readonly type = '[RETAILER CALCULATION] Set object values for request';
    constructor(public payload: { exclusionOfWarranty: boolean; objectName: string; objectQuantity: number }) {
    }

}

export class SetSearchValues {

    static readonly type = '[RETAILER CALCULATION] Set search for lessee values';
    constructor(public payload: { companyName: string; city: string }) {
    }

}
