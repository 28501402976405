// #region Imports

import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../../apps/l7/src/app/shared/guards/auth.guard';
import { SelectLesseeView } from '../Views/SelectLesseeView';

// #endregion

/**
 * @internal
 */
export const LESSEES_OVERVIEW_ROUTE_PATH: string = 'quotes/lessees-overview';

/**
 * @internal
 */
export const LESSEES_OVERVIEW_ROUTE: Route = {
    path: LESSEES_OVERVIEW_ROUTE_PATH,
    component: SelectLesseeView,
    canActivate: [AuthGuard],
};

/**
 * @public
 */
export const CONTACTS_ROUTES: Routes = [
    LESSEES_OVERVIEW_ROUTE,
];

/**
 * @public
 */
@NgModule({
    imports: [
        RouterModule.forChild(CONTACTS_ROUTES),
    ],
    exports: [RouterModule],
})
export class ContactsRoutingModule {
}
