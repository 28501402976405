<mat-table fxFill
           [dataSource]="dataSource"
           [attr.test-id]="'QuoteVersionsTable'">
    <ng-container *ngFor="let column of columns"
                  [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>
            <span class="text-truncate"
                  *ngIf="column !== 'menu'">
                {{ 'quote.versions.' + column | translate }}
            </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index;"
                  (click)="goToDetailView($event, row, column)"
                  [attr.test-id]="'versionColumn'+row.id">
            <ng-container [ngSwitch]="column">
                <ng-container *ngSwitchCase="'version'">
                    {{ row | quoteName }}
                </ng-container>
                <ng-container *ngSwitchCase="'last_update'">
                    <span class="text-truncate">{{ row[column] | date: 'mediumDate' }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'total_leasing_value'">
                    {{row.quote_calculation.total_leasing_value | currency}}
                </ng-container>
                <ng-container *ngSwitchCase="'total_terms'">
                    {{row.quote_calculation.total_terms}} Monate
                </ng-container>
                <ng-container *ngSwitchCase="'instalment'">
                    {{row.quote_calculation.instalment | currency}}
                </ng-container>
                <ng-container *ngSwitchCase="'yearly_interest'">
                    {{row.quote_calculation.yearly_interest | number : '1.2'}} %
                </ng-container>
                <ng-container *ngSwitchCase="'contract_type'">
                    <ng-container *ngIf="row.quote_calculation.contract_type === 'MIETKAUF'; else notMietkauf">
                        MK
                    </ng-container>
                    <ng-template #notMietkauf>
                        {{row.quote_calculation.contract_type}}
                        <ng-container *ngIf="row.sale_and_lease_back && row.quote_calculation.contract_type !== 'MKN'">+ SLB</ng-container>
                        <ng-container *ngIf="row.quote_calculation.contract_type === 'MKN'">+ SMB</ng-container>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'menu'">
                    <l7-quote-version-menu [version]="row"
                                           (addVendor)="onAddVendor($event)"></l7-quote-version-menu>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span [matTooltip]="row[column]"
                          class="text-truncate">{{ row[column] }}</span>
                </ng-container>
            </ng-container>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns; let i = index"
             matRipple
             [attr.test-id]="'quoteVersionTableRow' + row.object_name">
    </mat-row>
</mat-table>
