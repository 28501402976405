/* libs/quote/src/Components/quote-versions-cards/quote-versions-cards.component.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host .item {
  margin: 8px;
  max-width: calc(33.3% - 16px);
}
:host .item.lt-lg {
  max-width: calc(50% - 16px);
}
:host .item.lt-md {
  max-width: 100%;
}
:host mat-card-content {
  padding-bottom: 16px;
}
/*# sourceMappingURL=quote-versions-cards.component.css.map */
