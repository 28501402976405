import {
    ILeasingQuoteCreditRatingDto,
    ILeasingQuoteOverviewVersionDto,
    ILeasingQuoteVendorStatusDto,
    SigningLinkQuoteService,
} from '@abcfinlab/api/global';
import { AppConfig, subscribeOnce } from '@abcfinlab/core';
import { ToastService } from '@abcfinlab/ui';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { finalize, switchMap } from 'rxjs/operators';
import { GetCreditRating } from '../../../../../apps/l7/src/app/actions/Quote.actions';
import { DialogService } from '../../../../../apps/l7/src/app/private/services/dialog/dialog.service';
import { LesseeService } from '../../../../../apps/l7/src/app/private/services/lessee/lessee.service';
import { QuoteService } from '../../../../../apps/l7/src/app/private/services/quote/quote.service';
import { CheckCamModalComponent } from '../../../../../apps/l7/src/app/shared/modals/check-cam-modal/check-cam-modal.component';
import { DeleteConfirmationDialogComponent } from '../../../../../apps/l7/src/app/shared/modals/delete-confirmation-dialog/delete-confirmation-dialog.component';
import {
    GenericDialogComponent,
    GenericDialogData,
} from '../../../../../apps/l7/src/app/shared/modals/generic-dialog/generic-dialog.component';
import {
    RoutingBehaviour,
    ServerErrorDialogData,
} from '../../../../../apps/l7/src/app/shared/modals/server-error-dialog/server-error-dialog.component';
import { ErrorHandlerService } from '../../../../../apps/l7/src/app/shared/services/errorHandler/error-handler.service';

@UntilDestroy()
@Component({
    selector: 'l7-quote-version-menu',
    templateUrl: './quote-version-menu.component.html',
    styleUrls: ['./quote-version-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class QuoteVersionMenuComponent {

    @Input() version: ILeasingQuoteOverviewVersionDto;

    @Output() addVendor: EventEmitter<ILeasingQuoteOverviewVersionDto> = new EventEmitter<ILeasingQuoteOverviewVersionDto>();

    private readonly dialogConfig = {
        autoFocus: true,
        closeOnNavigation: false,
        disableClose: false,
        minHeight: '0px',
    };

    private readonly _host: string;

    constructor(private readonly datePipe: DatePipe,
        private readonly appConfig: AppConfig,
        private readonly router: Router,
        private readonly store: Store,
        private readonly actions$: Actions,
        private readonly lesseeService: LesseeService,
        private readonly errorHandler: ErrorHandlerService,
        private readonly signingLinkService: SigningLinkQuoteService,
        private readonly quoteService: QuoteService,
        private readonly toastService: ToastService,
        private readonly dialogService: DialogService,
        private readonly activatedRoute: ActivatedRoute) {
        this._host = appConfig.get('host');
        this.actions$
            .pipe(
                ofActionCompleted(GetCreditRating),
                untilDestroyed(this),
            )
            .subscribe(
                (values) => {
                    values.action.dialogRef.close();
                    this.updateSelectedLesseeInfo();
                },
                errorData => errorData.action.dialogRef.close(),
            );
    }

    get quote(): ILeasingQuoteOverviewVersionDto {
        return this.version;
    }

    get vendor_state(): string {
        return this.version.states.vendor;
    }

    get lessee_state(): string {
        return this.version.states.lessee.credit_rating;
    }

    get contract_draft_download_link(): string {
        return btoa(`${this._host}/api/v1/drafts` + `/pdf/download/${this.version.id}`);
    }

    get offer_download_link(): string {
        return btoa(`${this._host}/api/v1/quotes` + `/pdf/download/${this.version.id}`);
    }

    get lessee_state_approved_with_condition(): boolean {
        return this.version.states.lessee.credit_rating === ILeasingQuoteCreditRatingDto.ApprovedWithConditions || this.version.states.lessee.credit_rating === ILeasingQuoteCreditRatingDto.ApprovedWithConditionsFulfilled;
    }

    get lessee_state_discarded(): boolean {
        return this.version.states.lessee.credit_rating === ILeasingQuoteCreditRatingDto.Discarded;
    }

    get lessee_state_pending(): boolean {
        return this.version.states.lessee.credit_rating === ILeasingQuoteCreditRatingDto.Pending;
    }

    get lessee_has_positive_credit_rating(): boolean {
        return this.version.states.lessee.credit_rating === ILeasingQuoteCreditRatingDto.Positive;
    }

    get lessee_state_open(): boolean {
        return this.version.states.lessee.credit_rating === ILeasingQuoteCreditRatingDto.Open;
    }

    get tooltip_delete(): string {
        if (this.lessee_has_positive_credit_rating) {
            return 'Positive Bonität. Nicht löschbar!';
        }
        if (this.lessee_state_pending) {
            return 'Laufende Bonitätsprüfung. Nicht löschbar!';
        }
        if (this.lessee_state_approved_with_condition) {
            return 'Genehmigt mit Auflage Bonität. Nicht löschbar!';
        }
        return 'Kalkulation löschen?';
    }

    get has_signing_link(): boolean {
        return !!this.version.link;
    }

    get signing_link_positive(): boolean {
        return this.has_signing_link && this.version.link.invalid_at === null && this.version.link.link !== null && (new Date() <= new Date(this.version.link.expires_at));
    }

    get signing_link_expired(): boolean {
        return this.has_signing_link && this.version.link.invalid_at === null && this.version.link.expires_at !== null && (new Date() > new Date(this.version.link.expires_at));
    }

    get signing_link_invalid(): boolean {
        return this.has_signing_link && this.version.link.invalid_at !== null;
    }

    get tooltip_signing_link(): string {
        if (this.signing_link_expired) {
            const expiredAt = this.version.link.expires_at;
            return `Link ist abgelaufen am ${this.datePipe.transform(expiredAt, 'EEEE, dd MMMM HH:mm')} Uhr (MEZ)`;
        }
        if (this.signing_link_invalid) {
            const invalidAt = this.version.link.invalid_at;
            return `Link ist ungültig seit ${this.datePipe.transform(invalidAt, 'EEEE, dd MMMM HH:mm')} Uhr (MEZ)`;
        }
        if (this.signing_link_positive) {
            const expiresAt = this.version.link.expires_at;
            return `Link wird ablaufen am ${this.datePipe.transform(expiresAt, 'EEEE, dd MMMM HH:mm')} Uhr (MEZ)`;
        }
    }

    get signing_link_classes(): string {
        if (this.signing_link_positive) {
            return 'VALID';
        }
        if (this.signing_link_invalid) {
            return 'INVALID';
        }
        if (this.signing_link_expired) {
            return 'EXPIRED';
        }
    }

    public onAddVendor(evt: MouseEvent): void {
        evt.stopPropagation();

        if (!this.lessee_state_open || !this.version.states.vendor_change_allowed) {
            const dialogData: GenericDialogData = {
                id: 'only_open_can_set_vendor',
                title: 'Lieferanteninformationen ändern',
                body: 'dialogs.no_vendor_set_if_state_is_not_open',
                negativeText: 'global.close',
            };
            this.dialogService.openDialog(GenericDialogComponent, this.dialogConfig, dialogData);
            return;
        }

        this.addVendor.emit(this.version);
    }

    /**
     *  Open dialog to confirm if the user wants to make a boni check if the vendor is already selected
     */
    public openBoniCheckDialog(evt: MouseEvent): void {
        evt.stopPropagation();

        let dialogData: GenericDialogData = {
            id: 'credit_check_manually_triggered',
            body: 'calculation.credit_check_manually_triggered',
            negativeText: 'global.close',
        };

        if (this.version.states.credit_check_manually_triggered) {
            this.dialogService.openDialog(GenericDialogComponent, this.dialogConfig, dialogData);
            return;
        }

        if (this.lessee_state_pending) {
            dialogData = {
                ...dialogData,
                ...{
                    id: 'no_credit_check_on_pending_state',
                    body: 'dialogs.no_credit_check_on_pending_state',
                },
            };

            this.dialogService.openDialog(GenericDialogComponent, this.dialogConfig, dialogData);
            return;
        }

        if (this.lessee_has_positive_credit_rating || this.lessee_state_approved_with_condition) {
            dialogData = {
                ...dialogData,
                ...{
                    id: 'no_credit_check_on_positive_state',
                    body: 'dialogs.no_credit_check_on_positive_state',
                },
            };

            this.dialogService.openDialog(GenericDialogComponent, this.dialogConfig, dialogData);
            return;
        }

        let dialogBodyText: string = 'calculation.body_boni_check_dialog';

        if (this.vendor_state === ILeasingQuoteVendorStatusDto.Open) {
            dialogBodyText = 'calculation.body_boni_check_dialog_no_vendor';
        }

        dialogData = {
            id: 'generic_dialog_boni_check',
            title: 'global.action_required',
            body: dialogBodyText,
            negativeText: 'global.no',
            positiveText: 'global.yes',
        };

        this.dialogService.openDialog(GenericDialogComponent, { ...this.dialogConfig, width: '45vw' }, dialogData).afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((result) => {
                if (result && dialogData.id === 'generic_dialog_boni_check') {
                    this.onCamCreditCheck();
                } else if (result && dialogData.id === 'generic_dialog_vendor_not_selected') {
                    this.addVendor.emit(this.version);
                    void this.router.navigate(['../search-vendor'], {
                        relativeTo: this.activatedRoute,
                    });
                }
            });
    }

    /**
     *  fetch lessee info
     */

    private updateSelectedLesseeInfo() {
        this.lesseeService.fetchLesseeByID(this.lesseeService.getSelectedLesseeID());
    }

    /**
     *  Start credit check on cam site
     */
    private onCamCreditCheck(): void {
        const dialogRef = this.dialogService.openDialog(CheckCamModalComponent, {});
        subscribeOnce(this.store.dispatch(new GetCreditRating(this.version.id, this.lesseeService.getSelectedLesseeID(), dialogRef)), (x) => { }, (error) => {
            const _error = typeof error.error === 'string' ? JSON.parse(error.error) : error.error;
            const config: MatDialogConfig = {
                id: 'modal_server_error_400_boni_check',
            };
            const data: ServerErrorDialogData = {
                title: 'Es ist ein Fehler aufgetreten',
                routingBehaviour: RoutingBehaviour.STAY,
                text: _error.error_description,
                error: error,
            };
            this.errorHandler.handleCriticalServerError(error, config, data);
        });
    }

    /**
     * Delete a single version of a quote. Should only be allowed if the lessee has no positive credit rating! (CORONA UPDATE)
     */
    public onDeleteVersion(evt: MouseEvent) {
        evt.stopPropagation();
        if (this.lessee_has_positive_credit_rating || this.lessee_state_pending || this.lessee_state_approved_with_condition) {
            return;
        } else {
            const data = {
                quoteId: this.version.id,
                objectName: this.version.object_name,
                deleteType: 'VERSION',
            };
            this.dialogService.openDialog(DeleteConfirmationDialogComponent, { ...this.dialogConfig, width: '45wv' }, data);
        }
    }

    /**
     *  Open dialog to inform that quote is already pending
     */
    public openDialogAlreadyPending(evt: MouseEvent) {
        evt.stopPropagation();
        const data: GenericDialogData = {
            id: 'generic_dialog_already_pending',
            title: 'calculation.body_already_pending_dialog',
        };
        this.dialogService.openDialog(GenericDialogComponent, this.dialogConfig, data);
    }

    public deactivateLink() {
        const dialogData: GenericDialogData = {
            id: 'invalidation_of_signing_link',
            title: 'Bearbeitung läuft!',
            body: 'Link wird invalidiert ...',
        };
        const dialogRef = this.dialogService.openDialog(GenericDialogComponent, this.dialogConfig, dialogData);
        this.signingLinkService.invalidateSigningLink({ leasingQuoteId: this.version.id }).pipe(
            switchMap(() => this.quoteService.fetchQuotesListByLesseeUUID(this.lesseeService.getSelectedLesseeID())),
            finalize(() => dialogRef.close()),
            untilDestroyed(this),
        ).subscribe((res) => {
        }, error => alert('Could not invalidate signing link!'));
    }

    public onCopy(success: boolean): void {
        if (success) {
            this.toastService.showSuccess('In Zwischenablage gespeichert!');
        }
    }

}
