import ObjectMapper from 'object-mapper';

const map = {
    lessee_id: 'lessee_id',
    object_group: 'object_group_id',
    quote_id: 'quote_id',
    quote_number: 'quote_number',
    version: 'version',
};

export class LeasingQuoteMapper {

    public values;
    constructor(formValues: any) {
        this.values = formValues;
    }

    public getValues() {
        const values = new ObjectMapper(this.values, map);
        return values;
    }

}
