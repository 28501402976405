// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BeneficialOwnerConfirmDialogViewPresenter } from './BeneficialOwnerConfirmDialogViewPresenter';

// #endregion

/**
 * The `BeneficialOwnerConfirmDialogView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-beneficial-owner-confirm-dialog-view',
    templateUrl: './BeneficialOwnerConfirmDialogView.html',
    styleUrls: ['./BeneficialOwnerConfirmDialogView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        BeneficialOwnerConfirmDialogViewPresenter,
    ],
    standalone: false,
})
export class BeneficialOwnerConfirmDialogView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: BeneficialOwnerConfirmDialogViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `BeneficialOwnerConfirmDialogView` class.
     *
     * @public
     */
    public constructor(presenter: BeneficialOwnerConfirmDialogViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `BeneficialOwnerConfirmDialogView`
     *
     * @public
     * @readonly
     */
    public get presenter(): BeneficialOwnerConfirmDialogViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
