/* libs/quote/src/Views/retailer-update-lessee-overview/retailer-update-lessee-overview.component.scss */
.mat-mdc-list-item {
  background: #ffffff;
}
.mat-mdc-list-item .lesseeInfo {
  color: #cccccc;
}
.subTitle {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}
/*# sourceMappingURL=retailer-update-lessee-overview.component.css.map */
