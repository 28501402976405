import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'l7-retailer-quote-step-chip',
    templateUrl: './retailer-quote-step-chip.component.html',
    styleUrls: ['./retailer-quote-step-chip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class RetailerQuoteStepChipComponent {

    @Input() label: string;
    @Input() tooltip: string;
    @Input() icon: string;
    @Input() variant: string;

}
