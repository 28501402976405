<l7-page>
    <l7-page-header *ngIf="pageData"
                    [hasBackNavigation]="true"
                    [supportiveTitle]="('global.nav_contact' | translate) + ': ' + pageData.newGetQuote.lessee.nav_contact_number"
                    [subTitle]="pageData.newGetQuote.lessee.name"
                    [title]="headline_title">
    </l7-page-header>
    <l7-page-pre-content fxLayoutAlign="end">
        <div class="cam-check-container"
             fxLayout="column"
             fxFlex="20"
             fxLayoutAlign="center center">
            <l7-gwg-dependency-bar [hidden]="!creditRating"
                                   [creditState]="creditRating"
                                   #gwgDependencyBar>
            </l7-gwg-dependency-bar>
        </div>
    </l7-page-pre-content>
    <l7-page-content *ngIf="pageData">
        <div class="page-container"
             fxLayout="column"
             fxLayoutGap="16px"
             [hidden]="!pageData?.quote">
            <h3> {{'quotes.details_sub_headline' | translate }}</h3>
            <form [formGroup]="leasingQuoteForm"
                  id="form_leasing-quote"
                  [ngClass]="{'disabled': credit_rating_discarded }"
                  fxLayout="column"
                  fxLayoutAlign="space-between"
                  fxLayoutGap="48px">
                <div fxFlex
                     fxLayout="column"
                     fxLayoutGap="32px">
                    <mat-card appearance="outlined" *ngIf="pageData"
                              class="mat-card-outlined">
                        <mat-card-header>
                            <h3 matCardTitle>{{ 'quotes.object_details_title' | translate }}</h3>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="fieldset-object"
                                 [ngClass]="{'fieldset-hire-purchase': is_hire_purchase }">
                                <ng-container formGroupName="object">
                                    <mat-form-field appearance="outline"
                                                    [style]="'width: 100%;'">
                                        <mat-label>Objektname</mat-label>
                                        <input l7InputRef
                                               matInput
                                               l7MarkAsTouched
                                               placeholder="Objektname"
                                               formControlName="name"
                                               id="input_object-name">
                                        <mat-error *ngIf="leasingQuoteForm.get('object').get('name').errors?.maxlength">
                                            {{'error.name_bigger_than' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-container>

                                <l7-quote-summary-card [leasingQuote]="pageData?.newGetQuote"
                                                       [hasSlbCode]="pageData?.newGetQuote?.saleAndLeaseBack?.code !== undefined && pageData?.newGetQuote?.saleAndLeaseBack?.code !== null"
                                                       [minColumns]="4"
                                                       [maxColumns]="4"
                                                       [flat]="true"
                                                       [creditCheck]="creditRating"
                                                       [hasHeader]="false">
                                </l7-quote-summary-card>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <div fxFlex
                         fxLayout="row"
                         fxFlexAlign="center space-between"
                         fxLayoutGap="32px">
                        <mat-card appearance="outlined" class="mat-card-outlined"
                                  fxFlex="50">
                            <mat-card-header>
                                <h3 matCardTitle>{{ 'quotes.delivery_information_details' | translate }}</h3>
                            </mat-card-header>
                            <mat-card-content *ngIf="pageData">
                                <div fxLayout="row">
                                    <l7-address-card fxFlex
                                                     *ngIf="pageData.newGetQuote.objects[0].vendor"
                                                     [title]="'global.vendor' | translate"
                                                     [contact]="pageData.newGetQuote.objects[0].vendor"
                                                     [flat]="true">
                                    </l7-address-card>

                                    <l7-address-card fxFlex
                                                     *ngIf="!pageData.newGetQuote.objects[0].vendor"
                                                     [title]="'global.vendor' | translate"
                                                     [contentHtml]="'global.no_vendor' | translate"
                                                     [flat]="true">
                                        <div *ngIf="creditRating.credit_rating !== lesseeCamState.OPEN"
                                             class="actions">
                                            <a class="link"
                                               (click)="onCalculateNewVersion()"
                                               id="no-vendor-change">
                                                {{"quotes.create_new_for_vendor_change" | translate}}
                                            </a>
                                        </div>
                                    </l7-address-card>

                                    <l7-address-card fxFlex
                                                     *ngIf="pageData.newGetQuote.objects[0].vendor?.nav_contact_number"
                                                     [title]="'global.nav_contact' | translate"
                                                     [contentHtml]="pageData.newGetQuote.objects[0].vendor?.nav_contact_number"
                                                     [flat]="true">
                                    </l7-address-card>
                                </div>
                            </mat-card-content>
                            <mat-card-actions *ngIf="isVendorChangeAllowed">
                                <button mat-button
                                        id="anchor_change-vendor"
                                        color="accent"
                                        (click)="onChangeVendor()">{{ 'quotes.change_supplier' | translate }}</button>
                            </mat-card-actions>
                        </mat-card>
                        <mat-card appearance="outlined" *ngIf="lessee"
                                  class="mat-card-outlined"
                                  fxFlex="50">
                            <mat-card-header>
                                <h3 matCardTitle>{{ 'quotes.object_location_details_title' | translate }}</h3>
                            </mat-card-header>
                            <mat-card-content>
                                <l7-address-card [flat]="true"
                                                 [title]="' ' | translate"
                                                 [contact]="lessee">
                                </l7-address-card>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <mat-card appearance="outlined" *ngIf="pageData?.newGetQuote.saleAndLeaseBack?.code !== null && pageData?.newGetQuote.saleAndLeaseBack?.code !== undefined"
                              class="mat-card-outlined">
                        <mat-card-header>
                            <h3 matCardTitle>{{ 'quotes.payment_contract_headline' | translate }}</h3>
                        </mat-card-header>
                        <mat-card-content>
                            <l7-slb-quote-detail-card [quoteId]="pageData?.newGetQuote.quoteId"
                                                      [completed]="pageData?.newGetQuote.saleAndLeaseBack?.completed">
                            </l7-slb-quote-detail-card>
                        </mat-card-content>
                    </mat-card>

                    <mat-card appearance="outlined" class="mat-card-outlined" *ngIf="insuranceAndHandlingFeeValues">
                        <mat-card-content>
                            <l7-insurance-form #feeFormComponent
                                               (loaded)="feeFormHasLoaded($event)"
                                               (validation)="isFeeFormValid = $event"
                                               [contractType]="pageData.newGetQuote.inhouseQuoteCalculation.contractType"
                                               [objectGroup]="pageData.objectGroup"
                                               [totalLeasingValue]="pageData.newGetQuote.inhouseQuoteCalculation.quoteCalculation.totalLeasingValue"
                                               [totalTerms]="pageData.newGetQuote.inhouseQuoteCalculation.quoteCalculation.totalTerms"
                                               [view]="'QUOTE_DETAILS'"
                                               [contractStatus]="'BILLABLE'"
                                               [isReadonlyUser]="false"
                                               [navStatus]="15"
                                               [insuranceValueMax]="pageData.newGetQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceValueMax"
                                               [insuranceValueMin]="pageData.newGetQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceValueMin"
                                               [insuranceAndHandlingFeeValues]="insuranceAndHandlingFeeValues">
                            </l7-insurance-form>
                        </mat-card-content>
                    </mat-card>
                    <mat-card appearance="outlined" class="mat-card-outlined">
                        <mat-card-header>
                            <h3 matCardTitle>{{ 'quotes.BankDataTitle' | translate }}</h3>
                            <mat-card-subtitle *ngIf="pageData?.newGetQuote?.inhouseQuoteCalculation?.contractType !== 'MKN'">
                                {{ 'quotes.BankDataSubtitle' | translate }}
                            </mat-card-subtitle>
                            <mat-card-subtitle *ngIf="pageData?.newGetQuote?.inhouseQuoteCalculation?.contractType === 'MKN'">
                                {{ 'quotes.BankDataSubtitleMkn' | translate }}
                            </mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <l7-bank-account-checker (loaded)="isIbanLoaded = $event"
                                                     (ibanValidity)="ibanIsValid($event)"
                                                     (changed)="addBicAndBankNameToBankAccount($event)"
                                                     [ngxsFormName]="'quote.bankAccountForm'"
                                                     [accountOwner]="pageData?.newGetQuote?.lessee.name"
                                                     [showBankOwner]="true">
                            </l7-bank-account-checker>
                        </mat-card-content>
                    </mat-card>
                    <ng-container *ngIf="isIdentificationRequired | async">
                        <ng-container *l7Feature="'beneficialOwner'">
                            <ng-container *ngIf="(this.legalPersonality | async)">
                                <l7-beneficial-owners-view #beneficialOwnersView
                                                           [boList]="(beneficialOwners | async)"
                                                           [lesseeCrefoId]="lessee.crefo_id"
                                                           (boListUpdated)="beneficialOwnersListUpdated($event)">
                                </l7-beneficial-owners-view>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                </div>
            </form>

            <footer fxLayout="column"
                    fxLayoutGap="16px">
                <ng-container *ngIf="beneficialOwnersWasChanged && !this.wasChanged && this.beneficialOwnersView.beneficialOwnersForm.controls.beneficialOwners.valid">
                    <div>
                        <button id="button_update-beneficialOwners"
                                [attr.test-id]="'updateBeneficialOwners'"
                                mat-flat-button
                                [color]="'accent'"
                                (click)="saveBeneficialOwners()"
                                [disabled]="isLoading">
                            {{'global.save_changes' | translate}}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="wasChanged">
                    <div class="info-popup-container">
                        <p class="info-popup-container__body">{{'quotes.quote_changed' | translate}}</p>
                    </div>
                    <div>
                        <button id="button_update-version"
                                mat-flat-button
                                [color]="'accent'"
                                (click)="onUpdateQuote()"
                                [disabled]="isLoading ">
                            {{'global.save_changes' | translate}}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="!wasChanged && !beneficialOwnersWasChanged">
                    <div fxLayout="row"
                         fxLayoutAlign="center center"
                         fxLayoutGap="16px">
                        <div style="display:inline-block;"
                             [matTooltip]="'calculation.credit_check_manually_triggered' | translate"
                             matTooltipClass="tooltip-alert"
                             [matTooltipDisabled]="(creditCheckManuallyTriggered | async) === false">
                            <button mat-button
                                    [color]="'accent'"
                                    id="button_new-version"
                                    (click)="onCalculateNewVersion()"
                                    [disabled]="(creditCheckManuallyTriggered | async)">{{'quotes.btn_create_new_version' | translate }}</button>
                        </div>
                        <div style="display:inline-block;"
                             [matTooltip]="tooltip_signing_process_disabled"
                             [matTooltipDisabled]="can_start_signing_process"
                             matTooltipClass="tooltip-alert">
                            <button mat-flat-button
                                    [color]="'accent'"
                                    id="button_start-signing"
                                    (click)="onStartSigning()"
                                    [disabled]="!can_start_signing_process"> {{'quotes.btn_start_contract_process' | translate }}</button>
                        </div>
                    </div>
                </ng-container>
                <a (click)="onBackToOverview()"
                   id="anchor_back-to-quotelist">{{'quotes.btn_back_calculation_overview'| translate }}</a>
            </footer>
        </div>
    </l7-page-content>
</l7-page>
