<div class="page-container">
  <main fxLayout="column">
      <div fxLayout="row">
          <div fxFlex="95">
              <ng-container *ngTemplateOutlet="header"></ng-container>
          </div>
          <l7-calculator-toggle-switch [initialState]="showAdditionalFields"
                                       (toggleFields)="toggleFields($event)" fxFlex="5" fxLayout="row" fxLayoutAlign="center end"></l7-calculator-toggle-switch>
      </div>
      <div>
          <!-- mobile tab representation -->
          <ng-container *ngIf="isMobile">
              <mat-tab-group>
                  <form [formGroup]="mainCalculationForm"
                        ngxsForm="calculation.mainCalculationForm">
                      <mat-tab label="Kalkulieren">
                          <ng-container *ngTemplateOutlet="calculationHeader"></ng-container>
                          <ng-container *ngTemplateOutlet="templateBasicForm"></ng-container>
                          <ng-container *ngTemplateOutlet="templateCalculationForm"></ng-container>
                      </mat-tab>
                      <mat-tab label="Zustand/Vers.">
                          <ng-container *ngTemplateOutlet="templateUsedForm"></ng-container>
                          <ng-container *ngTemplateOutlet="templateFeeForm"></ng-container>
                          <ng-container *ngTemplateOutlet="templateSlbCodeForm"></ng-container>
                      </mat-tab>
                      <mat-tab label="BWÜ">
                          <ng-container *ngTemplateOutlet="templateProvisionForm"></ng-container>
                          <ng-container *ngTemplateOutlet="templateHandlingFeeForm"></ng-container>
                      </mat-tab>
                  </form>
              </mat-tab-group>
              <ng-container *ngTemplateOutlet="footer"></ng-container>
          </ng-container>
          <!-- desktop representation -->
          <ng-container *ngIf="!isMobile">
              <form [formGroup]="mainCalculationForm"
                    ngxsForm="calculation.mainCalculationForm">
                  <div class="calculator-screen">
                      <ng-container *ngTemplateOutlet="calculationHeader"></ng-container>
                      <div class="body">
                          <div class="left">
                              <ng-container *ngTemplateOutlet="templateBasicForm"></ng-container>
                              <ng-container *ngTemplateOutlet="templateCalculationForm"></ng-container>
                          </div>
                          <div class="right">
                              <ng-container *ngTemplateOutlet="templateUsedForm"></ng-container>
                              <ng-container *ngTemplateOutlet="templateFeeForm"></ng-container>
                              <ng-container *ngTemplateOutlet="templateSlbCodeForm"></ng-container>
                              <ng-container *ngTemplateOutlet="templateHandlingFeeForm"></ng-container>
                          </div>
                          <div class="bottom">
                              <hr>
                              <ng-container *ngTemplateOutlet="templateProvisionForm"></ng-container>
                          </div>
                      </div>
                      <ng-container *ngTemplateOutlet="footer"></ng-container>
                  </div>
              </form>
          </ng-container>
      </div>
  </main>
</div>

<!-- TEMPLATES -->
<ng-template #header>
  <header class="header">
    <h3 class="hidden-mobile">Angebotskalkulation</h3>
    <ng-container *ngIf="screenMode">
      <h2>{{selectedLessee?.name}}</h2>
      <h4 *ngIf="selectedLessee?.nav_contact_number">{{'global.nav_contact' | translate}}: {{selectedLessee?.nav_contact_number}}</h4>
    </ng-container>
    <p class="hidden-mobile">Bitte wählen Sie den zu errechnenden Wert:</p>
  </header>
</ng-template>

<!-- leasingObjectForm ('name') -->
<ng-template #calculationHeader>
  <div class="header">
    <div class="left">
      <l7-function-resolve (functionParam)="setValueToCalculate($event)"
                           [disableFirstRate]="disableFuncParamFirstRate"
                           [disableLastRate]="disableFuncParamLastRate"
                           [selectedResolveParam]="valueToCalculate$ | async"
                           [contractType]="basicForm.get('contract_type').value"
                           [disabled]="!basicForm.valid">
      </l7-function-resolve>
    </div>
    <div class="right">
      <ng-container *ngIf="isCreateQuote">
        <form class="form form-leasing-object"
              [formGroup]="leasingObjectForm"
              autocomplete="off">
          <div class="input-container">
            <mat-form-field appearance="outline">
              <mat-label>Objektbezeichnung</mat-label>
              <input formControlName="name"
                     id="input_object-name"
                     matInput
                     l7InputRef
                     l7MarkAsTouched
                     placeholder="Objektbezeichnung *">
              <mat-error *ngIf="leasingObjectForm.get('name').errors?.maxlength">
                {{'error.name_bigger_than' | translate}}
              </mat-error>
            </mat-form-field>
            <hr>
          </div>
        </form>
      </ng-container>
    </div>

  </div>
</ng-template>

<!-- basicForm ('object_group', 'contract_type', 'total_leasing_value', 'total_terms') -->
<ng-template #templateBasicForm>
  <form [formGroup]="basicForm"
        class="form form-basic"
        (keydown.enter)="calculate($event)"
        id="form_calculation"
        autocomplete="off">
    <div class="input-container input-object-group">
      <mat-form-field class="block"
                      appearance="outline">
        <mat-label>Objektgruppe</mat-label>
        <mat-select (selectionChange)="calculate($event)"
                    [(value)]="selectedObjectGroup"
                    formControlName="object_group"
                    id="select_object-group">
          <mat-option>
            <ngx-mat-select-search noEntriesFoundLabel="Keine Objektgruppe gefunden ..."
                                   [formControl]="objectGroupFilterCtrl">
            </ngx-mat-select-search>
          </mat-option>
          <ng-container *ngIf="objectGroups?.length">
            <mat-option *ngFor="let objectGroup of filteredObjectGroups$ | async"
                        [value]="objectGroup">
              {{objectGroup.code}}: {{objectGroup.name}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="input-container input-contract-type">
      <mat-form-field class="block"
                      appearance="outline">
        <mat-label>Vertragsart</mat-label>
        <mat-select (selectionChange)="calculate($event)"
                    [(value)]="selectedContractType"
                    formControlName="contract_type"
                    id="select_contract-type">
          <ng-container *ngIf="objectGroups">
            <mat-option *ngFor="let contractType of contractTypes;"
                        [value]="contractType">
              {{contractType}}-Vertrag
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="input-container">
      <mat-form-field class="block"
                      appearance="outline">
        <mat-label *ngIf="basicForm.getRawValue().contract_type!==contractTypeId.MIETKAUF">Laufzeit</mat-label>
        <mat-label *ngIf="basicForm.getRawValue().contract_type===contractTypeId.MIETKAUF">Mietkaufzeit Gesamt</mat-label>
        <mat-select formControlName="total_terms"
                    [(value)]="selectedContractTerms"
                    (selectionChange)="calculate($event)"
                    id="select_total-terms">

          <mat-option *ngFor="let range of amortizationRange; let i = index"
                      [value]="amortizationRange[i]">
            {{range}} Monate
          </mat-option>

        </mat-select>
      </mat-form-field>
    </div>
    <div class="input-container">
      <mat-form-field appearance="outline">
        <mat-label>Objektwert in Euro</mat-label>
        <input formControlName="total_leasing_value"
               id="input_object-value"
               matInput
               l7InputRef
               l7MarkAsTouched
               (debounceKeyDown)="calculate($event)"
               currencyMask
               placeholder="Objektwert in Euro *">
        <mat-error *ngIf="basicForm.get('total_leasing_value').errors && basicForm.get('total_leasing_value').dirty">
          Wert zwischen {{minimumTotalLeasingValue}} € und {{maximumTotalLeasingValue}} € eingeben.
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</ng-template>

<!-- calculationForm ('down_payment', 'down_payment_percent', 'residual_value', 'residual_value_percent', 'instalment', 'leasing_factor', 'yearly_interest') -->
<ng-template #templateCalculationForm>
  <!-- TODO initialize calculation form component in component -->
  <l7-calculation-form [contractType]="basicForm.get('contract_type')?.value"
                       [totalValue]="basicForm.get('total_leasing_value')?.value"
                       [showAdditionalFields]="showAdditionalFields"
                       [isFormValid]="basicForm.valid"
                       [calculationForm]="calculationForm"
                       [provisionForm]="provisionForm"
                       [calculationErrors]="calculationState.leasingQuoteCalculation.errors"
                       [minResidualValue]="(minResidualValue | async)"
                       [downPaymentSettings]="downPaymentSettings | async"
                       (setFunctionParam)="setFirstOrLastRateFuncParam($event)"
                       (calculate)="calculate($event)">
  </l7-calculation-form>
</ng-template>


<ng-template #templateUsedForm>
  <l7-calculator-used-condition-form style="min-height: 52px;"
                                     #conditionFormComponent
                                     (objectConditionHasChanged)="changeTotalTermsRange($event)"></l7-calculator-used-condition-form>
</ng-template>

<ng-template #templateFeeForm>
  <l7-calculator-fee-form #feeFormComponent
                          [ngxsStateName]="'calculation'"
                          [showAdditionalFields]="showAdditionalFields"
                          [view]="screenMode"
                          [objectGroup]="selectedObjectGroup"
                          [calculation]="calculationState.leasingQuoteCalculation.quote_calculation"
                          [contractType]="basicForm.get('contract_type')?.value"
                          [totalLeasingValue]="calculationState.leasingQuoteCalculation.quote_calculation?.total_leasing_value"
                          (validation)="isFeeFormValid = $event"
                          (calculate)="calculate($event)">
  </l7-calculator-fee-form>
</ng-template>

<ng-template #templateSlbCodeForm>
  <ng-container>
    <l7-slb-code-form>
    </l7-slb-code-form>
  </ng-container>
</ng-template>

<!-- handlingFeeForm ('dealer_commission', 'dealer_commission_percent') -->
<ng-template #templateHandlingFeeForm>
  <form [formGroup]="provisionForm"
        class="form form-provision"
        (keydown.enter)="calculate($event)"
        [ngClass]="{'disabled': !basicForm.valid}"
        autocomplete="off">
    <div class="input-container"
         [ngClass]="{'field-hidden': !showAdditionalFields}">
      <mat-form-field appearance="outline">
        <mat-label>Händlerprovision in Euro</mat-label>
        <input formControlName="dealer_commission"
               matInput
               l7InputRef
               l7MarkAsTouched
               placeholder="Händlerprovision in Euro"
               (debounceKeyDown)="calculate($event)"
               id="input_dealer-provision-euro"
               currencyMask>
      </mat-form-field>
    </div>
    <div class="input-container"
         [ngClass]="{'field-hidden': !showAdditionalFields}">
      <mat-form-field appearance="outline">
        <mat-label>Händlerprovision in %</mat-label>
        <input formControlName="dealer_commission_percent"
               matInput
               l7InputRef
               l7MarkAsTouched
               placeholder="Händlerprovision in %"
               (debounceKeyDown)="calculate($event)"
               id="input_dealer-provision-percent"
               currencyMask
               [l7Min]="0"
               [l7Max]="100"
               [options]="{suffix: ' %'}">
      </mat-form-field>
    </div>
  </form>
</ng-template>

<!-- provisionForm ('refinancing_rate', 'risk_discount', 'present_value_profit', 'present_value_profit_percent', 'exploitation_code') -->
<ng-template #templateProvisionForm>
  <form [formGroup]="provisionForm"
        class="form form-conditions"
        (keydown.enter)="calculate($event)"
        [ngClass]="{'disabled': !basicForm.valid}"
        autocomplete="off">
    <div class="input-container"
         [ngClass]="{'field-hidden': !showAdditionalFields}">
      <mat-form-field appearance="outline" class="smaller">
        <mat-label>Einstandskondition in %</mat-label>
        <input formControlName="refinancing_rate"
               matInput
               l7InputRef
               l7MarkAsTouched
               (debounceKeyDown)="calculate($event)"
               placeholder="Einstandskondition in %"
               id="input_refinancing-rate"
               currencyMask
               [l7Min]="0"
               [l7Max]="100"
               [options]="{suffix: ' %'}">
        <mat-error *ngIf="provisionForm.get('refinancing_rate').errors">
          {{'error.value_bigger_than' | translate : {param1: '0'} }}
        </mat-error>
        <mat-icon matSuffix
                  [fontSet]="'l7'"
                  [fontIcon]="'Alert_Circle'"
                  *ngIf="provisionForm.controls.refinancing_rate.value !== (calculationState$ | async)?.leasingQuoteCalculation?.quote_calculation?.default_refinancing_interest">
        </mat-icon>
        <mat-hint *ngIf="provisionForm.controls.refinancing_rate.value !== (calculationState$ | async)?.leasingQuoteCalculation?.quote_calculation?.default_refinancing_interest">
          {{ 'calculation.refinancing_rate_warning' | translate: {nav_value: (calculationState$ | async)?.leasingQuoteCalculation?.quote_calculation?.default_refinancing_interest} }}
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="input-container"
         [ngClass]="{'field-hidden': !showAdditionalFields}">
      <mat-form-field appearance="outline" class="smaller">
        <mat-label>Kalk. Risikoabschlag</mat-label>
        <input formControlName="risk_discount"
               matInput
               l7InputRef
               placeholder="Kalk. Risikoabschlag"
               currencyMask
               [options]="{suffix: ' %'}"
               id="input_risk-discount">
      </mat-form-field>
    </div>
    <div class="input-container"
         [ngClass]="{'field-hidden': !showAdditionalFields}">
      <mat-form-field appearance="outline" class="smaller">
        <mat-label>BWÜ ohne Zusatzerträge</mat-label>
        <input formControlName="present_value_profit"
               matInput
               l7InputRef
               l7MarkAsTouched
               placeholder="BWÜ ohne Zusatzerträge"
               id="input_present-value-profit"
               currencyMask>
        <mat-error *ngIf="provisionForm.get('present_value_profit').errors">
          {{'error.value_bigger_than' | translate : {param1: '0'} }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="input-container"
         [ngClass]="{'field-hidden': !showAdditionalFields}">
      <mat-form-field appearance="outline" class="smaller">
        <mat-label>BWÜ ohne Zusatzerträge %</mat-label>
        <input formControlName="present_value_profit_percent"
               matInput
               l7InputRef
               l7MarkAsTouched
               placeholder="BWÜ ohne Zusatzerträge %"
               id="input_present-value-profit-percent"
               currencyMask
               [options]="{suffix: ' %'}">
        <mat-error *ngIf="provisionForm.get('present_value_profit_percent').errors">
          {{'error.value_bigger_than' | translate : {param1: '0'} }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="input-container"
         [ngClass]="{'field-hidden': !showAdditionalFields}">
      <mat-form-field appearance="outline" class="smaller">
        <mat-label>Objektbewertung</mat-label>
        <input formControlName="exploitation_code"
               matInput
               l7InputRef
               (focusout)="calculate($event)"
               placeholder="Objektbewertung"
               id="input_exploitation_code">
      </mat-form-field>
    </div>
  </form>
</ng-template>

<ng-template #footer>
  <div class="footer">
    <ng-container *ngIf="screenMode === 'CREATE'">
      <div class="button-row">
        <button tabindex="-1"
                mat-button
                [color]="'accent'"
                (click)="onGoToDashboard(screenMode)"
                id="button_abort-calculation">
          Abbrechen
        </button>
        <button mat-flat-button
                [color]="'accent'"
                (click)="onSave('CREATE')"
                id="button_save-calculation"
                [disabled]="!quote_can_be_saved || (isLoading$ | async)">
          Angebot speichern
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="screenMode === 'TRANSIENT'">
      <div class="button-row">
        <button mat-flat-button
                [color]="'accent'"
                (click)="onGoToDashboard(screenMode)"
                id="button_back-to-dashboard">
          Zur Startseite
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="screenMode === 'UPDATE'">
      <div class="button-row">
        <button tabindex="-1"
                mat-button
                [color]="'accent'"
                (click)="onBackToDetailView()"
                id="button_back-to-detail-view">
          Zurück zur Detailansicht
        </button>
        <button mat-flat-button
                [color]="'accent'"
                (click)="onSave('UPDATE')"
                id="button_create-new-version"
                [disabled]="!quote_can_be_saved ||(isLoading$ | async)">
          Neue Version speichern
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>
