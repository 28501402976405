/* libs/quote/src/Views/retailer-quote-details/RetailerQuoteDetailsView.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host mat-card-header ::ng-deep .mat-mdc-card-header-text {
  width: 100%;
}
:host l7-text-block.small-text ::ng-deep p {
  font-size: 12px;
}
:host legend {
  font-size: 12px;
  font-weight: unset;
  margin-bottom: 5px;
}
:host .block-with-margin {
  margin-left: 20px;
}
:host .mat-mdc-menu-trigger {
  cursor: pointer;
}
:host mat-list-item.item-disabled mat-card,
:host mat-list-item.item-disabled mat-icon {
  color: rgba(48, 59, 69, 0.38);
}
@media only screen and (max-width: 600px) {
  :host .small-card {
    width: 45%;
  }
}
/*# sourceMappingURL=RetailerQuoteDetailsView.css.map */
