<l7-page>
  <l7-page-header *ngIf="lessee"
                  [hasBackNavigation]="true"
                  [supportiveTitle]="('global.nav_contact' | translate) + ': ' + lessee.nav_contact_number"
                  [subTitle]="lessee.name"
                  [title]="('slb.details_headline' | translate)">
  </l7-page-header>
    <l7-page-pre-content fxLayoutAlign="end">
        <div class="cam-check-container"
             fxLayout="column"
             fxFlex="20"
             fxLayoutAlign="center center">
            <l7-gwg-dependency-bar [hidden]="kuevComponentData"
                                   [creditState]="creditRating">
            </l7-gwg-dependency-bar>
        </div>
    </l7-page-pre-content>
    <l7-page-content cdkScrollable>
        <form [formGroup]="slbDetailsForm" l7ScrollToError (ngSubmit)="kuevComponentData ? onSave() : null"
              fxLayout="column"
              fxLayoutGap="16px"
              id="form_leasing-quote">
            <mat-card appearance="outlined" class="mat-card-outlined">
                <mat-card-header>
                    <mat-card-title fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                        <div>{{ 'slb.title_shop_basket' | translate }}</div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column">
                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px"
                         fxLayoutGap="16px">
                        <ng-container [formGroup]="objectDetailsForm">
                            <mat-form-field [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                                            id="purchasePrice"
                                            appearance="outline">
                                <mat-label>{{ 'global.buy_price' | translate }}</mat-label>
                                <input matInput
                                       *ngIf="!kuevComponentData"
                                       type="text"
                                       currencyMask
                                       l7MarkAsTouched
                                       [attr.test-id]="'totalLeasingValueNet'"
                                       [formControlName]="'totalLeasingValueNet'">
                                <input matInput
                                       l7SelectOnDblClick
                                       currencyMask
                                       l7MarkAsTouched
                                       *ngIf="kuevComponentData"
                                       (keyup)="purchasePriceSubject.next($event)"
                                       [attr.test-id]="'totalLeasingValueNet'"
                                       [formControlName]="'totalLeasingValueNet'">
                                <mat-error *ngIf="objectDetailsForm.controls.totalLeasingValueNet.invalid && objectDetailsForm.controls.totalLeasingValueNet.hasError('required') && kuevComponentData && objectDetailsForm.controls.totalLeasingValueNet.getError('required') as error">
                                    {{'global.mandatoryField' | translate}}
                                </mat-error>
                                <mat-error *ngIf="objectDetailsForm.controls.totalLeasingValueNet.invalid && objectDetailsForm.controls.totalLeasingValueNet.hasError('percentage') && kuevComponentData && objectDetailsForm.controls.totalLeasingValueNet.getError('percentage') as error">
                                    Der maximale Kaufpreis beträgt {{error.max | number}} € (110%)
                                </mat-error>
                                <mat-error *ngIf="objectDetailsForm.controls.totalLeasingValueNet.invalid && objectDetailsForm.controls.totalLeasingValueNet.hasError('min') && kuevComponentData && objectDetailsForm.controls.totalLeasingValueNet.getError('min') as error">
                                    Der minimale Kaufpreis beträgt {{error.min | number}} €
                                </mat-error>
                            </mat-form-field>

                            <div [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'" fxLayoutAlign="start center" fxLayoutGap="8px">
                                <mat-form-field appearance="outline" class="smallInput">
                                    <mat-label>{{ 'global.taxes_percent' | translate }}</mat-label>
                                    <ng-container *ngIf="(contractType | async) !== 'MKN'; else vatPercentageMKN">
                                        <input matInput
                                               type="text"
                                               currencyMask
                                               [options]="{suffix: ' %', precision: 0}"
                                               l7MarkAsTouched
                                               [attr.test-id]="'vatPercentage'"
                                               [formControlName]="'vatPercentage'">
                                    </ng-container>
                                    <ng-template #vatPercentageMKN>
                                        <input matInput
                                               type="text"
                                               [attr.test-id]="'vatPercentage'"
                                               [formControlName]="'vatPercentage'">
                                    </ng-template>

                                </mat-form-field>
                                <div>
                                    <mat-icon [fontSet]="'l7'"
                                              [fontIcon]="'Link'"></mat-icon>
                                </div>
                                <mat-form-field appearance="outline" class="smallInput">
                                    <mat-label>{{ 'global.taxes_value' | translate }}</mat-label>
                                    <ng-container *ngIf="(contractType | async) !== 'MKN'; else vatAmountMKN">
                                        <input matInput
                                               type="text"
                                               currencyMask
                                               l7MarkAsTouched
                                               [attr.test-id]="'vatAmount'"
                                               [formControlName]="'vatAmount'">
                                    </ng-container>
                                    <ng-template #vatAmountMKN>
                                        <input matInput
                                               type="text"
                                               [attr.test-id]="'vatAmount'"
                                               [formControlName]="'vatAmount'">
                                    </ng-template>
                                </mat-form-field>
                            </div>

                            <mat-form-field [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                                            appearance="outline">
                                <mat-label>{{ 'global.total_price' | translate }}</mat-label>
                                <input matInput
                                       type="text"
                                       currencyMask
                                       l7MarkAsTouched
                                       [attr.test-id]="'totalLeasingValueGross'"
                                       [formControlName]="'totalLeasingValueGross'">
                            </mat-form-field>
                        </ng-container>

                    </div>
                    <l7-spacer  *ngIf="(isObjectValueChanged | async)" [thickness]="'bottom'" [multiplier]="24">
                        <form [formGroup]="objectValueChangeForm">
                            <div fxLayoutGap="0"
                                 fxLayoutGap.gt-sm="16px"
                                 fxLayout="column"
                                 fxLayout.gt-sm="row">
                                <mat-form-field fxFlex="33"
                                                appearance="outline">
                                    <mat-label>{{'slb.contractManagement.downPayment' | translate}}</mat-label>
                                    <input matInput
                                           type="text"
                                           currencyMask
                                           formControlName="downPayment"
                                           [attr.test-id]="'AcknowledgementDownPaymentInput'">
                                </mat-form-field>
                                <mat-form-field fxFlex="33"
                                                *ngIf="(contractType | async) === 'MKN'"
                                                appearance="outline">
                                    <mat-label>{{'slb.contractManagement.firstInstalment' | translate}}</mat-label>
                                    <input matInput
                                           type="text"
                                           currencyMask
                                           formControlName="firstInstalment"
                                           [attr.test-id]="'AcknowledgementFirstInstalmentInput'">
                                </mat-form-field>
                                <mat-form-field fxFlex="33"
                                                appearance="outline">
                                    <mat-label>{{'slb.contractManagement.instalment' | translate}}</mat-label>
                                    <input matInput
                                           type="text"
                                           currencyMask
                                           formControlName="instalment"
                                           [attr.test-id]="'AcknowledgementInstalmentInput'">
                                </mat-form-field>
                                <mat-form-field fxFlex="33"
                                                appearance="outline"
                                                *ngIf="(contractType | async) === 'TA' || (contractType | async) === 'KFZ'">
                                    <mat-label>{{'slb.contractManagement.residualValue' | translate}}</mat-label>
                                    <input matInput
                                           type="text"
                                           required
                                           currencyMask
                                           formControlName="residualValue"
                                           [attr.test-id]="'AcknowledgementResidualValueInput'">
                                </mat-form-field>
                                <mat-form-field fxFlex="33"
                                                appearance="outline"
                                                *ngIf="(contractType | async) === 'MKN'">
                                    <mat-label>{{'slb.contractManagement.lastInstalment' | translate}}</mat-label>
                                    <input matInput
                                           type="text"
                                           required
                                           currencyMask
                                           formControlName="lastInstalment"
                                           [attr.test-id]="'AcknowledgementLastInstalmentInput'">
                                </mat-form-field>

                                <mat-form-field fxFlex="33"
                                                appearance="outline">
                                    <mat-label>
                                        <mat-label>
                                            @if (insuranceType === IInsuranceTypeDto.Pro100) {
                                                {{'slb.contractManagement.pro100' | translate}}
                                            } @else {
                                                {{'slb.contractManagement.insuranceValue' | translate}}
                                            }
                                        </mat-label>
                                    </mat-label>
                                    <input matInput
                                           type="text"
                                           currencyMask
                                           formControlName="insuranceValue"
                                           [attr.test-id]="'AcknowledgementInsuranceValueInput'">
                                </mat-form-field>

                            </div>

                        </form>
                        <l7-banner
                            [appearance]="'outlined'"
                            [color]="'warn'"
                            [icon]="'Alert_Triangle'"
                            [iconFontSet]="'l7'"
                            [content]="'slb.contractManagement.objectValueChangeBanner' | translate">
                        </l7-banner>
                    </l7-spacer>

                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px"
                         fxLayoutGap="16px">
                        <l7-date-picker [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                                        [label]="'slb.label_acknowledgment_date'"
                                        [controlName]="'acknowledgementDate'"
                                        [uniqueId]="'acknowledgementDate'"
                                        [maxDate]="maxDate"
                                        (dateChanged)="onAcknowledgementDateChanged($event)"
                                        [parentFormGroup]="slbDetailsForm">
                        </l7-date-picker>
                        <mat-form-field appearance="outline" [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                            <mat-label>{{'slb.label_leasing_start_date' | translate}}</mat-label>
                            <mat-select formControlName="leasingStartDate"
                                        [attr.test-id]="'leasingStartDate'">
                                <ng-container *ngIf="(allowedLeasingStartDates |  async)">
                                    <mat-option *ngFor="let leasingStartDate of (allowedLeasingStartDates | async);"
                                                [value]="leasingStartDate | date: 'dd.MM.yyyy'">
                                        {{leasingStartDate | date: 'dd.MM.yyyy'}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <l7-date-picker [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                                        [label]="'slb.label_billing_date'"
                                        [controlName]="'billingDate'"
                                        [uniqueId]="'billingDate'"
                                        [minDate]="minBillingDate | async"
                                        [maxDate]="maxDate"
                                        [parentFormGroup]="slbDetailsForm">
                        </l7-date-picker>
                    </div>

                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px"
                         fxLayoutGap="16px">
                        <ng-container [formGroup]="objectDetailsForm">
                            <mat-form-field [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                                            appearance="outline">
                                <mat-label>{{ 'quote.retailers.calculation.input.condition.label' | translate }}</mat-label>
                                <mat-select [formControlName]="'objectCondition'"
                                            [attr.test-id]="'objectCondition'">
                                    <mat-option [value]="'NEW'">{{ 'condition.NEW' | translate }}</mat-option>
                                    <mat-option [value]="'USED'">{{ 'condition.USED' | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <mat-form-field [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                                        appearance="outline">
                            <mat-label>
                                {{ 'contract_management.takeover_confirmation.object.year_of_construction' | translate }}
                            </mat-label>
                            <mat-select formControlName="yearOfConstruction"
                                        [attr.test-id]="'year_of_construction'">
                                <mat-option *ngFor="let year of this.years; trackBy:yearTrackByIndex"
                                            [attr.test-id]="'AcknowledgementYearOfConstructionSelect' + year"
                                            [value]="year">{{ year }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px"
                         fxLayoutGap="16px" [style]="'margin-bottom: 12px'">
                        <span>{{'contract_management.takeover_confirmation.object.is_a_chassis_number' | translate}}</span>
                        <mat-radio-group formControlName="chassisNumber"
                                         (change)="changeSerialNumberLabel($event)">
                            <mat-radio-button [value]="false">Nein</mat-radio-button>
                            <mat-radio-button [value]="true">Ja</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px"
                         fxLayoutGap="16px">
                        <mat-form-field appearance="outline"
                                        [fxFlex]="'0 1 calc(100% - 16px)'">
                            <mat-label>{{ labelSerialNumber | async }}</mat-label>
                            <input l7InputRef
                                   l7NullValue
                                   l7MarkAsTouched
                                   matInput
                                   (emptyToNull)="handleEmptyString($event)"
                                   formControlName="objectSerialNumber"
                                   id="input_object_serial_number">
                            <mat-error *ngIf="slbDetailsForm.controls.objectSerialNumber.invalid && slbDetailsForm.controls.objectSerialNumber.hasError('serialNumber')">
                                I, O, Q nicht zulässig
                            </mat-error>
                            <mat-error *ngIf="(slbDetailsForm.controls.objectSerialNumber.getError('minlength') ||
                              slbDetailsForm.controls.objectSerialNumber.getError('maxlength')) && !slbDetailsForm.controls.objectSerialNumber.hasError('serialNumber')">
                                17 Zeichen nötig
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="mat-card-outlined">
                <mat-card-header>
                    <mat-card-title fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                        <div *ngIf="(contractType | async) !== 'MKN'; else lesseeTitleMKN">{{ 'slb.title_lessee' | translate }}</div>
                        <ng-template #lesseeTitleMKN>
                            <div>{{ 'slb.title_lessee_mkn' | translate }}</div>
                        </ng-template>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column">
                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px"
                         fxLayoutGap="16px">
                        <mat-form-field appearance="outline"
                                        [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'">
                            <mat-label *ngIf="(contractType | async) !== 'MKN'; else lesseeTaxNumberLabelMKN">{{'slb.tax_number_lessee' | translate}}</mat-label>
                            <ng-template #lesseeTaxNumberLabelMKN>
                                <mat-label>{{'slb.tax_number_lessee_mkn' | translate}}</mat-label>
                            </ng-template>
                            <input l7NullValue
                                   l7MarkAsTouched
                                   matInput
                                   (emptyToNull)="handleEmptyString($event)"
                                   [placeholder]="(contractType | async) !== 'MKN' ? ('slb.tax_number_lessee' | translate) : ('slb.tax_number_lessee_mkn' | translate)"
                                   formControlName="taxNumberLessee"
                                   id="input_tax_number_lessee">
                        </mat-form-field>

                        <mat-form-field appearance="outline"
                                        [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'">
                            <mat-label *ngIf="(contractType | async) !== 'MKN'; else billingNumberLabelMKN">
                                {{ 'slb.billing_number_label' | translate }}
                            </mat-label>
                            <ng-template #billingNumberLabelMKN>
                                <mat-label>
                                    {{ 'slb.billing_number_label_mkn' | translate }}
                                </mat-label>
                            </ng-template>
                            <input l7NullValue
                                   l7MarkAsTouched
                                   matInput
                                   (emptyToNull)="handleEmptyString($event)"
                                   [placeholder]="(contractType | async) !== 'MKN' ? ('slb.billing_number_label' | translate) : ('slb.billing_number_label_mkn' | translate)"
                                   formControlName="billingNumber"
                                   id="input_billing_number">
                        </mat-form-field>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="mat-card-outlined">
                <mat-card-header>
                    <mat-card-title>
                        <div>{{ 'slb.title_bank_data' | translate }}</div>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <ng-container *ngIf="(slbCode | async) === 'UNECHTLN' || (slbCode | async) === 'UNECHTLN_V2' || (slbCode | async) === 'UNECHTLNMK'; else payVendor">
                            <div *ngIf="(contractType | async) !== 'MKN'; else subTitleBankDataMKN">
                                {{'slb.sub_title_bank_data_lessee' | translate}}
                            </div>
                            <ng-template #subTitleBankDataMKN>
                                <div>
                                    {{'slb.sub_title_bank_data_lessee_mkn' | translate}}
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-template #payVendor>
                            <div *ngIf="(contractType | async) !== 'MKN'; else subTitlePayVendorMKN">
                                {{'slb.sub_title_bank_data_vendor' | translate}}
                            </div>
                            <ng-template #subTitlePayVendorMKN>
                                <div>
                                    {{'slb.sub_title_bank_data_vendor_mkn' | translate}}
                                </div>
                            </ng-template>
                        </ng-template>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content fxLayout="column">
                    <ng-container *ngIf="(slbCode | async) === 'UNECHTLN' || (slbCode | async) === 'UNECHTLN_V2' || (slbCode | async) === 'UNECHTLNMK';">
                        <div fxLayout="row" [style]="'margin-bottom: 10px;'">
                            {{'Bankverbindung des Zahlungsempfänger:' | translate}}
                        </div>
                        <l7-bank-account-checker #bankAccountCheckerComponent
                                                 [isOwnerEditable]="true"
                                                 (changed)="bankAccountChanged($event)"
                                                 (ibanValidity)="isIbanValid = $event"
                                                 fxFlex="100"
                                                 fxLayout="row">
                        </l7-bank-account-checker>
                    </ng-container>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="mat-card-outlined">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'slb.title_confirmations' | translate }}
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{'slb.sub_title_confirmations' | translate}}
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content fxLayout="column" *ngIf="!kuevComponentData">
                    <div fxLayout="column"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px"
                         fxLayoutGap="16px">
                        <mat-checkbox name="SLB_OBJECT_KEPT_BY_LESSEE"
                                      [fxFlex]="'0 1 100%'"
                                      (change)="confirmationChanged($event)"
                                      [checked]="isSlbObjectKeptByLessee | async"
                                      id="checkbox_object_undertake">
                            <ng-container *ngIf="(contractType | async) !== 'MKN'; else objectUndertakeMKN">
                                {{'slb.label_object_undertake' | translate}}
                            </ng-container>
                            <ng-template #objectUndertakeMKN>
                                {{'slb.label_object_undertake_mkn' | translate}}
                            </ng-template>
                        </mat-checkbox>

                        <mat-checkbox name="SLB_ORIGINAL_INVOICE_ATTACHED"
                                      [fxFlex]="'0 1 100%'"
                                      (change)="confirmationChanged($event)"
                                      [checked]="isSlbOriginalInvoiceAttached | async"
                                      id="checkbox_vendor_bill">
                            {{'slb.label_vendor_bill' | translate}}
                        </mat-checkbox>
                    </div>
                </mat-card-content>
                <mat-card-content fxLayout="column" *ngIf="kuevComponentData">
                    <div fxLayout="row">
                        <div fxLayout="column">
                            <mat-checkbox name="SLB_OBJECT_KEPT_BY_LESSEE"
                                          [fxFlex]="'0 1 100%'"
                                          l7MandatoryCheckboxWithError
                                          (change)="confirmationChanged($event)"
                                          [checked]="isSlbObjectKeptByLessee | async"
                                          id="checkbox_object_undertake">
                                <ng-container *ngIf="(contractType | async) !== 'MKN'; else objectUndertakeMKN">
                                    {{'slb.label_object_undertake' | translate}}
                                </ng-container>
                                <ng-template #objectUndertakeMKN>
                                    {{'slb.label_object_undertake_mkn' | translate}}
                                </ng-template>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div fxLayout="column">
                            <mat-checkbox name="SLB_ORIGINAL_INVOICE_ATTACHED"
                                          [fxFlex]="'0 1 100%'"
                                          l7MandatoryCheckboxWithError
                                          (change)="confirmationChanged($event)"
                                          [checked]="isSlbOriginalInvoiceAttached | async"
                                          id="checkbox_vendor_bill">
                                {{'slb.label_vendor_bill' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
                <button mat-stroked-button
                        color="primary"
                        *ngIf="!kuevComponentData"
                        (click)="onGoBack()"
                        id="button_go_back_to_quote_details">
                    {{'global.cancel' | translate}}
                </button>
                <button mat-flat-button
                        color="primary"
                        *ngIf="!kuevComponentData"
                        (click)="onSaveInQuote()"
                        id="button_save-slb">
                    {{'global.save_changes' | translate}}
                </button>
                <button mat-flat-button
                        color="primary"
                        *ngIf="kuevComponentData"
                        id="button_save-slb-with-link">
                    {{'Speichern und Link erstellen' | translate}}
                </button>

            </div>
        </form>
    </l7-page-content>

</l7-page>
