/* libs/quote/src/Components/calculation-extra-payments/CalculationExtraPaymentsComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host .icon-link,
:host l7-custom-form-control {
  margin-bottom: 22px;
  min-width: 24px;
}
:host .icon-link.blocked {
  color: #8028bf;
}
/*# sourceMappingURL=CalculationExtraPaymentsComponent.css.map */
