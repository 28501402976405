/* libs/quote/src/Views/slb-details-form/slb-details-form.component.scss */
:host hr {
  border-top: 1px solid #eee;
}
:host .cam-check-container {
  position: absolute;
  top: 16%;
  padding: 10px;
}
:host .mat-icon.Link {
  margin-bottom: 11px;
  color: rgba(26, 35, 45, 0.38);
}
:host .mat-mdc-form-field.smallInput ::ng-deep .mat-mdc-form-field-infix {
  width: auto !important;
}
:host .mat-mdc-radio-button {
  margin-right: 24px;
}
/*# sourceMappingURL=slb-details-form.component.css.map */
