<l7-dialog-header [titleIconColor]="'warning'" [titleIcon]="'Alert_Triangle'" [closeable]="true" [title]="'quote.retailers.lessees.updateComparisonDialog.title' | translate" (closed)="submitDialog(RetailerRecalculationConfirmationState.Cancel)">

</l7-dialog-header>
<mat-dialog-content fxLayout="column">
    <l7-text-block [text]="'quote.retailers.lessees.updateComparisonDialog.text' | translate"></l7-text-block>
    <l7-spacer [thickness]="'top'"></l7-spacer>

    <div fxFlex="100" fxLayout="row" fxLayoutGap="8px">
        <mat-card class="mat-card-flat more-padding">
            <mat-card-content class="more-padding">
                <div fxFlex
                     fxLayout="row wrap"
                     fxLayoutGap="16px">
                    <fieldset
                        [fxFlex]="'0 1 calc(50% - 16px)'"
                        [fxFlex.lt-md]="'calc(50% - 16px)'">
                        <legend>{{ 'dialogs.leasingFactorChangeComparison.totalLeasingValue' | translate }}</legend>
                        <p>{{(comparisonData | async)?.oldCalculation.objectValue | currency}}</p>
                    </fieldset>
                    <fieldset
                        [fxFlex]="'0 1 calc(50% - 16px)'"
                        [fxFlex.lt-md]="'calc(50% - 16px)'">
                        <legend>{{ 'dialogs.leasingFactorChangeComparison.contractType' | translate }}</legend>
                        <p>{{'contractType.' + (comparisonData | async)?.oldCalculation.contractType | translate}}</p>
                    </fieldset>
                    <fieldset
                        [fxFlex]="'0 1 calc(50% - 16px)'"
                        [fxFlex.lt-md]="'calc(50% - 16px)'">
                        <legend>{{ 'dialogs.leasingFactorChangeComparison.MonthlyRate' | translate }}</legend>
                        <p>{{(comparisonData | async)?.oldCalculation.instalment | currency}}</p>
                    </fieldset>
                    <fieldset
                        [fxFlex]="'0 1 calc(50% - 16px)'"
                        [fxFlex.lt-md]="'calc(50% - 16px)'">
                        <legend>{{ 'dialogs.leasingFactorChangeComparison.term' | translate }}</legend>
                        <p>{{(comparisonData | async)?.oldCalculation.term + ' Monate'}}</p>
                    </fieldset>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="mat-card-flat more-padding">
            <mat-card-content class="more-padding inactive">
                <div fxFlex
                     fxLayout="row wrap"
                     fxLayoutGap="16px">
                    <fieldset
                        [fxFlex]="'0 1 calc(50% - 16px)'"
                        [fxFlex.lt-md]="'calc(50% - 16px)'">
                        <legend>{{ 'dialogs.leasingFactorChangeComparison.totalLeasingValue' | translate }}</legend>
                        <l7-compare-to [valueToShow]="(comparisonData | async)?.newCalculation.totalLeasingValue | currency"
                                       [valueToCompare]="(comparisonData | async)?.oldCalculation.objectValue | currency">
                        </l7-compare-to>
                    </fieldset>
                    <fieldset
                        [fxFlex]="'0 1 calc(50% - 16px)'"
                        [fxFlex.lt-md]="'calc(50% - 16px)'">
                        <legend>{{ 'dialogs.leasingFactorChangeComparison.contractType' | translate }}</legend>
                        <l7-compare-to [valueToShow]="(comparisonData | async)?.newCalculation.type"
                                       [valueToCompare]="(comparisonData | async)?.oldCalculation.contractType">
                        </l7-compare-to>
                    </fieldset>
                    <fieldset
                        [fxFlex]="'0 1 calc(50% - 16px)'"
                        [fxFlex.lt-md]="'calc(50% - 16px)'">
                        <legend>{{ 'dialogs.leasingFactorChangeComparison.MonthlyRate' | translate }}</legend>
                        <l7-compare-to [valueToShow]="(comparisonData | async)?.newCalculation.calculation.instalment | currency"
                                       [valueToCompare]="(comparisonData | async)?.oldCalculation.instalment | currency" >
                        </l7-compare-to>
                    </fieldset>
                    <fieldset
                        [fxFlex]="'0 1 calc(50% - 16px)'"
                        [fxFlex.lt-md]="'calc(50% - 16px)'">
                        <legend>{{ 'dialogs.leasingFactorChangeComparison.term' | translate }}</legend>
                        <l7-compare-to [valueToShow]="(comparisonData | async)?.newCalculation.calculation.terms.toString() + ' Monate'"
                                       [valueToCompare]="(comparisonData | async)?.oldCalculation.term.toString() + ' Monate'">
                        </l7-compare-to>
                    </fieldset>
                </div>
            </mat-card-content>
        </mat-card>
    </div>




</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button [color]="'primary'" [attr.test-id]="'btnNewCalucluation'" (click)="submitDialog(RetailerRecalculationConfirmationState.NewCalculation)">
        {{ 'quote.retailers.lessees.updateComparisonDialog.newCalculation' | translate }}
    </button>
    <button mat-stroked-button [color]="'primary'" [attr.test-id]="'btnConfirm'" (click)="submitDialog(RetailerRecalculationConfirmationState.ConfirmChanges)">
        {{ 'quote.retailers.lessees.updateComparisonDialog.confirm' | translate }}
    </button>
</mat-dialog-actions>
