// #region Imports

import {
    IContactSearchResultEntryDto,
    ILesseeUpdateForQuoteDraftDto, IRetailerQuoteDto,
    RetailerQuoteService,
} from '@abcfinlab/api/global';
import { once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
    QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE_PATH,
    QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH,
} from '../../Routing/RoutePaths';
import {
    ILesseeUpdateComparisonInfo, RetailerRecalculationConfirmationState,
    RetailerRecalculationConfirmationView,
} from '../Dialogs/retailer-recalculation-confirmation/RetailerRecalculationConfirmationView';

// #endregion

export interface ILesseeUpdateInfo {
    quoteId: string;
    lesseeCompanyName: string;
    city: string;
    country: string;
}

export interface IUpdateCalculationInfo {
    quoteId: string;
    newCalculation: IRetailerQuoteDto;
    isRecalculation: boolean;
    lesseeContact: IContactSearchResultEntryDto;
}
/**
 * The presenter of the {@link RetailerUpdateLesseeOverviewComponent} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class RetailerUpdateLesseeOverviewPresenter {

    // #region Fields

    private readonly _retailerQuoteService: RetailerQuoteService;
    private readonly _toastService: ToastService;
    private readonly _dialogService: MatDialog;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _router: Router;
    private readonly _cdr: ChangeDetectorRef;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _lesseesSubject: BehaviorSubject<Array<IContactSearchResultEntryDto>>;
    private _newCalculation: IRetailerQuoteDto;
    private _hasRecalculation: boolean = false;
    private _lesseeUpdateInfo: ILesseeUpdateInfo;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SelectLesseeViewPresenter` class.
     *
     * @public
     */
    public constructor(retailerQuoteService: RetailerQuoteService, busyBoxService: BusyBoxService, translationFacade: TranslationFacade,
        router: Router, activatedRoute: ActivatedRoute, cdr: ChangeDetectorRef, toastService: ToastService, dialogService: MatDialog) {
        this._retailerQuoteService = retailerQuoteService;
        this._dialogService = dialogService;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._toastService = toastService;
        this._router = router;
        this._cdr = cdr;
        this._activatedRoute = activatedRoute;
        this._lesseesSubject = new BehaviorSubject([]);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `lessees` property.
     *
     * @public
     * @readonly
     */
    public get lessees(): Observable<Array<IContactSearchResultEntryDto>> {
        return this._lesseesSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this.initializeLessees();
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    /**
     * @internal
     */
    public onLesseeSelected(lessee: IContactSearchResultEntryDto): void {
        if (this._hasRecalculation) {
            once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                this._retailerQuoteService.get({ leasingQuoteId: this._lesseeUpdateInfo.quoteId }).pipe(),
            ), (res) => {
                const dialogData: ILesseeUpdateComparisonInfo = { oldCalculation: res.detail, newCalculation: this._newCalculation };

                this._dialogService.open(RetailerRecalculationConfirmationView, { data: dialogData }).afterClosed().pipe(untilDestroyed(this)).subscribe((result: RetailerRecalculationConfirmationState) => {
                    if (result === RetailerRecalculationConfirmationState.ConfirmChanges) {
                        this.updateLesseeWithDraft(lessee);
                    }
                    if (result === RetailerRecalculationConfirmationState.NewCalculation) {
                        this.adjustCalculation(lessee);
                    }
                });
            }, (err) => {
                this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
            });
        } else {
            this.updateLesseeWithDraft(lessee);
        }
    }

    private updateLesseeWithDraft(lessee: IContactSearchResultEntryDto): void {
        this._newCalculation.quoteId = this._lesseeUpdateInfo.quoteId;
        const updateLesseeInfo: ILesseeUpdateForQuoteDraftDto = {
            lesseeContact: this._lesseesSubject.value.find(x => x.crefoId === lessee.crefoId),
            newLeasingQuote: this._newCalculation,
        };
        once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
            this._retailerQuoteService.updateDraftWithLessee({ leasingQuoteId: this._lesseeUpdateInfo.quoteId, body: updateLesseeInfo }).pipe(),
        ), (res) => {
            void this._router.navigateByUrl(`presentation/${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}/${String(this._lesseeUpdateInfo.quoteId)}`);
        }, (err) => {
            this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
        });
    }

    private adjustCalculation(lessee: IContactSearchResultEntryDto): void {
        const updateCalculationInfo: IUpdateCalculationInfo = {
            quoteId: this._lesseeUpdateInfo.quoteId,
            newCalculation: this._newCalculation,
            isRecalculation: true,
            lesseeContact: lessee,
        };
        void this._router.navigateByUrl(`presentation/${QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE_PATH}/${this._lesseeUpdateInfo.quoteId}`, { state: updateCalculationInfo });
    }

    /**
     * @private
     */
    private initializeLessees(): void {
        once(combineLatest([this._activatedRoute.data, this._activatedRoute.params]), ([data, params]) => {
            once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                this._retailerQuoteService.lesseeDraftSearch({ country: data.lessees.country, city: data.lessees.city, companyName: data.lessees.name, leasingQuoteId: params.quoteId }).pipe(),
            ), (res) => {
                this._hasRecalculation = res.factorChanged;
                this._newCalculation = res.newLeasingQuote;
                this._lesseesSubject.next(res.searchResults.content);
                this._lesseeUpdateInfo = {
                    country: data.lessees.country,
                    city: data.lessees.city,
                    lesseeCompanyName: data.lessees.name,
                    quoteId: params.quoteId,
                };
            }, (err) => {
                this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
            });
        });
        this._cdr.detectChanges();
    }

}
