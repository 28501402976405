import { ContactsModule } from '@abcfinlab/contacts';
import { TranslationModule } from '@abcfinlab/core';
import { PageModule } from '@abcfinlab/layout';
import { CameraModule, PresentationModule, SignaturePadModule } from '@abcfinlab/presentation';
import { RetailerQuoteStepChipComponent } from '@abcfinlab/quote';
import {
    AbsoluteDirectiveModule,
    BannerModule,
    BreakpointObserverModule,
    CheckboxGroupModule,
    CompareToModule,
    DatePickerModule,
    DialogHeaderModule,
    IbanValidatorModule,
    MandatoryCheckboxWithErrorDirectiveModule,
    MaxValidatorModule,
    MinValidatorModule,
    MotionModule,
    Motions,
    ScrollToErrorModule,
    SelectOnDblClickDirectiveModule,
    SpacerModule,
    SummaryModule,
    TextBlockModule,
    TileModule,
    WizardModule, WrapModule,
} from '@abcfinlab/ui';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgxsModule } from '@ngxs/store';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '../../../legacy/gwg/src/lib/shared/shared.module';
import { CalculationExtraPaymentsComponent } from '../Components/calculation-extra-payments/CalculationExtraPaymentsComponent';
import { CalculationFormComponent } from '../Components/calculation-form/CalculationFormComponent';
import { DeleteQuoteComponent } from '../Components/delete-quote/delete-quote.component';
import { IdentifySignerComponent } from '../Components/identify-signer/identify-signer.component';
import { InsuranceAndHandlingFeeComponent } from '../Components/insurance-and-handling-fee/InsuranceAndHandlingFeeComponent';
import { PageQuoteListComponent } from '../Components/page-quote-list/page-quote-list.component';
import { QuoteListComponent } from '../Components/quote-list/quote-list.component';
import { QuoteSummaryCardModule } from '../Components/quote-summary-card/quote-summary-card.component';
import { QuoteVersionMenuComponent } from '../Components/quote-version-menu/quote-version-menu.component';
import { QuoteVersionsCardsComponent } from '../Components/quote-versions-cards/quote-versions-cards.component';
import { QuoteVersionsTableComponent } from '../Components/quote-versions-table/quote-versions-table.component';
import { RefinancingInterestComponent } from '../Components/refinancing-interest/RefinancingInterestComponent';
import { RetailerQuoteVersionsCardsComponent } from '../Components/retailer-quote-versions-cards/retailer-quote-versions-cards.component';
import { RetailerQuoteVersionsTableComponent } from '../Components/retailer-quote-versions-table/retailer-quote-versions-table.component';
import { CalculateDirective } from '../Directives/calculate.directive';
import { QuoteNamePipe } from '../Pipes/quote-name.pipe';
import { CustomerCreationRequestService } from '../Services/CustomerCreationRequestService';
import { WorkflowStepsIndicatorsService } from '../Services/workflow-steps-indicators.service';
import { RetailerCalculationState } from '../State/RetailerCalculationState';
import { CalculationView } from '../Views/calculation/CalculationView';
import { RetailerBonicheckPendingComponent } from '../Views/Dialogs/retailer-bonicheck-pending/retailer-bonicheck-pending.component';
import { RetailerCreateContractView } from '../Views/Dialogs/retailer-create-contract/RetailerCreateContractView';
import { RetailerIdentificationView } from '../Views/Dialogs/retailer-identification/RetailerIdentificationView';
import { RetailerQuoteDocumentActionView } from '../Views/Dialogs/retailer-quote-document-action/RetailerQuoteDocumentAction';
import {
    RetailerRecalculationConfirmationView,
} from '../Views/Dialogs/retailer-recalculation-confirmation/RetailerRecalculationConfirmationView';
import { RetailerCalculationView } from '../Views/retailer-calculation/RetailerCalculationView';
import { RetailerLesseesOverviewComponent } from '../Views/retailer-lessees-overview/retailer-lessees-overview.component';
import { RetailerQuoteView } from '../Views/retailer-quote/RetailerQuoteView';
import { BoniCheckListItemComponent } from '../Views/retailer-quote-details/boni-check-list-item/boni-check-list-item.component';
import { RetailerQuoteDetailsView } from '../Views/retailer-quote-details/RetailerQuoteDetailsView';
import { RetailerQuotesListComponent } from '../Views/retailer-quotes-list/retailer-quotes-list.component';
import { RetailerUpdateLesseeOverviewComponent } from '../Views/retailer-update-lessee-overview/retailer-update-lessee-overview.component';
import { SlbDetailsFormComponent } from '../Views/slb-details-form/slb-details-form.component';

@NgModule({
    declarations: [
        QuoteNamePipe,
        DeleteQuoteComponent,
        QuoteVersionsTableComponent,
        QuoteVersionMenuComponent,
        QuoteVersionsCardsComponent,
        QuoteListComponent,
        PageQuoteListComponent,
        SlbDetailsFormComponent,
        RetailerQuoteView,
        RetailerCalculationView,
        RetailerQuoteDetailsView,
        RetailerIdentificationView,
        RetailerCreateContractView,
        RetailerLesseesOverviewComponent,
        RetailerQuotesListComponent,
        RetailerUpdateLesseeOverviewComponent,
        RetailerQuoteVersionsCardsComponent,
        RetailerQuoteVersionsTableComponent,
        RetailerQuoteStepChipComponent,
        BoniCheckListItemComponent,
        RetailerBonicheckPendingComponent,
        CalculationView,
        InsuranceAndHandlingFeeComponent,
        RefinancingInterestComponent,
        CalculationFormComponent,
        CalculationExtraPaymentsComponent,
        CalculateDirective,
        RetailerQuoteDocumentActionView,
        RetailerRecalculationConfirmationView,
        IdentifySignerComponent,
    ],
    exports: [
        QuoteNamePipe,
        DeleteQuoteComponent,
        QuoteVersionsTableComponent,
        QuoteVersionMenuComponent,
        QuoteVersionsCardsComponent,
        QuoteSummaryCardModule,
        RetailerQuoteView,
        RetailerCalculationView,
        RetailerQuoteDetailsView,
        RetailerIdentificationView,
        RetailerCreateContractView,
        RetailerLesseesOverviewComponent,
        RetailerQuotesListComponent,
        RetailerUpdateLesseeOverviewComponent,
        RetailerQuoteVersionsCardsComponent,
        RetailerQuoteVersionsTableComponent,
        RetailerQuoteStepChipComponent,
        SlbDetailsFormComponent,
        BoniCheckListItemComponent,
        RetailerBonicheckPendingComponent,
        RetailerQuoteDocumentActionView,
        RetailerRecalculationConfirmationView,
        IdentifySignerComponent,
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule,
        TranslationModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatExpansionModule,
        MatMenuModule,
        ClipboardModule,
        MatDividerModule,
        MatRippleModule,
        PageModule,
        SpacerModule,
        TextBlockModule,
        ReactiveFormsModule,
        MatStepperModule,
        BreakpointObserverModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatListModule,
        MatChipsModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MandatoryCheckboxWithErrorDirectiveModule,
        ScrollToErrorModule,
        DatePickerModule,
        ContactsModule,
        MinValidatorModule,
        MaxValidatorModule,
        MatButtonToggleModule,
        NgxCurrencyDirective,
        SelectOnDblClickDirectiveModule,
        PresentationModule,
        DialogHeaderModule,
        BannerModule,
        CameraModule,
        SharedModule,
        ScrollingModule,
        PdfViewerModule,
        SignaturePadModule,
        CheckboxGroupModule,
        NgxMatSelectSearchModule,
        NgxsModule.forFeature([RetailerCalculationState]),
        NgxMaskDirective,
        NgxMaskPipe,
        TileModule,
        MatSlideToggleModule,
        SummaryModule,
        AbsoluteDirectiveModule,
        MotionModule.forRoot({
            motions: [
                Motions.fadeSlideIn,
            ],
        }),
        WrapModule,
        WizardModule,
        IbanValidatorModule,
        CompareToModule,
        NgOptimizedImage,
    ],
    providers: [
        provideNgxMask(),
        QuoteNamePipe,
        CustomerCreationRequestService,
        WorkflowStepsIndicatorsService,
    ],
})
export class QuoteModule { }
