import {
    IContractTypeDto, IInhouseCalculationRequestDto, IRefinancingInterestResponseDto,
} from '@abcfinlab/api/global';
import { ControlsOf } from '@abcfinlab/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

/**
 * The RefinancingInterestComponent view.
 *
 * @public
 */
@Component({
    selector: 'l7-refinancing-interest',
    templateUrl: './RefinancingInterestComponent.html',
    styleUrls: ['./RefinancingInterestComponent.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class RefinancingInterestComponent {

    private _contractType: IContractTypeDto;
    private _userPanelOpenState: boolean = true;
    @Input() form: FormGroup<ControlsOf<IInhouseCalculationRequestDto>>;
    @Input() refinancingInterestResponse: IRefinancingInterestResponseDto;
    @Input() exploitationCode: number;

    @Input() set contractType(value: IContractTypeDto) {
        this._contractType = value;
        if (value && this._userPanelOpenState) {
            this.panelOpenState = true;
            return;
        }

        this.panelOpenState = false;
    }

    public get contractType(): IContractTypeDto {
        return this._contractType;
    }

    public panelOpenState: boolean;

    public togglePanelState(evt: boolean): void {
        this._userPanelOpenState = evt;
    }

}
