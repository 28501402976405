/* libs/quote/src/Components/retailer-quote-step-chip/retailer-quote-step-chip.component.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: block;
}
:host .DEFAULT {
  color: gray !important;
  background-color: lightgrey !important;
}
:host .DEFAULT mat-icon {
  color: gray !important;
}
:host .PENDING {
  color: #8028bf !important;
  background-color: rgba(128, 40, 191, 0.2) !important;
}
:host .PENDING mat-icon,
:host .PENDING l7-text-block {
  color: #8028bf !important;
}
:host .OPEN {
  color: #1b7e9a !important;
  background-color: #e4f0f3 !important;
}
:host .OPEN mat-icon,
:host .OPEN l7-text-block {
  color: #1b7e9a !important;
}
:host .COMPLETED {
  color: #02cc83 !important;
  background-color: rgba(30, 188, 131, 0.2) !important;
}
:host .COMPLETED mat-icon,
:host .COMPLETED l7-text-block {
  color: #02cc83 !important;
}
:host .WARNING {
  color: #ffa800 !important;
  background-color: #fff5e0 !important;
}
:host .WARNING mat-icon,
:host .WARNING l7-text-block {
  color: #ffa800 !important;
}
:host .ERROR,
:host .DECLINED {
  color: #ad0149 !important;
  background-color: rgba(173, 1, 73, 0.2) !important;
}
:host .ERROR mat-icon,
:host .ERROR l7-text-block,
:host .DECLINED mat-icon,
:host .DECLINED l7-text-block {
  color: #ad0149 !important;
}
mat-icon {
  margin-right: 8px;
}
/*# sourceMappingURL=retailer-quote-step-chip.component.css.map */
