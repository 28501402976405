// #region Imports

import { ISaveOfferWithoutCrefoViewDialogData, ISaveOfferWithoutCrefoViewDialogResult, SaveOfferWithoutCrefoView } from '@abcfinlab/contacts';
import { ControlsOf, Validators as CoreValidators, CountryCode, FormValidator } from '@abcfinlab/core';
import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

// #endregion

/**
 * The presenter of the {@link SaveOfferWithoutCrefoView} view.
 *
 * @internal
 */
@Injectable()
export class SaveOfferWithoutCrefoViewPresenter {

    // #region Fields

    private readonly _formValidator: FormValidator;
    private readonly _form: FormGroup<ControlsOf<ISaveOfferWithoutCrefoViewDialogResult>>;
    private readonly _dialogRef: MatDialogRef<SaveOfferWithoutCrefoView>;
    private readonly _dialogData: BehaviorSubject<ISaveOfferWithoutCrefoViewDialogData>;
    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the SaveOfferWithoutCrefoViewPresenter class.
     *
     * @public
     */
    public constructor(dialogRef: MatDialogRef<SaveOfferWithoutCrefoView>, formValidator: FormValidator,
        @Inject(MAT_DIALOG_DATA) dialogData: ISaveOfferWithoutCrefoViewDialogData) {
        this._formValidator = formValidator;
        this._dialogRef = dialogRef;
        this._dialogData = new BehaviorSubject<ISaveOfferWithoutCrefoViewDialogData>(dialogData);
        this._form = new FormGroup<ControlsOf<ISaveOfferWithoutCrefoViewDialogResult>>({
            companyName: new FormControl<string>({ value: '', disabled: false }, [Validators.required]),
            street: new FormControl<string>({ value: '', disabled: false }, [Validators.required]),
            houseNumber: new FormControl<string>({ value: '', disabled: false }, [Validators.required]),
            postalCode: new FormControl<string>({ value: '', disabled: false }, [Validators.required, CoreValidators.postalCode(CountryCode.DE)]),
            city: new FormControl<string>({ value: '', disabled: false }, [Validators.required]),
            country: new FormControl<string>({ value: 'Deutschland', disabled: true }, [Validators.required, Validators.minLength(2), Validators.maxLength(8)]),
        });
    }

    // #endregion

    // #region Properties

    public get form(): FormGroup<ControlsOf<ISaveOfferWithoutCrefoViewDialogResult>> {
        return this._form;
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        if (isNaN(Number(this._dialogData.value.city))) {
            this._form.controls.city.patchValue(this._dialogData.value.city);
        } else {
            this._form.controls.postalCode.patchValue(this._dialogData.value.city);
        }
        this._form.controls.companyName.patchValue(this._dialogData.value.companyName);
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    /**
     * Handles the submit event of the form.
     *
     * @param cancel - Indicates whether the submit was canceled.
     * @internal
     */
    public onSubmit(cancel?: boolean): void {
        if (cancel) {
            this._dialogRef.close();
            return;
        }

        this._formValidator.validate(this._form);

        if (this._form.valid) {
            this._dialogRef.close(this._form.getRawValue());
        }
    }

    // #endregion

}
