/* libs/quote/src/Views/calculation/CalculationView.scss */
:host l7-page {
  padding: 16px;
}
:host l7-page .margin-top {
  margin-top: 16px;
}
:host l7-page l7-page-header ::ng-deep l7-text-block.title {
  font-size: 18px;
}
:host l7-page l7-page-header ::ng-deep l7-text-block.title.sub {
  font-size: 24px;
}
:host l7-page l7-page-header ::ng-deep l7-text-block.title.supportive {
  margin-top: 8px;
}
/*# sourceMappingURL=CalculationView.css.map */
