/* libs/quote/src/Views/Dialogs/retailer-identification/RetailerIdentificationView.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host mat-stepper.stepper-without-header ::ng-deep .mat-horizontal-stepper-header-container {
  display: none !important;
}
:host mat-stepper.stepper-without-header ::ng-deep .mat-vertical-stepper-header,
:host mat-stepper.stepper-without-header ::ng-deep .mat-stepper-vertical-line::before {
  display: none !important;
}
:host mat-stepper.stepper-without-header ::ng-deep .mat-vertical-content-container .mat-vertical-content {
  padding: 0;
}
:host mat-stepper.stepper-without-header ::ng-deep .mat-horizontal-stepper-content {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
:host mat-stepper .mat-mdc-card-header .mat-mdc-card-title {
  display: flex;
}
:host mat-stepper .mat-mdc-card-header .mat-mdc-card-title .mat-icon {
  margin-left: 16px;
}
:host mat-stepper .more-button {
  font-size: 16px;
  color: #2b7e95;
}
:host mat-stepper .icon-card {
  max-width: 500px;
  cursor: pointer;
}
:host mat-stepper .disable-upload {
  position: absolute;
  opacity: 0.75;
  width: 97%;
  height: 63%;
  z-index: 9999;
  background: white;
}
:host mat-stepper .mat-mdc-radio-group {
  display: grid;
}
:host mat-stepper .mat-mdc-radio-button {
  margin-bottom: 10px;
}
:host mat-stepper .mat-mdc-form-field.warning ::ng-deep .mat-form-field-outline,
:host mat-stepper .mat-mdc-form-field.warning ::ng-deep .mat-mdc-form-field-error {
  color: #ff7d00;
}
:host mat-stepper .mat-mdc-form-field.warning.mat-form-field-invalid ::ng-deep mat-label {
  color: #ff7d00;
}
:host mat-stepper pdf-viewer {
  height: 300px;
}
:host mat-stepper h2 sup {
  top: 0.5em;
}
:host .mat-mdc-dialog-actions.center-buttons {
  justify-content: center !important;
}
/*# sourceMappingURL=RetailerIdentificationView.css.map */
