// #region Imports

import { LesseeService as ContactLesseeService, ILesseeOverviewDto } from '@abcfinlab/api/contact';
import { TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LesseeService } from '../../../../apps/l7/src/app/private/services/lessee/lessee.service';
import { ISelectLesseeSearchCondition } from '../Models/Interfaces/ISelectLesseeSearchCondition';

// #endregion

/**
 * @private
 */
const EMPTY_SEARCH_CONDITION: ISelectLesseeSearchCondition = {
    term: '',
};

/**
 * @private
 */
const isEmptySearchCondition = (other: ISelectLesseeSearchCondition) => EMPTY_SEARCH_CONDITION.term.toLowerCase() === other.term.toLowerCase();

/**
 * The presenter of the {@link SelectLesseeView} view.
 *
 * @internal
 */
@Injectable()
export class SelectLesseeViewPresenter {

    // #region Fields

    private readonly _contactLesseeService: ContactLesseeService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _router: Router;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _lesseeService: LesseeService;
    private readonly _lesseesSubject: BehaviorSubject<Array<ILesseeOverviewDto>>;
    private readonly _conditionSubject: BehaviorSubject<ISelectLesseeSearchCondition>;
    private readonly _isDefaultSearchConditionSubject: BehaviorSubject<boolean>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SelectLesseeViewPresenter` class.
     *
     * @public
     */
    public constructor(contactLesseeService: ContactLesseeService, busyBoxService: BusyBoxService, translationFacade: TranslationFacade,
        router: Router, lesseeService: LesseeService, activatedRoute: ActivatedRoute) {
        this._contactLesseeService = contactLesseeService;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._router = router;
        this._activatedRoute = activatedRoute;
        this._lesseeService = lesseeService;
        this._lesseesSubject = new BehaviorSubject([]);
        this._conditionSubject = new BehaviorSubject(EMPTY_SEARCH_CONDITION);
        this._isDefaultSearchConditionSubject = new BehaviorSubject(true);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `lessees` property.
     *
     * @public
     * @readonly
     */
    public get lessees(): Observable<Array<ILesseeOverviewDto>> {
        return this._lesseesSubject.asObservable();
    }

    /**
     * Returns the `condition` property.
     *
     * @public
     * @readonly
     */
    public get condition(): Observable<ISelectLesseeSearchCondition> {
        return this._conditionSubject.asObservable();
    }

    /**
     * Returns the `isDefaultSearchCondition` property.
     *
     * @public
     * @readonly
     */
    public get isDefaultSearchCondition(): Observable<boolean> {
        return this._isDefaultSearchConditionSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this.initializeCondition();
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    /**
     * @internal
     */
    public onTermChanged(value: string): void {
        const condition = {
            ...this._conditionSubject.value,
            term: value.trim().toLowerCase(),
        };

        this._conditionSubject.next(condition);
        this._isDefaultSearchConditionSubject.next(isEmptySearchCondition(this._conditionSubject.value));
        this.initializeLessees();
    }

    /**
     * @internal
     */
    public onLesseeSelected(lessee: ILesseeOverviewDto): void {
        this._lesseeService.setLesseeID(lessee.id);
        this._lesseeService.fetchLesseeByID(lessee.id);
        void this._router.navigate(['../list'], {
            queryParams: { id: lessee.id },
            relativeTo: this._activatedRoute,
        });
    }

    /**
     * @internal
     */
    public onBackToStart(): void {
        void this._router.navigate(['../../'], {
            relativeTo: this._activatedRoute,
        });
    }

    /**
     * @private
     */
    private initializeCondition(): void {
        this.initializeLessees();
    }

    /**
     * @private
     */
    private initializeLessees(): void {
        this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this._contactLesseeService.getAllOfCurrentUserLead()).subscribe((x) => {
            this._lesseesSubject.next(x);
        });
    }

    public formatAddress(lessee: ILesseeOverviewDto): string {
        let address = '';
        if (lessee.street) {
            address += lessee.street;
        }

        if (lessee.houseNumber) {
            address += ` ${lessee.houseNumber}`;
        }

        if (lessee.postcode) {
            address += `, ${lessee.postcode}`;
        }

        if (lessee.city) {
            address += ` ${lessee.city}`;
        }

        if (lessee.navContactNumber !== null) {
            const sep = address.trim().length > 0 ? ' | ' : '';
            address += `${sep}${this._translationFacade.instant('global.nav_contact')}: ${lessee.navContactNumber}`;
        }

        return address;
    }

    // #endregion

}
