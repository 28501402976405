// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WizardComponent } from '../../../../ui/src/Index';
import { RetailerCalculationViewPresenter } from '../retailer-calculation/RetailerCalculationViewPresenter';

// #endregion

/**
 * The `RetailerCalculationView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-retailer-calculation-view',
    templateUrl: './RetailerCalculationView.html',
    styleUrls: ['./RetailerCalculationView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RetailerCalculationViewPresenter,
    ],
    standalone: false,
})
export class RetailerCalculationView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: RetailerCalculationViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RetailersOverviewView` class.
     *
     * @public
     */
    public constructor(presenter: RetailerCalculationViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    @ViewChild('stepper')
    public stepperCreateQuote: WizardComponent;

    /**
     * Returns the presenter of the `RetailersOverviewView`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailerCalculationViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
