// #region Imports

import { ISearchRetailerQuoteLesseeResultDto, RetailerQuoteService } from '@abcfinlab/api/global';
import { Globals, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { RETAILER_QUOTES_LIST_ROUTE_PATH } from '../../Routing/RoutePaths';

// #endregion

/**
 * The presenter of the {@link SelectLesseeView} view.
 *
 * @internal
 */
@Injectable()
export class RetailerLesseesOverviewPresenter {

    // #region Fields

    private readonly _retailerQuoteService: RetailerQuoteService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _router: Router;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _lesseesSubject: BehaviorSubject<Array<ISearchRetailerQuoteLesseeResultDto>>;

    public searchString: string = '';

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SelectLesseeViewPresenter` class.
     *
     * @public
     */
    public constructor(retailerQuoteService: RetailerQuoteService, busyBoxService: BusyBoxService, translationFacade: TranslationFacade,
        router: Router, activatedRoute: ActivatedRoute) {
        this._retailerQuoteService = retailerQuoteService;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._router = router;
        this._activatedRoute = activatedRoute;
        this._lesseesSubject = new BehaviorSubject([]);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `lessees` property.
     *
     * @public
     * @readonly
     */
    public get lessees(): Observable<Array<ISearchRetailerQuoteLesseeResultDto>> {
        return this._lesseesSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this.initializeLessees(this.searchString);
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        //
    }

    /**
     * @internal
     */
    public onTermChanged(value: string): void {
        this.initializeLessees(value);
    }

    /**
     * @internal
     */
    public onLesseeSelected(lessee: ISearchRetailerQuoteLesseeResultDto): void {
        void this._router.navigate([`../../../${RETAILER_QUOTES_LIST_ROUTE_PATH}`, lessee.lesseeId], { relativeTo: this._activatedRoute, queryParams: { lesseName: lessee.lesseeCompanyName } });
    }

    /**
     * @internal
     */
    public formatAddress(contact: ISearchRetailerQuoteLesseeResultDto): string {
        let address = '';

        // it could be possible string with empty spaces
        if (contact.street?.trim()) {
            address += contact.street;
        }

        // it could be possible string with empty spaces
        if (contact.streetNo?.trim()) {
            address += ` ${contact.streetNo}`;
        }

        if (contact.postcode) {
            address += `, ${contact.postcode}`;
        }

        if (contact.city) {
            address += ` ${contact.city}`;
        }

        if (contact.navContactNumber) {
            const sep = address.trim().length > 0 ? ' | ' : '';
            address += `${sep}${this._translationFacade.instant('global.nav_contact')}: ${contact.navContactNumber}`;
        }

        return address;
    }

    /**
     * @private
     */
    private initializeLessees(searchTerm: string): void {
        this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this._retailerQuoteService.lesseeSearch({ searchString: searchTerm, page: 0, pageSize: Globals.Page.DEFAULT_PAGE_SIZE })).subscribe((x) => {
            this._lesseesSubject.next(x.content);
        });
    }

}
