<div class="boni-check-list-item">
    <l7-tile [readonly]="isReadOnly" [disabled]="isDisabled" (click)="onClick($event)" [attr.test-id]="'CREDIT_CHECK'">
        <div style="display: flex; align-items: center;">
            <l7-boni-check-icon
                [loading]="isPending"
                [status]="creditCheckRating" style="margin-right: 16px;">
            </l7-boni-check-icon>

            <div style="width: 100%;">
                <!-- *OPEN* state of credit ckeck -->
                <ng-container *ngIf="status === 'OPEN' && !isPending">
                    <l7-text-block
                        [text]="'quote.retailers.quoteDetails.tasks.creditCheck.OPEN' | translate">
                    </l7-text-block>
                </ng-container>

                <!-- *PENDING* state of credit check -->
                <ng-container *ngIf="(creditCheckRating === 'PENDING' || isPending) && status !== 'COMPLETED'">
                    <div style="display: flex; flex-wrap: wrap;">
                        <l7-text-block
                            style="margin-right: 6px;"
                            [wrap]="true"
                            [maxLines]="3" 
                            [text]="'quote.retailers.quoteDetails.tasks.creditCheck.IN_PROGRESS' | translate">
                        </l7-text-block>
                        <a (click)="onOpenInformationModal($event)">Jetzt erfahren, wie es weiter geht.</a>
                    </div>
                </ng-container>

                <!-- *COMPLETED* state of credit check -->
                <ng-container *ngIf="status === 'COMPLETED'">
                    <l7-text-block
                        [attr.test-id]="'creditCheck'"
                        [wrap]="true"
                        [maxLines]="3"
                        [text]="'quote.retailers.quoteDetails.tasks.creditCheck.' + creditCheckRating | translate: translationAttributes">
                    </l7-text-block>
                </ng-container>
            </div>

            <div style="margin-left: 16px;">
                <!-- chevron icon right -->
                <mat-icon *ngIf="!isDisabled && !isReadOnly && !isPending && !renderMenu"
                    [fontSet]="'l7'"
                    [color]="'primary'"
                    [fontIcon]="'Chevron_Right'">
                </mat-icon>

                <mat-icon *ngIf="renderMenu" [matMenuTriggerFor]="menu"
                    [fontSet]="'l7'"
                    [color]="'primary'"
                    [fontIcon]="'More_Horiz_Filled'">
                </mat-icon>
            </div>
            <mat-menu #menu="matMenu">
                <button *ngFor="let action of menuItems"
                    mat-menu-item (click)="onMenuItemClick(action)">
                    {{ 'quote.retailers.quoteDetails.tasks.actions.' + action | translate }}
                </button>
            </mat-menu>
        </div>
    </l7-tile>
</div>