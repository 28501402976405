<div fxFlex
     fxLayout="column">
    <l7-dialog-header [title]="'Wirtschaftlich Berechtigte erfassen' | translate"></l7-dialog-header>
    <mat-dialog-content fxFlex
                        fxLayout="column"
                        fxLayoutGap="32px">
        <form fxLayout="column"
              fxLayoutGap="16px"
              [formGroup]="this.presenter.form | async"
              (ngSubmit)="this.presenter.submit()">
            <div fxLayout="row"
                 fxLayout.lt-md="column"
                 fxLayoutGap="16px">
                <mat-form-field fxFlex
                                [appearance]="'fill'">
                    <mat-label>{{ 'Vorname' | translate }}</mat-label>
                    <input matInput
                           required
                           [attr.test-id]="'FirstNameInput'"
                           [formControlName]="'firstName'">
                    <mat-error *ngIf="(this.presenter.form  | async).controls['firstName'].hasError('required')">
                        Das Feld ist erforderlich.
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex
                                [appearance]="'fill'">
                    <mat-label>{{ 'Nachname' | translate }}</mat-label>
                    <input matInput
                           required
                           [attr.test-id]="'LastNameInput'"
                           [formControlName]="'lastName'">
                    <mat-error *ngIf="(this.presenter.form  | async).controls['lastName'].hasError('required')">
                        Das Feld ist erforderlich.
                    </mat-error>
                </mat-form-field>
            </div>
            <l7-date-picker fxFlex
                            [label]="'Geburtsdatum'"
                            [controlName]="'dateOfBirth'"
                            [uniqueId]="'DateOfBirthDateInput'"
                            [formFieldAppearance]="'fill'"
                            [parentFormGroup]="this.presenter.form | async">
            </l7-date-picker>
            <mat-radio-group fxLayout="column"
                             fxLayoutGap="8px"
                             [formControlName]="'fictitious'"
                             required>
                <mat-radio-button [checked]="true"
                                  [value]='false'>
                    <l7-text-block [wrap]="true"
                                   [text]="'Ich bestätige, den WB im Dialog mit dem Kunden erfragt zu haben'"></l7-text-block>
                </mat-radio-button>
                <mat-radio-button [value]='true'>
                    <l7-text-block [wrap]="true"
                                   [text]="'Als fiktiven WB speichern. Ich konnte keinen WB ermitteln'"></l7-text-block>
                </mat-radio-button>
            </mat-radio-group>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button
                color="accent"
                mat-dialog-close
                [attr.test-id]="'CancelButton'">{{ 'global.cancel' | translate }}</button>
        <button mat-flat-button
                color="accent"
                [attr.test-id]="'SaveButton'"
                (click)="this.presenter.submit()">{{ 'global.save' | translate }}</button>
    </mat-dialog-actions>
</div>