// #region Imports

import { SearchContactView, SearchType } from '@abcfinlab/contacts';
import { RetailerContractSuccessView } from '@abcfinlab/presentation';
import { PageQuoteListComponent } from '@abcfinlab/quote';
import { Route } from '@angular/router';
import { CalculationComponent } from '../../../../apps/l7/src/app/private/pages/calculation/calculation.component';
import { PageChooseLesseeComponent } from '../../../../apps/l7/src/app/private/pages/route-container-create-quote/page-choose-lessee/page-choose-lessee.component';
import { PageAddVendorComponent } from '../../../../apps/l7/src/app/private/pages/route-container-quotes/page-add-vendor/page-add-vendor.component';
import { QuoteDetailViewComponent } from '../../../../apps/l7/src/app/private/pages/route-container-quotes/page-quote-detail-view/quote-detail-view.component';
import { ObjectGroupsResolver } from '../../../../apps/l7/src/app/private/resolver/objectGroups/objectGroups.resolver';
import { ResetCalculationStateResolver } from '../../../../apps/l7/src/app/private/resolver/reset-calculation-state.resolver';
import { AuthGuard } from '../../../../apps/l7/src/app/shared/guards/auth.guard';
import {
    getCalculationSettingsResolver, getLesseeResolver, getLesseesForQuoteResolver,
    getObjectGroupsResolver,
    getQuotesForLesseeResolver, getRetailerQuoteResolver,
} from '../Resolvers/Resolvers';
import { RetailerConfigurationResolver } from '../Resolvers/RetailerConfigurationResolver';
import { RetailerQuoteDetailsResolver } from '../Resolvers/RetailerQuoteDetailsResolver';
import { RetailerQuotesListResolver } from '../Resolvers/RetailerQuotesListResolver';
import { CalculationView } from '../Views/calculation/CalculationView';
import { RetailerLesseesOverviewComponent } from '../Views/retailer-lessees-overview/retailer-lessees-overview.component';
import { RetailerQuoteView } from '../Views/retailer-quote/RetailerQuoteView';
import { RetailerQuotesListComponent } from '../Views/retailer-quotes-list/retailer-quotes-list.component';
import {
    RetailerUpdateLesseeOverviewComponent,
} from '../Views/retailer-update-lessee-overview/retailer-update-lessee-overview.component';
import {
    QUOTE_CALCULATION_PLAYGROUND_ROUTE_PATH,
    QUOTE_CALCULATION_ROUTE_PATH,
    QUOTE_DETAIL_ROUTE_PATH,
    QUOTE_LIST_ROUTE_PATH,
    QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE_PATH,
    QUOTE_RETAILER_CREATE_QUOTE_ROUTE_PATH,
    QUOTE_RETAILER_EDIT_QUOTE_ROUTE_PATH, QUOTE_RETAILER_LESSEES_ROUTE_PATH,
    QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH,
    QUOTE_SEARCH_LESSEE_ROUTE_PATH, RETAILER_QUOTE_SIGNED_SUCCESS_PATH, RETAILER_LESSEE_UPDATE_ROUTE_PATH,
    RETAILER_QUOTES_LIST_ROUTE_PATH,
} from './RoutePaths';

// #endregion

/**
 * @internal
 */
export const QUOTE_LIST_ROUTE: Route = {
    path: QUOTE_LIST_ROUTE_PATH,
    component: PageQuoteListComponent,
    resolve: {
        quotes: getQuotesForLesseeResolver,
        lessee: getLesseeResolver,
    },
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const QUOTE_DETAIL_ROUTE: Route = {
    path: QUOTE_DETAIL_ROUTE_PATH,
    component: QuoteDetailViewComponent,
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const QUOTE_CALCULATION_ROUTE: Route = {
    path: QUOTE_CALCULATION_ROUTE_PATH,
    component: CalculationComponent,
    canActivate: [AuthGuard],
    resolve: {
        objectGroups: ObjectGroupsResolver,
        calculationState: ResetCalculationStateResolver,
        calculationSettings: getCalculationSettingsResolver,
    },
};

/**
 * @internal
 */
export const QUOTE_CALCULATION_PLAYGROUND_ROUTE: Route = {
    path: QUOTE_CALCULATION_PLAYGROUND_ROUTE_PATH,
    component: CalculationView,
    canActivate: [AuthGuard],
    resolve: {
        objectGroups: getObjectGroupsResolver,
        calculationSettings: getCalculationSettingsResolver,
    },
};

/**
 * @internal
 */
export const QUOTE_SEARCH_LESSEE_ROUTE: Route = {
    path: QUOTE_SEARCH_LESSEE_ROUTE_PATH,
    component: SearchContactView,
    data: {
        searchType: SearchType.Lessee,
    },
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const QUOTE_RETAILER_CREATE_QUOTE_ROUTE: Route = {
    path: QUOTE_RETAILER_CREATE_QUOTE_ROUTE_PATH,
    component: RetailerQuoteView,
    resolve: {
        retailerSettings: RetailerConfigurationResolver,
        calculationSettings: getCalculationSettingsResolver,
    },
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const QUOTE_RETAILER_EDIT_QUOTE_ROUTE: Route = {
    path: QUOTE_RETAILER_EDIT_QUOTE_ROUTE_PATH,
    component: RetailerQuoteView,
    resolve: {
        retailerSettings: RetailerConfigurationResolver,
        calculationSettings: getCalculationSettingsResolver,
    },
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE: Route = {
    path: `${QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE_PATH}/:id`,
    component: RetailerQuoteView,
    resolve: {
        retailerSettings: RetailerConfigurationResolver,
        quoteDetails: RetailerQuoteDetailsResolver,
        calculationSettings: getCalculationSettingsResolver,
    },
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const QUOTE_RETAILER_QUOTE_DETAILS_ROUTE: Route = {
    path: `${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}/:id`,
    component: RetailerQuoteView,
    resolve: {
        quoteDetails: RetailerQuoteDetailsResolver,
        calculationSettings: getCalculationSettingsResolver,
    },
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const QUOTE_ADD_VENDOR_ROUTE: Route = {
    path: 'quotes/add-vendor',
    component: PageAddVendorComponent,
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const CREATE_QUOTE_CHOOSE_LESSEE_ROUTE: Route = {
    path: 'quote/create/choose-lessee',
    component: PageChooseLesseeComponent,
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const QUOTE_CREATE_CALCULATION_ROUTE: Route = {
    path: 'quote/create/calculation',
    component: CalculationComponent,
    canActivate: [AuthGuard],
    resolve: {
        objectGroups: ObjectGroupsResolver,
        calculationState: ResetCalculationStateResolver,
        calculationSettings: getCalculationSettingsResolver,
    },
};

/**
 * @internal
 */
export const QUOTE_CHANGE_CALCULATION_ROUTE: Route = {
    path: 'quotes/change',
    component: CalculationComponent,
    canActivate: [AuthGuard],
    resolve: {
        objectGroups: ObjectGroupsResolver,
        calculationSettings: getCalculationSettingsResolver,
    },
};

/**
 * @internal
 */
export const QUOTE_SEARCH_VENDOR_ROUTE: Route = {
    path: 'quotes/search-vendor',
    component: SearchContactView,
    data: {
        searchType: SearchType.Vendor,
    },
    canActivate: [AuthGuard],
};

/**
 * @internal
 */

export const RETAILER_QUOTES_LIST_ROUTE: Route = {
    path: `${RETAILER_QUOTES_LIST_ROUTE_PATH}/:id`,
    component: RetailerQuotesListComponent,
    resolve: {
        quotesList: RetailerQuotesListResolver,
    },
    canActivate: [AuthGuard],
};
/**
 * @internal
 */
export const QUOTE_RETAILER_LESSEES_ROUTE: Route = {
    path: QUOTE_RETAILER_LESSEES_ROUTE_PATH,
    component: RetailerLesseesOverviewComponent,
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const RETAILER_LESSEE_UPDATE_ROUTE: Route = {
    path: RETAILER_LESSEE_UPDATE_ROUTE_PATH,
    component: RetailerUpdateLesseeOverviewComponent,
    resolve: {
        lessees: getLesseesForQuoteResolver,
    },
    canActivate: [AuthGuard],
};

export const RETAILER_CONTRACT_SIGNED_SUCCESS_ROUTE: Route = {
    path: `${RETAILER_QUOTE_SIGNED_SUCCESS_PATH}/:id`,
    component: RetailerContractSuccessView,
    resolve: {
        lessee: getLesseeResolver,
        quote: getRetailerQuoteResolver,
    },
    canActivate: [AuthGuard],
};
