/* libs/quote/src/Views/retailer-quote/RetailerQuoteView.scss */
:host .mat-stepper-horizontal {
  background: none;
}
:host mat-stepper.no-pointer-events ::ng-deep .mat-horizontal-stepper-header-container {
  box-shadow:
    0px 2px 1px -1px rgba(48, 59, 69, 0.2),
    0px 1px 1px 0px rgba(48, 59, 69, 0.14),
    0px 1px 3px 0px rgba(48, 59, 69, 0.12);
  border-radius: 4px;
}
:host mat-stepper.no-pointer-events ::ng-deep .mat-horizontal-stepper-header,
:host mat-stepper.no-pointer-events ::ng-deep .mat-vertical-stepper-header {
  pointer-events: none !important;
}
:host mat-icon.disabled-number {
  background-color: #8F959A;
  color: white;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  flex: none;
  position: relative;
}
:host mat-icon.disabled-number div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
/*# sourceMappingURL=RetailerQuoteView.css.map */
