import {
    ICalculationErrorDto,
    IInhouseCalculationRequestDto, IInhouseCalculationResponseDto,
    IInsuranceTypeDto,
    ILeasingQuoteFieldToCalculateDto, IQuoteCalculationRequestDto,
    LeasingCalculationService,
} from '@abcfinlab/api/global';
import { ControlsOf, EventHub } from '@abcfinlab/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Directive, HostListener, OnInit } from '@angular/core';
import { FormGroup, NgControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { getPercent, getPercentageValue, toDecimal } from '../../../../apps/l7/src/app/helper/calculation.helper';
import { calculatePercentages, fieldsWithPercentageValues } from '../Helpers/ConvertPercentagesHelper';
import { KnownEvents } from '../Models/KnowEvents';

export type HttpRequestState = Readonly<
    'EMPTY' | 'FETCHING' | 'FETCHED' |
    { errorMessage: string }
>;
@UntilDestroy()
@Directive({
    selector: '[l7Calculate]',
    standalone: false,
})
export class CalculateDirective implements OnInit {

    private readonly _control: NgControl;
    private readonly _calculationService: LeasingCalculationService;
    private readonly _eventHub: EventHub;

    private readonly _fieldsWithPercentageValues = fieldsWithPercentageValues;

    private _form: FormGroup<ControlsOf<IInhouseCalculationRequestDto>>;
    private _formControlName: string;
    private _previousInsuranceType: IInsuranceTypeDto = IInsuranceTypeDto.No;

    public constructor(control: NgControl, calculationService: LeasingCalculationService, eventHub: EventHub) {
        this._control = control;
        this._calculationService = calculationService;
        this._eventHub = eventHub;
        this.triggerFetch$.pipe(
            untilDestroyed(this),
            tap(() => this.httpRequestState$.next('FETCHING')),
            switchMap(({ path, cancel }) => {
                if (cancel || !this._form) { // 👈
                    return of(null);
                }

                if (this._form.controls.insuranceAndHandlingFeeRequest.controls.insuranceType.getRawValue() === IInsuranceTypeDto.No) {
                    this._form.controls.insuranceAndHandlingFeeRequest.controls.insuranceValue.patchValue(null, { emitEvent: false });
                }

                let insuranceValue = this._form.controls.insuranceAndHandlingFeeRequest.controls.insuranceValue.getRawValue();

                if (this._formControlName === 'totalLeasingValue') {
                    insuranceValue = null;
                }

                const instalment = this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.Instalment ? null : this._form.controls.quoteCalculationRequest.controls.instalment.getRawValue();
                const yearlyInterestPercentage = this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.YearlyInterest ? null : this._form.controls.quoteCalculationRequest.controls.yearlyInterestPercentage.getRawValue();
                let firstInstalmentValue = this._form.controls.quoteCalculationRequest.controls.firstInstalmentValue.getRawValue();
                let firstInstalmentPercentage = this._form.controls.quoteCalculationRequest.controls.firstInstalmentPercentage.getRawValue();
                let lastInstalmentValue = this._form.controls.quoteCalculationRequest.controls.lastInstalmentValue.getRawValue();
                let lastInstalmentPercentage = this._form.controls.quoteCalculationRequest.controls.lastInstalmentPercentage.getRawValue();
                if (this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.FirstInstalment) {
                    firstInstalmentValue = null;
                    firstInstalmentPercentage = null;
                } else if (this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.LastInstalment) {
                    lastInstalmentValue = null;
                    lastInstalmentPercentage = null;
                }
                const refinancingInterestValue = !this._form.controls.refinancingInterestValue.value ? null : this._form.controls.refinancingInterestValue.value;

                const body = {
                    ...this._form.getRawValue(),
                    ...{
                        insuranceAndHandlingFeeRequest: {
                            ...this._form.controls.insuranceAndHandlingFeeRequest.getRawValue(),
                            ...{ insuranceValue },
                        },
                        quoteCalculationRequest: {
                            ...this._form.controls.quoteCalculationRequest.getRawValue(),
                            ...{ instalment, yearlyInterestPercentage, firstInstalmentValue, firstInstalmentPercentage, lastInstalmentValue, lastInstalmentPercentage },
                        },
                        ...{ refinancingInterestValue },
                    },
                };
                body.quoteCalculationRequest = calculatePercentages(body.quoteCalculationRequest);
                return this._calculationService.calculateQuote({ body })
                    .pipe(
                        tap((response) => {
                            this._eventHub.publish<{ activeControlName: string; response: IInhouseCalculationResponseDto }>(KnownEvents.CALCULATION, { activeControlName: this._formControlName, response });
                            this.httpRequestState$.next('FETCHED');
                            this.patchResponseValues(response);
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            this.httpRequestState$.next({ errorMessage: 'Request error' });
                            return EMPTY;
                        }),
                    );
            })).subscribe();
    }

    protected triggerFetch$: BehaviorSubject<{ path?: string; cancel?: boolean }> = new BehaviorSubject<{ path?: string; cancel?: boolean }>({ path: '' });
    protected httpRequestState$: BehaviorSubject<HttpRequestState> = new BehaviorSubject<HttpRequestState>('EMPTY');

    @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent): void {
        if (this.validateNumber(event)) {
            this.calculate();
        }
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
        setTimeout(() => this.calculate(), 500);
    }

    @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
        if (this._form.controls.quoteCalculationRequest.controls.totalLeasingValue.getRawValue() && (this._formControlName === 'objectCondition' || this._formControlName === 'valueToCalculate')) {
            setTimeout(() => this.calculate(), 500);
            return;
        }
        const extraPaymentToRemove = Object.values(this._fieldsWithPercentageValues).find(x => x.includes(this._formControlName));

        if (this._form.controls.quoteCalculationRequest.controls.totalLeasingValue.getRawValue() && extraPaymentToRemove?.length > 0 && !(event.target instanceof HTMLInputElement)) {
            this._form.controls.quoteCalculationRequest.controls[extraPaymentToRemove[0]].patchValue(0, { emitEvent: false });
            this._form.controls.quoteCalculationRequest.controls[extraPaymentToRemove[1]].patchValue(0, { emitEvent: false });
            this.calculate();
        }
    }

    @HostListener('selectionChange', ['$event']) onSelect(event: MatSelectChange): void {
        if (this._form.controls.quoteCalculationRequest.controls.totalLeasingValue.getRawValue() && (this._formControlName === 'totalTerms' || this._formControlName === 'insuranceType')) {
            this.calculate();
        }
    }

    @HostListener('ngModelChange', ['$event']) valueChange(event: unknown): void {
        if (this._formControlName === 'insuranceType' && event !== this._previousInsuranceType && this._form.controls.quoteCalculationRequest.controls.totalLeasingValue.valid) {
            this._previousInsuranceType = event as IInsuranceTypeDto;
            this.calculate();
        }
    }

    public ngOnInit(): void {
        if (this._control.control.parent.get('contractType')) {
            this._form = this._control.control.parent as FormGroup<ControlsOf<IInhouseCalculationRequestDto>>;
        } else if (this._control.control.parent.parent && this._control.control.parent.parent.get('contractType')) {
            this._form = this._control.control.parent.parent as FormGroup<ControlsOf<IInhouseCalculationRequestDto>>;
        }

        this._formControlName = this._control.name.toString();
    }

    private calculate(): void {
        if (this._formControlName === 'totalLeasingValue' && this._form.controls.quoteCalculationRequest.controls.totalLeasingValue.valid) {
            this.enableCalculationFields();
            this.patchValuesAndPercentagesRelatedToTotalLeasingValue();
        } else if (this._formControlName === 'totalLeasingValue' && this._form.controls.quoteCalculationRequest.controls.totalLeasingValue.invalid) {
            this._eventHub.publish<{ activeControlName: string; response: IInhouseCalculationResponseDto }>(KnownEvents.CALCULATION, { activeControlName: 'totalLeasingValue', response: null });
            this.disableCalculationFields();
        }
        this.patchValueOrPercentage();
        this._form.controls.quoteCalculationRequest.controls.residualValuePercentage.updateValueAndValidity();

        if (this._form && this._form.valid) {
            this.triggerFetch$.next({ path: this._formControlName });
        }
    }

    private cancelRequests(): void {
        this.triggerFetch$.next({ cancel: true });
    }

    private validateNumber(event: KeyboardEvent): boolean {
        const code = event.key ? event.key : null;

        if (/^[0-9]*$/.test(code)) {
            return true;
        } else if (['Backspace', 'Delete', ','].includes(code)) {
            return true;
        }

        return false;
    }

    private enableCalculationFields(): void {
        Object.keys(this._form.controls.quoteCalculationRequest.controls).forEach((key) => {
            if (key === 'totalLeasingValue') {
                return;
            }
            if (this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.Instalment && key === 'instalment') {
                return;
            } else if (this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.YearlyInterest && key === 'yearlyInterestPercentage') {
                return;
            } else if (this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.FirstInstalment && (key === 'firstInstalmentValue' || key === 'firstInstalmentPercentage')) {
                return;
            } else if (this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.LastInstalment && (key === 'lastInstalmentValue' || key === 'lastInstalmentPercentage')) {
                return;
            }
            this._form.controls.quoteCalculationRequest.controls[key].enable({ emitEvent: false });
        });
        this._form.controls.quoteCalculationRequest.controls.instalment.markAsTouched();
        this._form.controls.refinancingInterestValue.enable({ emitEvent: false });
    }

    private disableCalculationFields(): void {
        Object.keys(this._form.controls.quoteCalculationRequest.controls).forEach((key) => {
            if (key === 'totalLeasingValue' || key === 'residualValuePercentage') {
                return;
            }
            if (this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.Instalment && key === 'instalment') {
                return;
            } else if (this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.YearlyInterest && key === 'yearlyInterestPercentage') {
                return;
            }

            this._form.controls.quoteCalculationRequest.controls[key].disable({ emitEvent: false });
        });
        this._form.controls.refinancingInterestValue.patchValue(0, { emitEvent: false });
        this._form.controls.refinancingInterestValue.disable({ emitEvent: false });
    }

    private patchResponseValues(response: IInhouseCalculationResponseDto): void {
        Object.keys(response).forEach((key) => {
            switch (key) {
                case 'insuranceAndHandlingFee':
                    Object.keys(this._form.controls.insuranceAndHandlingFeeRequest.controls).forEach((k) => {
                        if (k === this._formControlName) {
                            return;
                        }
                        this._form.controls.insuranceAndHandlingFeeRequest.controls[k].patchValue(response.insuranceAndHandlingFee[k], { emitEvent: false });
                    });
                    break;
                case 'quoteCalculation':
                    Object.keys(this._form.controls.quoteCalculationRequest.controls).forEach((k) => {
                        if (k === 'totalLeasingValue' || k === this._formControlName) {
                            return;
                        }

                        const valuesWithPercentage = Object.values(this._fieldsWithPercentageValues).find(x => x.includes(this._formControlName));
                        if (valuesWithPercentage && (k === valuesWithPercentage[0] || k === valuesWithPercentage[1])) {
                            return;
                        }

                        this._form.controls.quoteCalculationRequest.controls[k].patchValue(response.quoteCalculation[k], { emitEvent: false });
                        if (k === 'instalment' && response.calculationErrors.length > 0) {
                            this.handleCalculationErrors(response.calculationErrors);
                        }
                    });
                    break;
                case 'refinancingInterestResponse':
                    if (this._formControlName === 'refinancingInterestValue') {
                        return;
                    }
                    this._form.controls.refinancingInterestValue.patchValue(response[key]?.refinancingInterestValue, { emitEvent: false });
                    break;
                case 'calculationErrors':
                    if (response.calculationErrors.length > 0) {
                        this.handleCalculationErrors(response.calculationErrors);
                    }
                    break;
            }
        });
    }

    private patchValueOrPercentage(): void {
        const valuesWithPercentage = Object.values(this._fieldsWithPercentageValues).find(x => x.includes(this._formControlName));
        if (!valuesWithPercentage) {
            return;
        }
        const totalLeasingValue = this._form.controls.quoteCalculationRequest.controls.totalLeasingValue.getRawValue();
        if (this._formControlName.includes('Percentage')) {
            const value = getPercentageValue(this._form.controls.quoteCalculationRequest.controls[this._formControlName].getRawValue(), totalLeasingValue);
            this._form.controls.quoteCalculationRequest.controls[valuesWithPercentage[0]].patchValue(value, { onlySelf: true, emitEvent: false });
            this._form.controls.quoteCalculationRequest.controls[valuesWithPercentage[1]].updateValueAndValidity();
        } else {
            const value = getPercent(totalLeasingValue, this._form.controls.quoteCalculationRequest.controls[this._formControlName].getRawValue(), 2, true);
            this._form.controls.quoteCalculationRequest.controls[valuesWithPercentage[1]].patchValue(value, { onlySelf: true, emitEvent: false });
        }
    }

    private patchValuesAndPercentagesRelatedToTotalLeasingValue(): void {
        const totalLeasingValue = this._form.controls.quoteCalculationRequest.controls.totalLeasingValue.getRawValue();
        Object.values(this._fieldsWithPercentageValues).forEach((value) => {
            if (this._form.controls.quoteCalculationRequest.controls[value[1]].getRawValue() === null) {
                this._form.controls.quoteCalculationRequest.controls[value[0]].patchValue(0, { emitEvent: false });
                this._form.controls.quoteCalculationRequest.controls[value[0]].markAsTouched({ emitEvent: false });
                this._form.controls.quoteCalculationRequest.controls[value[1]].patchValue(0, { emitEvent: false });
                this._form.controls.quoteCalculationRequest.controls[value[1]].markAsTouched({ emitEvent: false });
            } else {
                const fieldValue = getPercentageValue(this._form.controls.quoteCalculationRequest.controls[value[1]].getRawValue(), totalLeasingValue);
                this._form.controls.quoteCalculationRequest.controls[value[0]].patchValue(fieldValue, { onlySelf: true, emitEvent: false });
                this._form.controls.quoteCalculationRequest.controls[value[0]].markAsTouched({ emitEvent: false });
            }
            this._form.controls.quoteCalculationRequest.controls[value[0]].updateValueAndValidity();
            this._form.controls.quoteCalculationRequest.controls[value[1]].updateValueAndValidity();
        });
    }

    private handleCalculationErrors(errors: Array<ICalculationErrorDto>): void {
        errors.forEach((error) => {
            switch (error.key) {
                case 'calculation_yearly_interest_zero':
                    if (this._form.controls.valueToCalculate.getRawValue() === ILeasingQuoteFieldToCalculateDto.YearlyInterest) {
                        this._form.controls.quoteCalculationRequest.controls.instalment.setErrors({ valueNotPossible: true });
                        this._form.controls.quoteCalculationRequest.controls.instalment.markAsTouched();
                    }
                    break;
                case 'calculation_last_instalment_low':
                case 'calculation_first_instalment_low':
                    this._form.controls.quoteCalculationRequest.get(this._formControlName).setErrors({ valueNotPossible: true });
                    this._form.controls.quoteCalculationRequest.get(this._formControlName).markAsTouched();
                    break;
            }
        });
    }

}
