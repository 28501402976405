/* libs/contacts/src/Views/beneficial-owners-view/beneficial-owners-view.component.scss */
.mat-mdc-card-header .mat-mdc-card-title {
  display: flex;
}
.mat-mdc-card-header .mat-mdc-card-title .mat-icon {
  margin-left: 16px;
}
.more-button {
  font-size: 16px;
  color: #2b7e95;
}
/*# sourceMappingURL=beneficial-owners-view.component.css.map */
