/* libs/quote/src/Components/delete-quote/delete-quote.component.scss */
:host .mat-icon {
  font-size: 20px;
  display: inline-flex;
  margin-left: 8px;
}
:host .disabled {
  color: #CCCCCC;
}
:host .disabled:hover {
  transform: unset !important;
  cursor: not-allowed !important;
}
/*# sourceMappingURL=delete-quote.component.css.map */
