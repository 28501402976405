<l7-dialog-header [title]="'Identifizierung des Leasingnehmers'| translate"
                  [supportiveTitle]="supportiveTitle | translate"
                  [closeable]="true"
                  (closed)="onClose()">
</l7-dialog-header>
<mat-dialog-content fxLayout="column">
    <form [formGroup]="presenter.form"
          (ngSubmit)="presenter.submitIdentification($event)"
          l7ScrollToError
          #identificationForm="ngForm">
        <ng-container *cdkBreakpoint="let bp">
            <mat-stepper class="no-header"
                         #stepper
                         (selectionChange)="setSupportiveTitleText()"
                         [selectedIndex]="0"
                         [orientation]="bp('lt-md') ? 'vertical' : 'horizontal'">
                @if ((presenter.isIdentifySignerCompleted | async) === false) {
                    <mat-step [stepControl]="presenter.form" [label]="'IDENTIFY_SIGNER'">
                        <l7-identify-signer [signerForm]="presenter.form.controls.signer"
                                            (bankAccountIsShowed)="presenter.setBankAccountIsShowed($event)"
                                            [bankAccountForm]="presenter.form.controls.bankAccount">
                        </l7-identify-signer>
                        <l7-spacer [thickness]="'bottom'"
                                   [multiplier]="24">
                        </l7-spacer>

                    </mat-step>
                }
                @if ((presenter.onlyBeneficialOwnersAreMissing | async) === false) {
                    <mat-step  [label]="'CHOOSE_TYPE_OF_ID'">
                        <div fxLayout="column"
                             fxLayoutGap="16px">
                            <l7-tile fxFlex
                                     [inline]="true"
                                     [attr.test-id]="'btnChooseCamera'"
                                     (click)="chooseIDType('CAMERA')">
                                <div fxLayoutAlign="start start"
                                     fxLayout="row"
                                     fxLayoutGap="16px">
                                    <mat-icon [color]="'primary'"
                                              [fontSet]="'l7'"
                                              [fontIcon]="'Camera'"></mat-icon>
                                    <div fxFlex>
                                        <l7-text-block [wrap]="true"
                                                       [text]="'Dokument fotografieren' | translate"></l7-text-block>
                                        <l7-text-block [readonly]="true"
                                                       [wrap]="true"
                                                       [text]="'Fotografieren Sie jetzt den Ausweis des Leasingnehmers' | translate"></l7-text-block>

                                    </div>
                                    <div fxLayoutAlign="center center">
                                        <mat-icon [fontSet]="'l7'"
                                                  [color]="'primary'"
                                                  [fontIcon]="'Chevron_Right'"></mat-icon>
                                    </div>
                                </div>
                            </l7-tile>
                            <l7-tile fxFlex
                                     [inline]="true"
                                     [attr.test-id]="'btnChooseUpload'"
                                     (click)="chooseIDType('UPLOAD')">
                                <div fxLayoutAlign="start start"
                                     fxLayout="row"
                                     fxLayoutGap="16px">
                                    <mat-icon [color]="'primary'"
                                              [fontSet]="'l7'"
                                              [fontIcon]="'File_Upload'"></mat-icon>
                                    <div fxFlex>
                                        <l7-text-block [wrap]="true"
                                                       [text]="'Dokument hochladen' | translate"></l7-text-block>
                                        <l7-text-block [readonly]="true"
                                                       [wrap]="true"
                                                       [text]="'Laden Sie eine Kopie des Ausweises des Leasingnehmer hoch' | translate"></l7-text-block>
                                    </div>
                                    <div fxLayoutAlign="center center">
                                        <mat-icon [fontSet]="'l7'"
                                                  [color]="'primary'"
                                                  [fontIcon]="'Chevron_Right'"></mat-icon>
                                    </div>
                                </div>
                            </l7-tile>
                        </div>
                    </mat-step>
                    <!-- IDFRONT -->
                    <mat-step  [label]="'ID_FRONT'">
                        <ng-container *ngIf="(idType | async) === 'CAMERA' && (side | async) === 'FRONT'">
                            <ng-container *ngIf="!(showCapturedImage | async)">
                                <l7-web-camera-component fxFlex
                                                         fxLayout="row"
                                                         fxLayoutAlign="center"
                                                         [height]="350"
                                                         [width]="cameraWidth | async"
                                                         [triggerCapture]="(triggerCamera | async)"
                                                         (capturedImage)="showImage($event)">
                                </l7-web-camera-component>
                            </ng-container>
                            <l7-spacer [thickness]="'all'"
                                       [multiplier]="24"
                                       *ngIf="!(showCapturedImage | async)">
                                <div fxLayout="column"
                                     fxLayoutGap="16px"
                                     fxLayoutAlign="center center">
                                    <l7-text-block mat-line
                                                   fxLayout="row"
                                                   [alignment]="'center'"
                                                   [text]="'Der Ausweis sollte dafür die vorgegebene Form ausfüllen.'"
                                                   [wrap]="true">
                                    </l7-text-block>
                                </div>
                            </l7-spacer>
                            <div fxLayout="column"
                                 fxLayoutGap="16px"
                                 fxLayoutAlign="center center"
                                 *ngIf="(showCapturedImage | async)">
                                <div fxLayout="row"
                                     fxLayout.lt-sm="column"
                                     fxLayoutGap="16px">
                                    <img [src]="imageUrl | async"
                                         alt=""
                                         [style]="'width: ' + (cameraWidth | async) + 'px'" />
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="(idType | async) === 'UPLOAD' && (side | async) === 'FRONT'">
                            <mat-card appearance="outlined" class="mat-card-outlined">
                                <mat-card-header>
                                    <mat-card-title>
                                        {{ 'Vorderseite des Personalausweises hochladen' | translate }}
                                    </mat-card-title>
                                    <mat-card-subtitle>
                                        {{ 'Bitte laden Sie das Ausweisdokument als PDF, JP(E)G oder PNG hoch.' | translate }}
                                    </mat-card-subtitle>
                                </mat-card-header>
                                <mat-card-content>
                                    <l7-file-uploader [maxFiles]="1"
                                                      [orientation]="'vertical'"
                                                      [showMessage]="true"
                                                      [existingFiles]="frontFile | async"
                                                      [fileListClass]="'new-style'"
                                                      [useDefaultTypes]="true"
                                                      (filesChanged)="saveUploadedFile($event, 'FRONT')">
                                    </l7-file-uploader>
                                </mat-card-content>
                            </mat-card>
                        </ng-container>

                    </mat-step>
                    <!-- ID BACK -->
                    <mat-step [label]="'ID_BACK'">
                        <ng-container *ngIf="(idType | async) === 'CAMERA' && (side | async) === 'BACK'">
                            <l7-web-camera-component fxFlex
                                                     fxLayout="row"
                                                     fxLayoutAlign="center"
                                                     [height]="350"
                                                     [width]="cameraWidth | async"
                                                     [triggerCapture]="(triggerCamera | async)"
                                                     *ngIf="(showCapturedImage | async) === false"
                                                     (capturedImage)="showImage($event)">
                            </l7-web-camera-component>
                            <l7-spacer [thickness]="'all'"
                                       [multiplier]="24"
                                       *ngIf="!(showCapturedImage | async)">
                                <div fxLayout="column"
                                     fxLayoutGap="16px"
                                     fxLayoutAlign="center center">
                                    <l7-text-block mat-line
                                                   fxLayout="row"
                                                   [alignment]="'center'"
                                                   [text]="'Der Ausweis sollte dafür die vorgegebene Form ausfüllen.'"
                                                   [wrap]="true">
                                    </l7-text-block>
                                </div>
                            </l7-spacer>
                            <div fxLayout="column"
                                 fxLayoutGap="16px"
                                 fxLayoutAlign="center center"
                                 *ngIf="(showCapturedImage | async)">
                                <div fxLayout="row"
                                     fxLayout.lt-sm="column"
                                     fxLayoutGap="16px">
                                    <img [src]="imageUrl | async"
                                         alt=""
                                         [style]="'width: ' + (cameraWidth | async) + 'px'" />
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="(idType | async) === 'UPLOAD' && (side | async) === 'BACK'">
                            <mat-card appearance="outlined" class="mat-card-outlined">
                                <mat-card-header>
                                    <mat-card-title>
                                        {{ 'Rückseite des Personalausweises hochladen' | translate }}
                                    </mat-card-title>
                                    <mat-card-subtitle>
                                        {{ 'Bitte laden Sie das Ausweisdokument als PDF, JP(E)G oder PNG hoch.' | translate }}
                                    </mat-card-subtitle>
                                </mat-card-header>
                                <mat-card-content>
                                    <l7-file-uploader [maxFiles]="1"
                                                      [orientation]="'vertical'"
                                                      [showMessage]="true"
                                                      [existingFiles]="backFile | async"
                                                      [fileListClass]="'new-style'"
                                                      [useDefaultTypes]="true"
                                                      (filesChanged)="saveUploadedFile($event, 'BACK')">
                                    </l7-file-uploader>
                                </mat-card-content>
                            </mat-card>
                        </ng-container>
                    </mat-step>
                    <mat-step [label]="'CONFIRM_ID_SIGNER'">
                        <mat-card appearance="outlined" class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title [style.padding-bottom]="'8px'">
                                    {{ 'Daten des Unterzeichners' | translate }}
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row"
                                     fxLayout.lt-sm="column"
                                     fxLayoutGap="16px">
                                    <div [fxFlex]="'1 1 calc(' + 100 / 2 + '%' + ' - 16px)'"
                                         fxLayout="column"
                                         fxLayout.lt-sm="row">
                                        <img [src]="(presenter.frontImage | async)"
                                             alt=""
                                             [attr.test-id]="'showImageFront'"
                                             *ngIf="(presenter.isFrontImagePdf | async) === false" />
                                        <pdf-viewer [original-size]="false"
                                                    [render-text]="false"
                                                    [attr.test-id]="'showPdfFront'"
                                                    [src]="(presenter.frontImage | async)"
                                                    *ngIf="(presenter.isFrontImagePdf | async)">
                                        </pdf-viewer>
                                    </div>
                                    <div [fxFlex]="'1 1 calc(' + 100 / 2 + '%' + ' - 16px)'"
                                         fxLayout="column"
                                         fxLayout.lt-sm="row">
                                        <img [src]="presenter.backImage | async"
                                             alt=""
                                             [attr.test-id]="'showImageBack'"
                                             *ngIf="(presenter.isBackImagePdf | async) === false" />
                                        <pdf-viewer [original-size]="false"
                                                    [attr.test-id]="'showPdfBack'"
                                                    [render-text]="false"
                                                    [src]="presenter.backImage | async"
                                                    *ngIf="(presenter.isBackImagePdf | async)">
                                        </pdf-viewer>
                                    </div>
                                </div>

                                @if ((presenter.legalPersonality | async) === ILegalPersonalityDto.J) {
                                    <div fxLayout="row" [style.margin-top]="'16px'">
                                        <div fxLayout="column">
                                            <mat-checkbox name="IDENTIFICATION_JURISTIC_PERSON"
                                                          l7MandatoryCheckboxWithError
                                                          [triggerValidation]="validateCheckBoxIdentificationJuristicPerson"
                                                          required="true"
                                                          (change)="presenter.confirmationsChanged($event)"
                                                          [attr.test-id]="'signerJuristicPerson'">
                                                {{'Ich habe den Unterzeichner identifiziert. Das Original des Ausweisdokumentes, welches mir die anwesende natürliche Person vorgelegt hat, habe ich vollständig und lesbar fotografiert.*' | translate}}
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                } @else if ((presenter.legalPersonality | async) === ILegalPersonalityDto.N) {
                                    <div fxLayout="row">
                                        <div fxLayout="column">
                                            <mat-checkbox name="IDENTIFICATION_NATURAL_PERSON"
                                                          l7MandatoryCheckboxWithError
                                                          (change)="presenter.confirmationsChanged($event)"
                                                          [attr.test-id]="'signerJuristicPerson'">
                                                {{'Ich habe den Unterzeichner identifiziert.Das Original des Ausweisdokumentes, welches mir die anwesende natürliche Person vorgelegt hat, habe ich vollständig und lesbar fotografiert.*' | translate}}
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                }
                            </mat-card-content>
                        </mat-card>
                        <l7-spacer [thickness]="'bottom'"
                                   [multiplier]="24">
                        </l7-spacer>

                        <ng-container *ngIf="(presenter.legalPersonality | async) === 'N'">
                            <mat-card appearance="outlined" class="mat-card-outlined">
                                <mat-card-header>
                                    <mat-card-title>
                                        {{ 'Nachweis der Meldeadresse' | translate }}
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                    <div fxLayout="column"
                                         fxLayoutGap="16px">
                                        <div fxLayout="row">
                                            <mat-radio-group [attr.test-id]="'registrationAddress'"
                                                             name="registrationAddress"
                                                             (change)="presenter.confirmationsChanged($event)"
                                                             color="primary">
                                                <mat-radio-button color="primary"
                                                                  [checked]="true"
                                                                  value="REGISTRATION_ADDRESS_ON_ID">
                                                    {{'Die Meldeanschrift geht aus dem Nachweis hervor' | translate}}
                                                </mat-radio-button>
                                                <mat-radio-button color="primary"
                                                                  value="REGISTRATION_ADDRESS_NOT_ON_ID">
                                                    {{'Die Meldeanschrift geht NICHT aus dem Nachweis hervor' | translate }}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                            <l7-spacer [thickness]="'bottom'"
                                       [multiplier]="24">
                            </l7-spacer>
                            <mat-card appearance="outlined" class="mat-card-outlined"
                                      *ngIf="presenter.showUploadForAddressFile | async">
                                <mat-card-header>
                                    <mat-card-title>
                                        {{ 'Dokument zur Verifizierung der Meldeadresse hochladen' | translate }}
                                    </mat-card-title>
                                    <mat-card-subtitle>
                                        {{ 'Folgende Dokumente sind als Nachweiß der Meldeadresse zulässig: Stromrechnung, Gasrechnung, Internetrechnung' | translate }}
                                    </mat-card-subtitle>
                                </mat-card-header>
                                <mat-card-content>
                                    <l7-file-uploader [maxFiles]="1"
                                                      [orientation]="'vertical'"
                                                      [showMessage]="true"
                                                      [existingFiles]="addressFile | async"
                                                      [fileListClass]="'new-style'"
                                                      [useDefaultTypes]="true"
                                                      (filesChanged)="saveUploadedFile($event, 'ADDRESS')">
                                    </l7-file-uploader>
                                </mat-card-content>
                            </mat-card>
                        </ng-container>


                    </mat-step>
                }

                @if ((presenter.isShortCutFlow | async) === false && (presenter.legalPersonality | async) === ILegalPersonalityDto.J) {
                    <mat-step [label]="'ADD_BO'">
                        <l7-banner [color]="'info'"
                                   [appearance]="'outlined'"
                                   [attr.test-id]="'infoBeneficialOwner'"
                                   [iconFontSet]="'l7'"
                                   [icon]="'Info'"
                                   [content]="'quote.retailers.quoteDetails.tasks.identification.beneficialOwnerBannerInfo' | translate">
                        </l7-banner>
                        <l7-spacer [thickness]="'bottom'"
                                   [multiplier]="24">
                        </l7-spacer>
                        <l7-beneficial-owners-view #beneficialOwnersView
                                                   [boList]="presenter.beneficialOwners"
                                                   [lessee]="presenter.lessee | async"
                                                   [lesseeCrefoId]="presenter.lesseeCrefoId">
                        </l7-beneficial-owners-view>
                    </mat-step>
                }

                <mat-step [label]="'CONFIRM_BO'">
                    <!-- here checkboxes for transparent register -> only for ILegalPersonalityDto.J-->
                    <mat-card appearance="outlined" class="mat-card-outlined">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Bestätigung entsprechend dem Geldwäschegesetz' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div fxLayout="column"
                                 fxLayoutGap="16px">
                                <div fxLayout="row">
                                    <div fxLayout="column">
                                        <mat-checkbox [attr.test-id]="'registerInformationConfirmation'"
                                                      l7MandatoryCheckboxWithError
                                                      required="true"
                                                      name="TRANSPARENCY_REGISTER"
                                                      (change)="presenter.confirmationsChanged($event)">
                                            {{'Der Kunde ist darüber informiert, dass er als Unternehmen (juristische Personen des Privatrechts und eingetragene Personengesellschaften) gemäß Geldwäschegesetz verpflichtet ist, Angaben zu seinen wirtschaftlich Berechtigten zum Transparenzregister zu melden.' | translate}}<sup>*</sup>
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <h2 fxLayout="row">{{'Meldung zum Transparenzregister' | translate}}<sup>*</sup></h2>
                                <div fxLayout="row">
                                    <mat-radio-group [attr.test-id]="'transparencyRegistryNotification'"
                                                     name="transparencyRegistryNotification"
                                                     (change)="presenter.confirmationsChanged($event)"
                                                     color="primary">
                                        <mat-radio-button color="primary"
                                                          [checked]="true"
                                                          value="TRANSPARENCY_REGISTER_NOTIFICATION_MISSING">
                                            {{'Der Kunde erklärte, dass er für sein Unternehmen noch keine Meldung zum Transparenzregister vorgenommen hat.' | translate}}
                                        </mat-radio-button>
                                        <mat-radio-button color="primary"
                                                          value="TRANSPARENCY_REGISTER_NOTIFICATION_ENTRY">
                                            {{'Der Kunde erklärte, dass er für sein Unternehmen eine Meldung zum Transparenzregister vorgenommen habe und eine Eintragung somit vorliege.' | translate }}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </mat-step>

            </mat-stepper>
        </ng-container>
    </form>
</mat-dialog-content>
@if (stepperIdentification?.selected.label !== 'CHOOSE_TYPE_OF_ID') {
    <mat-dialog-actions [ngClass]="{'center-buttons': (idType | async) === 'CAMERA' && (stepperIdentification?.selected.label === 'ID_FRONT' || stepperIdentification?.selected.label === 'ID_BACK')}">
        @if (stepperIdentification?.selected.label === 'ADD_BO') {
            <div fxLayout="row" [fxFlex]="'1 1 calc(80% - 16px)'">
                @if ((presenter.onlyBeneficialOwnersAreMissing | async) === false) {
                    <button mat-stroked-button
                            color="primary"
                            [attr.test-id]="'btnSkip'"
                            (click)="identificationForm.ngSubmit.emit('WITHOUT_CONFIRM_BO')">
                        {{'quote.retailers.quoteDetails.tasks.identification.btnSkip' | translate}}
                    </button>
                }
                <button mat-stroked-button
                        color="primary"
                        [attr.test-id]="'btnSignerAsBO'"
                        (click)="takeSignatoryAsBeneficialOwner()">{{'quote.retailers.quoteDetails.tasks.identification.btnSignerAsBO' | translate}}</button>
            </div>

        }

        @if (showBtnBack) {
            <button mat-stroked-button
                    [fxFlex]="'0 1 calc(10% - 16px)'"
                    color="primary"
                    [attr.test-id]="'btnGoBack2'"
                    (click)="backToIdDecision()">{{'contract_management.back' | translate}}</button>
        }

        @if (showBtnNext) {
            <button mat-flat-button
                    color="primary"
                    [fxFlex]="'0 1 calc(10% - 16px)'"
                    [attr.test-id]="'btnNext'"
                    (click)="next()">{{'global.further' | translate}}</button>
        }

        <button mat-flat-button
                color="primary"
                [attr.test-id]="'btnCompleteIdentification'"
                *ngIf="stepperIdentification?.selectedIndex === 0 && (presenter.isShortCutFlow | async) === true"
                (click)="presenter.submitIdentificationShortCutFlow()">
            {{'Identifizierung abschließen' | translate}}
        </button>

        @if (stepperIdentification?.selected.label === 'CONFIRM_BO' || (stepperIdentification?.selected.label === 'CONFIRM_ID_SIGNER') && (presenter.legalPersonality | async) === ILegalPersonalityDto.N) {
            <button mat-flat-button
                    color="primary"
                    [attr.test-id]="'btnCompleteIdentification'"
                    (click)="identificationForm.ngSubmit.emit('IDENTIFICATION_COMPLETE')">{{'Identifizierung abschließen' | translate}}
            </button>
        }

        @if ((stepperIdentification?.selected.label === 'ID_FRONT' || stepperIdentification?.selected.label === 'ID_BACK') && (idType | async) === 'UPLOAD') {
            <button mat-stroked-button
                    color="primary"
                    [attr.test-id]="'btnGoBack2'"
                    (click)="backToIdDecision()">{{'contract_management.back' | translate}}</button>
            <button mat-flat-button
                    color="primary"
                    [attr.test-id]="'btnNext2'"
                    (click)="nextFileId()">{{'global.further' | translate}}</button>
        }

        @if ((stepperIdentification?.selected.label === 'ID_FRONT' || stepperIdentification?.selected.label === 'ID_BACK') && (idType | async) === 'CAMERA') {
            @if (!(showCapturedImage | async)) {
                <button mat-stroked-button
                        [attr.test-id]="'btnGoBack2'"
                        color="primary"
                        (click)="backToIdDecision()">{{'contract_management.back' | translate}}</button>
                <button mat-flat-button
                        [color]="'primary'"
                        [attr.test-id]="'cameraButton'"
                        (click)="triggerCapture()">
                    <ng-container *ngIf="(side | async) === 'FRONT'; else backSideText">
                        {{'Vorderseite aufnehmen' | translate}}
                    </ng-container>
                    <ng-template #backSideText>
                        {{'Rückseite aufnehmen' | translate}}
                    </ng-template>
                </button>
            } @else {
                <div fxLayout="row"
                     fxLayout.lt-sm="column"
                     fxLayoutAlign="center center"
                     fxLayoutGap="16px">
                    <button mat-stroked-button
                            color="primary"
                            [attr.test-id]="'btnRetryCapture'"
                            (click)="retryCapture()">{{'Erneut aufnehmen' | translate}}</button>
                    <button mat-flat-button
                            color="primary"
                            [attr.test-id]="'btnConfirm'"
                            (click)="nextFileId()">{{'Bestätigen' | translate}}</button>
                </div>
            }
        }
    </mat-dialog-actions>
}
