// #region Imports

import {
    DocumentsService, ISearchRetailerQuoteResultDto,
    RetailerQuoteService,
} from '@abcfinlab/api/global';
import { once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { groupArrayByKey } from '../../../../../apps/l7/src/app/helper/presentation.helper';
import {
    IRetailerQuoteVersionListMenuEvent,
} from '../../Components/retailer-quote-versions-cards/retailer-quote-versions-cards.component';
import {
    QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE_PATH,
    QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH,
} from '../../Routing/RoutePaths';

export interface IRetailerQuoteGroup {
    groupName: string;
    groupId: string;
    versions: Array<ISearchRetailerQuoteResultDto>;
}

// #endregion

/**
 * @private
 */

/**
 * @private
 */

/**
 * The presenter of the {@link SelectLesseeView} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class RetailerQuotesListPresenter {

    // #region Fields

    private readonly _retailerQuoteService: RetailerQuoteService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _documentsService: DocumentsService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _toastService: ToastService;
    private readonly _router: Router;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _quoteGroups: BehaviorSubject<Array<IRetailerQuoteGroup>>;
    private readonly _cdr: ChangeDetectorRef;
    public searchString: string = '';
    public lesseName: string = '';

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SelectLesseeViewPresenter` class.
     *
     * @public
     */
    public constructor(retailerQuoteService: RetailerQuoteService, busyBoxService: BusyBoxService, translationFacade: TranslationFacade,
        router: Router, activatedRoute: ActivatedRoute, documentsService: DocumentsService, toastService: ToastService, cdr: ChangeDetectorRef) {
        this._retailerQuoteService = retailerQuoteService;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._toastService = toastService;
        this._router = router;
        this._activatedRoute = activatedRoute;
        this._documentsService = documentsService;
        this._quoteGroups = new BehaviorSubject([]);
        this._cdr = cdr;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `lessees` property.
     *
     * @public
     * @readonly
     */
    public get quoteGroups(): Observable<Array<IRetailerQuoteGroup>> {
        return this._quoteGroups.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        once(combineLatest([this._activatedRoute.data, this._activatedRoute.queryParams]), ([data, params]) => {
            this.buildQuotesList(data.quotesList.content);
            this.lesseName = params.lesseName;
        });
        this._cdr.detectChanges();
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    public triggerMenuAction(menuEvent: IRetailerQuoteVersionListMenuEvent): void {
        this._busyBoxService.show('', null, this._retailerQuoteService.get({ leasingQuoteId: menuEvent.quoteId }))
            .pipe(untilDestroyed(this))
            .subscribe((x) => {
                if (menuEvent.action === 'downloadQuote') {
                    const targetDocumentId = x.documents.filter(x => x.type === 'QUOTE')[0]?.id;
                    if (targetDocumentId) {
                        this.downLoadDocument(targetDocumentId);
                    }
                }
                if (menuEvent.action === 'contractOffer') {
                    const targetDocumentId = x.documents.filter(x => x.type === 'CONTRACT_DRAFT')[0]?.id;
                    if (targetDocumentId) {
                        this.downLoadDocument(targetDocumentId);
                    }
                }
                if (menuEvent.action === 'createNewVersion') {
                    void this._router.navigateByUrl(`presentation/${QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE_PATH}/${menuEvent.quoteId}`);
                }
            });
    }

    public showLesseeQuoteDetails(selectedVersion: ISearchRetailerQuoteResultDto): void {
        void this._router.navigate([`../../../../../${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}`, selectedVersion.id], { relativeTo: this._activatedRoute });
    }

    private buildQuotesList(rawList: Array<ISearchRetailerQuoteResultDto>): void {
        const quotesList: Array<IRetailerQuoteGroup> = [];
        const groupedList = groupArrayByKey(rawList, 'quoteNumber');

        Object.keys(groupedList).forEach((quoteGroupId) => {
            quotesList.push({ groupName: groupedList[quoteGroupId]?.[0].leasingQuoteGroupName, groupId: quoteGroupId, versions: groupedList[quoteGroupId] });
        });
        quotesList.sort((a, b) => {
            const lastCreatedA = a.versions.reduce((previousValue, currentValue, currentIndex, array) => {
                return new Date(currentValue.creationDate) >= new Date(previousValue.creationDate) ? currentValue : previousValue;
            });
            const lastCreatedB = b.versions.reduce((previousValue, currentValue, currentIndex, array) => {
                return new Date(currentValue.creationDate) >= new Date(previousValue.creationDate) ? currentValue : previousValue;
            });
            return new Date(lastCreatedA.creationDate).getTime() - new Date(lastCreatedB.creationDate).getTime();
        });
        this._quoteGroups.next(quotesList.reverse());
    }

    private downLoadDocument(targetDocumentId: string): void {
        this._documentsService.downloadDocument({ documentId: targetDocumentId }).pipe(untilDestroyed(this)).subscribe((draftPdf) => {
            const draftPdfUrl = URL.createObjectURL(draftPdf);
            window.open(draftPdfUrl, '_blank');
        }, (error) => {
            this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
        });
    }

    public get isDraft(): boolean {
        const searchString = 'CREFO_CONFIRMATION';
        return this._quoteGroups.getValue().some(group =>
            group.versions.some(version =>
                version.firstOpenType === searchString));
    }

}
