// #region Imports

import { BeneficialOwnerService, IBeneficialOwnerDto, IContactDto } from '@abcfinlab/api/contact';
import { convertToISODate, FormValidator, subscribeOnce, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService } from '@abcfinlab/ui';
import { Inject, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { BeneficialOwnerEditDialogView } from './BeneficialOwnerEditDialogView';

// #endregion

interface BeneficialOwnerEditDialogViewData {
    contact: IContactDto;
}

/**
 * The presenter of the @{link BeneficialOwnerEditDialogView} view.
 *
 * @internal
 */
@Injectable()
export class BeneficialOwnerEditDialogViewPresenter {

    // #region Fields

    private readonly _formBuilder: UntypedFormBuilder;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _formValidator: FormValidator;
    private readonly _beneficialOwnerService: BeneficialOwnerService;
    private readonly _dialogRef: MatDialogRef<BeneficialOwnerEditDialogView>;
    private readonly _formSubject: BehaviorSubject<UntypedFormGroup | null>;
    private readonly _data: BeneficialOwnerEditDialogViewData;

    // #endregion

    // #region Ctor

    /**
    * Constructs a new instance of the `BeneficialOwnerEditDialogViewPresenter` class.
    *
    * @public
    */
    public constructor(
        @Inject(MAT_DIALOG_DATA) data: BeneficialOwnerEditDialogViewData,
        dialogRef: MatDialogRef<BeneficialOwnerEditDialogView>,
        formBuilder: UntypedFormBuilder, formValidator: FormValidator,
        busyBoxService: BusyBoxService,
        translationFacade: TranslationFacade,
        beneficialOwnerService: BeneficialOwnerService,
    ) {
        this._formBuilder = formBuilder;
        this._dialogRef = dialogRef;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._formValidator = formValidator;
        this._beneficialOwnerService = beneficialOwnerService;
        this._data = data;
        this._formSubject = new BehaviorSubject<UntypedFormGroup | null>(null);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): Observable<UntypedFormGroup> {
        return this._formSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
    * Called before the view first displays the data-bound properties and sets the view's input properties.
    *
    * @internal
    */
    public initialize(): void {
        const form = this._formBuilder.group({
            firstName: ['', [
                Validators.required,
            ]],
            lastName: ['', [
                Validators.required,
            ]],
            dateOfBirth: [''],
            fictitious: [false, [
                Validators.required,
            ]],
        });

        this._formSubject.next(form);
    }

    /**
    * Called before the view will be destroyed.
    * Unsubscribe Observables and detach event handlers to avoid memory leaks.
    *
    * @internal
    */
    public dispose(): void {
    }

    /**
     * @internal
     */
    public submit(): void {
        this._formValidator.validate(this._formSubject.value);
        if (this._formSubject.value.valid) {
            const submitData: IBeneficialOwnerDto = {
                ...this._formSubject.value.value,
                ...{ dateOfBirth: this._formSubject.value.value?.dateOfBirth ? convertToISODate(this._formSubject.value.value?.dateOfBirth) : null, lesseeCrefoId: this._data.contact.crefo_id },
            };
            subscribeOnce(this._busyBoxService.show(
                '',
                this._translationFacade.translate('global.busy'),
                this._beneficialOwnerService.save({
                    body: submitData,
                }),
            ), (x: IBeneficialOwnerDto) => {
                this._dialogRef.close(x);
            });
        }
    }

    // #endregion

}
