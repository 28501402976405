/* libs/quote/src/Components/retailer-quote-versions-cards/retailer-quote-versions-cards.component.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host .item {
  margin: 8px;
  max-width: calc(33.3% - 16px);
}
:host .item.lt-lg {
  max-width: calc(50% - 16px);
}
:host .item.lt-md {
  max-width: 100%;
}
.quote-state {
  height: 14px;
  font-size: 14px;
}
.quote-state-PENDING {
  color: #8028bf !important;
  background-color: rgba(128, 40, 191, 0.2) !important;
}
.quote-state-PENDING mat-icon,
.quote-state-PENDING l7-text-block {
  color: #8028bf !important;
}
.quote-state-OPEN {
  color: #1b7e9a !important;
  background-color: #e4f0f3 !important;
}
.quote-state-OPEN mat-icon,
.quote-state-OPEN l7-text-block {
  color: #1b7e9a !important;
}
.quote-state-COMPLETED {
  color: #02cc83 !important;
  background-color: rgba(30, 188, 131, 0.2) !important;
}
.quote-state-COMPLETED mat-icon,
.quote-state-COMPLETED l7-text-block {
  color: #02cc83 !important;
}
.quote-state-ERROR,
.quote-state-DECLINED {
  color: #ad0149 !important;
  background-color: rgba(173, 1, 73, 0.2) !important;
}
.quote-state-ERROR mat-icon,
.quote-state-ERROR l7-text-block,
.quote-state-DECLINED mat-icon,
.quote-state-DECLINED l7-text-block {
  color: #ad0149 !important;
}
.menuTrigger {
  cursor: pointer;
}
/*# sourceMappingURL=retailer-quote-versions-cards.component.css.map */
