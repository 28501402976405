/* libs/quote/src/Views/retailer-quote-details/boni-check-list-item/boni-check-list-item.component.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: block;
}
:host .boni-check-list-item {
  min-height: 56px;
}
:host a {
  font-weight: 900;
  color: var(--l7-info-500-color);
  text-decoration: none !important;
  line-height: 1.15;
}
/*# sourceMappingURL=boni-check-list-item.component.css.map */
