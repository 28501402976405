// #region Imports

import { BusyBoxComponent, ToastService } from '@abcfinlab/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslationFacade } from '../../../../../../../../libs/core/src/Index';
import { SetVendor } from '../../../../actions/Quote.actions';
import { ContactDTO } from '../../../../models/ContactDTO.interface';
import { LeasingQuoteDTO } from '../../../../models/LeasingQuoteDTO.interface';
import { VendorDTO } from '../../../../models/VendorDTO.interface';
import { GenericDialogComponent, GenericDialogData } from '../../../../shared/modals/generic-dialog/generic-dialog.component';
import { ContactState } from '../../../../state/Contact.state';
import { LesseeState } from '../../../../state/Leesee.state';
import { QuoteState } from '../../../../state/Quote.state';
import { LesseeRepositoryService } from '../../../repository/lessee-repository.service';
import { DialogService } from '../../../services/dialog/dialog.service';

// #endregion

@UntilDestroy()
@Component({
    selector: 'l7-page-add-vendor',
    templateUrl: './page-add-vendor.component.html',
    styleUrls: ['./page-add-vendor.component.scss'],
    standalone: false,
})
export class PageAddVendorComponent implements OnInit {

    // #region Fields

    private readonly _router: Router;
    private readonly _dialogService: DialogService;
    private readonly _store: Store;
    private readonly _lesseeRepositoryService: LesseeRepositoryService;

    private readonly config = {
        closeOnNavigation: true,
        disableClose: true,
        width: '400px',
        minHeight: '200px',
    };

    private readonly data = {
        title: '',
        message: 'Bitte haben Sie einen Moment Geduld.',
        options: null,
    };

    private _dialogRefForLoader: MatDialogRef<any>;

    private _selectedLessee: ContactDTO;

    // #endregion

    // #region Ctor
    public constructor(router: Router,
        dialogService: DialogService,
        private readonly translationFacade: TranslationFacade,
        store: Store,
        lesseeRepositoryService: LesseeRepositoryService,
        private readonly toastService: ToastService,
        private readonly activatedRoute: ActivatedRoute) {
        this._router = router;
        this._dialogService = dialogService;
        this._store = store;
        this._lesseeRepositoryService = lesseeRepositoryService;
        this.$searchResult = this._store.select(ContactState.getContactListFormSearchResult) as Observable<Array<ContactDTO>>;
        this.selectedQuote$ = this._store.select(QuoteState.getQuote);
        this.selectedLessee$ = this._store.select(LesseeState.getLessee);
        this.quoteVendor$ = this._store.select(QuoteState.getVendor);
    }

    // #endregion

    // #region Properties

    public $searchResult: Observable<Array<ContactDTO>>;
    public selectedQuote$: Observable<LeasingQuoteDTO>;
    public selectedLessee$: Observable<ContactDTO>;
    public quoteVendor$: Observable<VendorDTO>;

    public headline: string = 'Lieferantensuche';
    public additional: string = 'Angaben zum Lieferanten';

    public selectedQuote: LeasingQuoteDTO;
    public loading: boolean;
    public icons = {
        chevronRight: 'Chevron_Right',
    };

    // #endregion

    // #region Methods

    public ngOnInit(): void {
        this.selectedQuote$
            .pipe(untilDestroyed(this))
            .subscribe((quote) => {
                this.selectedQuote = quote;
            });

        this.selectedLessee$.pipe(untilDestroyed(this))
            .subscribe(l => this._selectedLessee = l);

        this.quoteVendor$
            .pipe(untilDestroyed(this))
            .subscribe((vendor) => {
                if (vendor && 'quote_id' in vendor) {
                    this._dialogRefForLoader.close();
                    this.toastService.show('Der gewählte Lieferant wurde gespeichert', 'success');

                    void this._router.navigate(['../list'], {
                        relativeTo: this.activatedRoute,
                        queryParams: { id: this._selectedLessee.id },
                    });
                }
            }, (error) => {
                alert(error);
            });
    }

    public onSelectionChange(item: ContactDTO): void {
        this._dialogRefForLoader = this._dialogService.openDialog(BusyBoxComponent, this.config, this.data);
        const setVendor = (newItem: ContactDTO) => {
            const payload: VendorDTO = { quote_id: this.selectedQuote.quote_id, ...newItem };
            this._store.dispatch(new SetVendor(payload));
        };

        this._lesseeRepositoryService.getCheckContactState(item.crefo_id, 'vendor').pipe(
            switchMap(x => x.contactNo.length ? of(true) : of(false)),
            untilDestroyed(this),
        ).subscribe(() => {
            this._lesseeRepositoryService.checkIfNewContactNeeded(item.crefo_id)
                .pipe(untilDestroyed(this))
                .subscribe((res: ContactDTO) => {
                    if (res.new_contact_needed) {
                        const contactDto = res;
                        const name = contactDto.name;
                        const address = `${contactDto.street} ${contactDto.house_number}, ${contactDto.postcode} ${contactDto.city}`;
                        const data: GenericDialogData = {
                            id: 'dialog_contact_alert_changed_address',
                            image: 'assets/images/icon-create-contract.svg',
                            title: 'Abweichende Adresse',
                            body: this.translationFacade.instant('dialogs.new_contact_address_vendor', {
                                param1: name,
                                param2: address,
                            }),
                            negativeText: 'global.cancel',
                            positiveText: 'global.further',
                        };
                        this._dialogRefForLoader.close();
                        this._dialogService
                            .openDialog(GenericDialogComponent, {}, data).afterClosed().subscribe((xx) => {
                                if (xx) {
                                    setVendor(contactDto);
                                } else {
                                    this.onNavigateBack();
                                }
                            });
                    } else {
                        setVendor(item);
                    }
                });
        }, (error) => {
            this._dialogRefForLoader.close();
            // customer is protected
            if (error instanceof HttpErrorResponse && error.status === 409) {
                setVendor(item);
            }
        });
    }

    public onNavigateBack(): void {
        void this._router.navigate(['../add-vendor'], {
            relativeTo: this.activatedRoute,
        });
    }

    public formatAddress(contact: ContactDTO): string {
        let address = '';
        if (contact.street) {
            address += contact.street;
        }

        if (contact.house_number) {
            address += ` ${contact.house_number}`;
        }

        if ('postcode' in contact && contact.postcode) {
            address += `, ${contact.postcode}`;
        } else if ('postal_code' in contact && contact.postal_code) {
            address += `, ${contact.postal_code}`;
        }

        if (contact.city) {
            address += ` ${contact.city}`;
        }

        if ('contact_number' in contact && contact.contact_number !== null) {
            const sep = address.trim().length > 0 ? ' | ' : '';
            address += `${sep}abcfinance Kontaktnr: ${contact.contact_number}`;
        }

        return address;
    }

    // #endregion

}
