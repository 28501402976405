import { AppConfig, IAppConfigData } from '@abcfinlab/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ObjectGroupDTO } from '../../../models/ObjectGroupDTO.interface';

@Injectable({
    providedIn: 'root',
})
export class ObjectgroupService {

    private readonly _host: string;
    private readonly _api: string;

    constructor(
        private readonly _http: HttpClient,
        appConfig: AppConfig<IAppConfigData>,
    ) {
        this._host = appConfig.get<string>('host');
        this._api = '/api/v1/objectgroups';
    }

    /**
   * Get all object groups by a single endpoint call from sales backend
   */
    public getObjectGroups(): Observable<Array<ObjectGroupDTO>> {
        return this._http.get<Array<ObjectGroupDTO>>(`${this._host + this._api}`, { withCredentials: true });
    }

    /**
   * Get a single object group by id.
   */
    public getObjectGroupById(id: string): Observable<ObjectGroupDTO> {
        if (typeof id === 'undefined' || id === null) {
            return throwError(new Error('No object group ID provided.'));
        }
        return this._http.get<ObjectGroupDTO>(`${this._host + this._api}/${id}`, { withCredentials: true });
    }

}
