<l7-dialog-header [title]="'So geht es weiter:' | translate"
                  [closeable]="true"
                  (closed)="onClose()">
</l7-dialog-header>
<mat-dialog-content fxLayout="column">
    <p>
        Sie können während der Prüfung weitere <a (click)="onLinkClick()">Angebote oder Verträge erstellen</a> und bearbeiten.
        Sobald sich der Status der Prüfung ändert, werden wir Sie hier und per E-Mail informieren.
    </p>
</mat-dialog-content>
