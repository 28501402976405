<l7-page>
    <l7-page-header #header
                    *ngIf="(presenter.lessees | async ).length"
                    [hasBackNavigation]="true"
                    [title]="'quote.retailers.lessees.update-title' | translate"></l7-page-header>
    <l7-page-header #header
                    *ngIf="!(presenter.lessees | async ).length"
                    [hasBackNavigation]="true"
                    [title]="'quote.retailers.lessees.update-empty-list-title' | translate"></l7-page-header>
    <l7-page-pre-content fxLayout="row"
                         *ngIf="(presenter.lessees | async ).length"
                         fxLayoutAlign="start start">
        <l7-page-section fxFlex.lt-md="100"
                         fxFlex="40">
            <h2 class="subTitle">{{'quote.retailers.lessees.update-subtitle'| translate}}</h2>
        </l7-page-section>
    </l7-page-pre-content>
    <l7-page-content fxFlex>

        <mat-nav-list fxFlex
                      *ngIf="(presenter.lessees | async).length else emptyList"
                      fxFill>
            <mat-list-item *ngFor="let lessee of presenter.lessees | async; last as last"
                           (click)="presenter.onLesseeSelected(lessee)">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" matListItemTitle [style]="'margin-bottom: 0px;'">
                            <l7-text-block [truncate]="true"
                                           [text]="lessee.name"></l7-text-block>
                        </div>
                        <l7-text-block matListItemLine
                                       [truncate]="true"
                                       [readonly]="true"
                                       [text]="(lessee.street + ' ' + lessee.houseNumber + ', ' + lessee.postalCode + ' ' + lessee.city) + (lessee?.contactNumber ? ' | ' + ('global.nav_contact' | translate) + ': ' + lessee.contactNumber : '')"></l7-text-block>
                <l7-spacer [thickness]="'bottom'" [density]="'comfortable'">
                </l7-spacer>
                        <mat-divider *ngIf="!last"
                                     class="without-margin"
                                     [inset]="true"></mat-divider>
                    <div matListItemMeta>
                        <mat-icon [fontSet]="'l7'"
                                  [fontIcon]="'Chevron_Right'"></mat-icon>
                    </div>
            </mat-list-item>
        </mat-nav-list>
    </l7-page-content>
</l7-page>
<ng-template #emptyList>
    <div fxLayout="column"
         fxLayoutGap="24px" style="padding: 2px;">
        <mat-card>
            <mat-card-content fxLayout="column" fxFlexAlign="center">
                <l7-spacer [thickness]="'bottom'" [multiplier]="12">
                </l7-spacer>
                <div fxLayoutAlign="center">
                    <img src="assets/images/image-not-found.svg" alt="">
                </div>
                <l7-spacer [thickness]="'bottom'" [multiplier]="48">
                </l7-spacer>
                <l7-text-block class="mat-headline-6"
                               [truncate]="true"
                               [color]="'bold'"
                               [text]="'global.wait' | translate"
                               [alignment]="'center'"></l7-text-block>
                <l7-spacer [thickness]="'bottom'" [multiplier]="24">
                </l7-spacer>
                <l7-text-block
                               [maxLines]="3"
                               [text]="'quote.retailers.lessees.update-empty-list' | translate"
                               [alignment]="'center'"></l7-text-block>
                <l7-spacer [thickness]="'bottom'" [multiplier]="24">
                </l7-spacer>
                <l7-spacer [thickness]="'bottom'" [multiplier]="48">
                </l7-spacer>
                <div fxLayout="row" fxFlexAlign="center">
                    <button mat-flat-button
                            color="primary"
                            [attr.test-id]="'btnGoToContractDetails'"
                            (click)="goBack()"
                            >{{'quote.retailers.lessees.backToQuoteBtn' | translate}}</button>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</ng-template>
