// #region Imports

import { VerificationService } from '@abcfinlab/api/global';
import { FinanceAdminService, IIdTypeDto } from '@abcfinlab/api/retailer';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// #endregion

export namespace Validators {

    export function contactNumberExistsAsync(service: FinanceAdminService, whiteList?: Array<string> | (() => Array<string>)): AsyncValidatorFn {
        const fn = (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (whiteList) {
                const wl = (typeof whiteList === 'function' ? whiteList() : whiteList);
                if (wl?.some(x => x.includes(control.value))) {
                    return of(null);
                }
            }

            return service.isCreatable({ id: control.value, idType: IIdTypeDto.Contact }).pipe(
                delay(2000),
                tap(() => {
                    control.markAllAsTouched();
                }),
                map(() => null),
                catchError(() => of({
                    contactNumberExists: true,
                })),
            );
        };

        return fn;
    }

    export function partnerNumberExistsAsync(service: FinanceAdminService, whiteList?: Array<string> | (() => Array<string>)): AsyncValidatorFn {
        const fn = (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (whiteList) {
                const wl = (typeof whiteList === 'function' ? whiteList() : whiteList);
                if (wl?.some(x => x.includes(control.value))) {
                    return of(null);
                }
            }

            return service.isCreatable({ id: control.value, idType: IIdTypeDto.Partner }).pipe(
                delay(2000),
                tap(() => {
                    control.markAllAsTouched();
                }),
                map(() => null),
                catchError(() => of({
                    partnerNumberExists: true,
                })),
            );
        };

        return fn;
    }

    export function emailExistsAsync(service: FinanceAdminService): AsyncValidatorFn {
        const fn = (control: AbstractControl): Observable<ValidationErrors | null> => service.isUserEmailUnique({ email: control.value }).pipe(
            delay(2000),
            tap(() => {
                control.markAllAsTouched();
            }),
            map(() => null),
            catchError(() => of({
                emailExists: true,
            })),
        );

        return fn;
    }

    let cancelSubject = new Subject();
    export function emailInvalidAsync(service: VerificationService): AsyncValidatorFn {
        cancelSubject.next(true); // Cancel previous requests
        cancelSubject.complete(); // Complete the subject
        cancelSubject = new Subject(); // Create a new subject
        const fn = (control: AbstractControl): Observable<ValidationErrors | null> => service.verifyEmail({ emailAddress: control.value }).pipe(
            distinctUntilChanged(),
            debounceTime(1000),
            tap(() => {
                control.markAllAsTouched();
            }),
            switchMap(() => of(null)),
            takeUntil(cancelSubject),
            catchError(() => of({
                emailInvaid: true,
            })),
        );

        return fn;
    }

}
