<!-- only in TA/VA/KFZ -->
<mat-card *ngIf="leasingQuote.inhouseQuoteCalculation.contractType !== contractType.Mietkauf && leasingQuote.inhouseQuoteCalculation.contractType !== contractType.Mkn"
          [class.mat-card-outlined]="this.outlined"
          [class.mat-elevation-z0]="this.flat">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <mat-card-header *ngIf="hasHeader">
        <h3 matCardTitle>{{ 'contract_management.object_details_title' | translate }}</h3>
    </mat-card-header>
    <mat-card-content>
        <div fxFlex
             fxLayout="row wrap"
             fxLayoutGap="16px">
            <ng-container *ngTemplateOutlet="beforeQuoteTemplate"></ng-container>
            <fieldset *ngIf="quoteName"
                      [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.objectName' | translate }}</legend>
                <p>{{quoteName}}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.contractType' | translate }}</legend>
                <p>
                    <span *ngIf="leasingQuote.saleAndLeaseBack?.code === 'UNECHTLN' || leasingQuote.saleAndLeaseBack?.code === 'UNECHTLI'" [matTooltip]="'slb.oldSLbToolTip' | translate">{{ '*' }}</span>
                    Leasing-{{leasingQuote.inhouseQuoteCalculation?.contractType}}
                    <span *ngIf="leasingQuote.saleAndLeaseBack?.code"> + SLB <span *ngIf="hasSlbCode">({{'slb.codes.new.' + leasingQuote.saleAndLeaseBack?.code | translate}})</span></span>
                </p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.condition' | translate }}</legend>
                <p>{{ condition_to_text }}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.objectValue' | translate }}</legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.quoteCalculation.totalLeasingValue | currency: 'EUR'}}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.leasingPeriod' | translate }}</legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.quoteCalculation.totalTerms}} Monate</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.down_payment' | translate }}</legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.quoteCalculation.downPaymentValue | currency: 'EUR'}}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.residual_value' | translate }}</legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.quoteCalculation.residualValue | currency: 'EUR'}}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.instalment' | translate }}</legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.quoteCalculation.instalment | currency: 'EUR'}}
            </fieldset>
            <fieldset
                      [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.handlingFeeValue' | translate }}</legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.handlingFeeValue | currency : 'EUR'}}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      *ngIf="creditCheck"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.creditCheck' | translate }}</legend>
                <div *ngIf="creditCheck"
                     fxLayout="row wrap"
                     fxLayoutGap="8px"
                     fxLayoutAlign="start center">
                    <mat-icon [color]="creditCheck.credit_rating === 'POSITIVE' ? 'success' : 'warning'"
                              [inline]="true"
                              [fontSet]="'l7'"
                              [fontIcon]="creditCheck.credit_rating | creditCheckIcon:'icon'"></mat-icon>
                    <l7-text-block [truncate]="true"
                                   [text]="'states.credit_check.' + creditCheck.credit_rating | translate"></l7-text-block>
                    <l7-text-block [color]="(this.isCreditRatingExpired| async) ? 'danger' : 'neutral'"
                                   [text]="'(' + (creditCheck.timestamp | date) + ')'"></l7-text-block>
                </div>
            </fieldset>
            <ng-container *ngTemplateOutlet="refinancingInterestSection"></ng-container>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'"
                      *ngIf="calculation">
                <legend>{{ 'quote.summary.insurance' | translate }}</legend>
                <div fxLayout="row"
                     fxLayoutGap="8px"
                     fxLayoutAlign="start center">
                    @if (!calculation.insurance_type || calculation.insurance_type === IInsuranceTypeDto.No) {
                        <span>{{'states.insurance.NO' | translate}}: {{'0' | currency}}</span>
                    } @else {
                        <span>{{'states.insurance.' + leasingQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceType | translate}}: {{leasingQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceValue | currency}}</span>
                    }
                    <mat-icon *ngIf="leasingQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceType === 'STANDARD'"
                              [class]="calculation | insuranceTypeIcon:'color'"
                              [inline]="true"
                              [fontSet]="'fa'"
                              [fontIcon]="calculation | insuranceTypeIcon:'icon'"></mat-icon>
                </div>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 0px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 0px)'"
                      *ngIf="calculation">
                <legend>{{'contract_management.documents.TRANSPARENCY_REGISTER' | translate }}</legend>
                <span *ngIf="!hasTransparencyRegister">{{'states.transparency_register.not_available' | translate}}</span>
                <span *ngIf="hasTransparencyRegister">{{'states.transparency_register.available' | translate}}</span>
            </fieldset>
        </div>
    </mat-card-content>
    <l7-quote-summary-card-obligations *ngIf="creditCheck?.credit_rating"
        [obligations]="leasingQuote.obligations"
        [quoteCreditRating]="creditCheck.credit_rating">
    </l7-quote-summary-card-obligations>
</mat-card>
<!-- only in MIETKAUF And MKN -->
<mat-card *ngIf="leasingQuote.inhouseQuoteCalculation?.contractType === contractType.Mietkauf || leasingQuote.inhouseQuoteCalculation?.contractType === contractType.Mkn"
          [class.mat-card-outlined]="this.outlined"
          [class.mat-elevation-z0]="this.flat">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <mat-card-header *ngIf="hasHeader">
        <h3 matCardTitle>{{ 'contract_management.object_details_title' | translate }}</h3>
    </mat-card-header>
    <mat-card-content>
        <div fxFlex
             fxLayout="row wrap"
             fxLayoutGap="16px">
            <ng-container *ngTemplateOutlet="beforeQuoteTemplate"></ng-container>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'"
                      *ngIf="quoteName">
                <legend>{{ 'quote.summary.objectName' | translate }}</legend>
                <p>{{quoteName}}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{'global.contract_type' | translate}}</legend>
                <p *ngIf="leasingQuote.inhouseQuoteCalculation.contractType === contractType.Mietkauf">{{'global.hire_purchase_contract' | translate}}</p>
                <p *ngIf="leasingQuote.inhouseQuoteCalculation.contractType === contractType.Mkn">{{'quote.summary.contractTypeMkn' | translate: {smbCode: leasingQuote.saleAndLeaseBack?.code} }}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{'global.object_state' | translate}}</legend>
                <p *ngIf="leasingQuote.inhouseQuoteCalculation.objectCondition === 'NEW'; else used">{{'calculation.condition_new_label' | translate}}</p>
                <ng-template #used>
                    <p>{{'calculation.condition_used_label' | translate}}</p>
                </ng-template>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{'global.object_price' | translate}}</legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.quoteCalculation.totalLeasingValue | currency: 'EUR'}}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{'global.terms' | translate}}</legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.quoteCalculation.totalTerms}} {{'global.month' | translate}}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.down_paymentMKN' | translate }}</legend>
                <p *ngIf="leasingQuote.inhouseQuoteCalculation.quoteCalculation?.downPaymentValue > 0; else nodownPayment">
                    {{leasingQuote.inhouseQuoteCalculation.quoteCalculation?.downPaymentValue  | currency: 'EUR'}}
                </p>
                <ng-template #nodownPayment>
                    <p>{{ 0 | currency: 'EUR'}}</p>
                </ng-template>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{'calculation.first_instalment_complete' | translate}}</legend>
                <p *ngIf="leasingQuote.inhouseQuoteCalculation.quoteCalculation?.firstInstalmentValue > 0; else noFirstInstalment">
                    {{leasingQuote.inhouseQuoteCalculation.quoteCalculation?.firstInstalmentValue | currency: 'EUR'}}
                </p>
                <ng-template #noFirstInstalment>
                    <p>{{'global.no' | translate}}</p>
                </ng-template>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{'calculation.total_claim' | translate}}</legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.quoteCalculation?.totalInstalments | currency: 'EUR'}}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>
                    {{'global.month_rate' | translate}}
                    <span *ngIf="leasingQuote.inhouseQuoteCalculation.quoteCalculation?.firstInstalmentValue > 0; else startFromBegin">(2-</span>
                    <ng-template #startFromBegin>
                        <span>(1-</span>
                    </ng-template>
                    <span *ngIf="leasingQuote.inhouseQuoteCalculation.quoteCalculation?.lastInstalmentValue > 0; else endAtTheEnd">
                        {{leasingQuote.inhouseQuoteCalculation.quoteCalculation?.totalTerms - 1}})
                    </span>
                    <ng-template #endAtTheEnd>
                        <span>{{leasingQuote.inhouseQuoteCalculation.quoteCalculation.totalTerms}})</span>
                    </ng-template>
                </legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.quoteCalculation?.instalment | currency: 'EUR'}}</p>
            </fieldset>
            <fieldset *ngIf="leasingQuote.inhouseQuoteCalculation.quoteCalculation.totalInstalmentsVat"
                      [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{'calculation.total_claim_taxes' | translate}}</legend>
                <p *ngIf="leasingQuote.inhouseQuoteCalculation.contractType === contractType.Mietkauf">
                    {{leasingQuote.inhouseQuoteCalculation.quoteCalculation?.totalInstalmentsVat | currency: 'EUR'}}
                </p>
                <p *ngIf="leasingQuote.inhouseQuoteCalculation.contractType === contractType.Mkn">{{'quote.summary.totalInstalmentsVatMKN' | translate}}</p>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{'calculation.last_instalment_complete' | translate}}</legend>
                <p *ngIf="leasingQuote.inhouseQuoteCalculation.quoteCalculation.lastInstalmentValue > 0; else noLastInstalment">
                    {{leasingQuote.inhouseQuoteCalculation.quoteCalculation.lastInstalmentValue | currency: 'EUR'}}
                </p>
                <ng-template #noLastInstalment>
                    <p>{{'global.no' | translate}}</p>
                </ng-template>
            </fieldset>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.handlingFeeValue' | translate }}</legend>
                <p>{{leasingQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.handlingFeeValue | currency : 'EUR'}}</p>
            </fieldset>
            <l7-quote-summary-card-obligations *ngIf="creditCheck?.credit_rating"
                [obligations]="leasingQuote.obligations"
                [quoteCreditRating]="creditCheck.credit_rating">
            </l7-quote-summary-card-obligations>
            <fieldset [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      *ngIf="creditCheck"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.creditCheck' | translate }}</legend>
                <div *ngIf="creditCheck"
                     fxLayout="row wrap"
                     fxLayoutGap="8px"
                     fxLayoutAlign="start center">
                    <mat-icon [color]="creditCheck.credit_rating === 'POSITIVE' ? 'success' : 'warning'"
                              [inline]="true"
                              [fontSet]="'l7'"
                              [fontIcon]="creditCheck.credit_rating | creditCheckIcon:'icon'"></mat-icon>
                    <l7-text-block [truncate]="true"
                                   [text]="'states.credit_check.' + creditCheck.credit_rating | translate">
                    </l7-text-block>
                    <l7-text-block [color]="(this.isCreditRatingExpired| async) ? 'danger' : 'neutral'"
                                   *ngIf="creditCheck.timestamp"
                                   [text]="'(' + (creditCheck.timestamp | date) + ')'">
                    </l7-text-block>
                </div>
            </fieldset>
            <ng-container *ngTemplateOutlet="refinancingInterestSection"></ng-container>
            <fieldset *ngIf="calculation"
                      [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                <legend>{{ 'quote.summary.insurance' | translate }}</legend>
                @if (!calculation.insurance_type || calculation.insurance_type === IInsuranceTypeDto.No) {
                    <p> {{ 'states.insurance.NO' | translate  }}: {{'0' | currency}}</p>
                } @else {
                    <p> {{ 'states.insurance.' + leasingQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceType | translate }}: {{leasingQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceValue | currency}}</p>
                }
            </fieldset>
            <fieldset *ngIf="calculation"
                      [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 0px)'"
                      [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 0px)'">
                <legend>{{'contract_management.documents.TRANSPARENCY_REGISTER' | translate }}</legend>
                <div *ngIf="!hasTransparencyRegister">
                    <l7-text-block [wrap]="true"
                                   [maxLines]="3"
                                   [text]="'states.transparency_register.not_available' | translate">
                    </l7-text-block>
                </div>
                <div fxLayout="row"
                     fxLayoutGap="8px"
                     *ngIf="hasTransparencyRegister">
                    <mat-icon [color]="'warning'"
                              [inline]="true"
                              [fontSet]="'l7'"
                              [fontIcon]="'Alert_Circle_Filled'"></mat-icon>
                    <l7-text-block [wrap]="true"
                                   [maxLines]="3"
                                   [text]="'states.transparency_register.available'| translate">
                    </l7-text-block>
                </div>
            </fieldset>
        </div>
    </mat-card-content>
</mat-card>

<ng-template #refinancingInterestSection>
    <fieldset *ngIf="calculation"
              [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
              [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
        <legend fxLayout="row">
            {{ 'quote.summary.refinancing_rate' | translate }}
            <mat-icon *ngIf="refinancingInterest.interest > calculation.refinancing_rate"
                      [class]="'warning'"
                      [matTooltip]="'quote.summary.refinancing_rate_tooltip' | translate"
                      [inline]="true"
                      [fontSet]="'l7'"
                      [fontIcon]="'Alert_Circle'"></mat-icon>
        </legend>
        <p>Kalkuliert: {{(calculation.refinancing_rate | number) + ' %'}} | Aktuell: {{(refinancingInterest.interest | number) + ' %'}}</p>
    </fieldset>
</ng-template>

