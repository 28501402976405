import { IContactDto } from '@abcfinlab/api/contact';
import {
    IbanCheckService,
    IRetailerQuoteResultDto,
    IRetailerRemoteSignatureRequestDto, IRetailerSignerDto, IWorkflowStepStatusDto, RetailerQuoteService,
    VerificationService,
} from '@abcfinlab/api/global';
import { ControlsOf, Validators as CoreValidators, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { Validators as AdministrationValidators } from '../../../../../administration/src/Validators/Validators';
import {
    IBankAccountFormDto,
} from '../retailer-identification/RetailerIdentificationViewPresenter';

// #endregion

interface ICreateContractDialogData {
    quoteId: string;
    lesseeId: string;
    lessee: IContactDto;
    quoteDetails: IRetailerQuoteResultDto;
    signer: IRetailerSignerDto;
}

/**
 * The presenter of the {@link RetailerCreateContractView} view.
 *
 * @internal
 */
@Injectable()
export class RetailerCreateContractViewPresenter {

    // #region Fields
    private readonly _verificationService: VerificationService;
    private readonly _retailerQuoteService: RetailerQuoteService;
    private readonly _ibanCheckService: IbanCheckService;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _toastService: ToastService;
    private readonly _dialogRef: MatDialogRef<any>;
    private readonly _dialogData: BehaviorSubject<ICreateContractDialogData>;
    private readonly _quoteDetails: BehaviorSubject<IRetailerQuoteResultDto> = new BehaviorSubject<IRetailerQuoteResultDto>(null);
    private readonly _lessee: BehaviorSubject<IContactDto> = new BehaviorSubject<IContactDto>(null);
    private readonly _form: FormGroup<ControlsOf<IRetailerRemoteSignatureRequestDto>>;
    private readonly _quoteId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _isIdentifySignerCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private readonly _type: BehaviorSubject<'LOCAL' | 'LOCAL_WITHOUT_IDENTIFICATION'> = new BehaviorSubject<'LOCAL' | 'LOCAL_WITHOUT_IDENTIFICATION'>(null);

    public showBankAccountFields: boolean = false;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RetailerCreateContractViewPresenter` class.
     *
     * @public
     */
    public constructor(messageBoxService: MessageBoxService, translationFacade: TranslationFacade,
        busyBoxService: BusyBoxService, toastService: ToastService, dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) dialogData: ICreateContractDialogData, verificationService: VerificationService,
        retailerQuoteService: RetailerQuoteService, ibanCheckService: IbanCheckService) {
        this._verificationService = verificationService;
        this._retailerQuoteService = retailerQuoteService;
        this._ibanCheckService = ibanCheckService;
        this._messageBoxService = messageBoxService;
        this._translationFacade = translationFacade;
        this._toastService = toastService;
        this._busyBoxService = busyBoxService;
        this._dialogRef = dialogRef;
        this._dialogData = new BehaviorSubject<ICreateContractDialogData>(dialogData);

        this._quoteId.next(dialogData.quoteId);

        this._lessee.next(dialogData.lessee);
        this._quoteDetails.next(dialogData.quoteDetails);
        this._isIdentifySignerCompleted.next(dialogData.signer !== null);
        const type = dialogData.quoteDetails.workflow.workflowSteps.find(wf => wf.stepType === 'IDENTIFICATION').status === IWorkflowStepStatusDto.Completed ? 'LOCAL' : 'LOCAL_WITHOUT_IDENTIFICATION';
        this._type.next(type);

        this._form = new FormGroup<ControlsOf<IRetailerRemoteSignatureRequestDto>>({
            email: new FormControl('', [Validators.required, CoreValidators.email()], AdministrationValidators.emailInvalidAsync(verificationService)),
            signer: new FormGroup<ControlsOf<IRetailerSignerDto>>({
                emailAddress: new FormControl('', [Validators.required, CoreValidators.email()], AdministrationValidators.emailInvalidAsync(verificationService)),
                lastName: new FormControl('', Validators.required),
                firstName: new FormControl('', Validators.required),
            }),
            bankAccount: new FormGroup<ControlsOf<IBankAccountFormDto>>({
                bankAccountName: new FormControl(null),
                bankAccountType: new FormControl('DIRECT_DEBIT'),
                bankName: new FormControl(''),
                bic: new FormControl(''),
                iban: new FormControl(null, [], CoreValidators.validateIban(this._ibanCheckService)),
            }),
        });

        if (dialogData.quoteDetails.quote.signer) {
            this._form.controls.signer.patchValue(dialogData.quoteDetails.quote.signer);
            this._form.controls.email.patchValue(dialogData.quoteDetails.quote.signer.emailAddress);
        }
        if (dialogData.quoteDetails.quote.bankAccount) {
            this._form.controls.bankAccount.patchValue(dialogData.quoteDetails.quote.bankAccount);
        }
    }

    // #endregion

    // #region Properties
    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<ControlsOf<IRetailerRemoteSignatureRequestDto>> {
        return this._form;
    }

    /**
     * Returns the `quoteDetails` property.
     *
     * @public
     * @readonly
     */
    public get quoteDetails(): Observable<IRetailerQuoteResultDto> {
        return this._quoteDetails.asObservable();
    }

    /**
     * Returns the `lessee` property.
     *
     * @public
     * @readonly
     */
    public get lessee(): Observable<IContactDto> {
        return this._lessee.asObservable();
    }

    /**
     * Returns the `quoteId` property.
     *
     * @public
     * @readonly
     */
    public get quoteId(): Observable<string> {
        return this._quoteId.asObservable();
    }

    public get isIdentifySignerCompleted(): Observable<boolean> {
        return this._isIdentifySignerCompleted.asObservable();
    }

    public get type(): Observable<'LOCAL' | 'LOCAL_WITHOUT_IDENTIFICATION'> {
        return this._type.asObservable();
    }
    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        //
    }

    public setBankAccountIsShowed(value: boolean): void {
        this.showBankAccountFields = value;
    }

    /**
     * @internal
     */
    public onSubmit(): void {
        this._form.get('email').clearAsyncValidators();
        this._form.get('email').updateValueAndValidity();
        if (this._form.valid) {
            once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                this._retailerQuoteService.prepareRemoteSignature({
                    leasingQuoteId: this._quoteId.getValue(),
                    body: this._form.getRawValue(),
                })), res => this._dialogRef.close('REMOTE'));
        } else {
            this._form.get('email').addAsyncValidators(AdministrationValidators.emailInvalidAsync(this._verificationService));
            this._form.get('email').updateValueAndValidity();
            this._form.markAllAsTouched();
        }
    }

    // #endregion

}
