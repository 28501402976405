import { IPageContactSearchResultEntryDto } from '@abcfinlab/api/contact';
import { IRetailerCreateQuoteRequestDto } from '@abcfinlab/api/global';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
    SetCalculationSelectionForRequest,
    SetCalculationValuesForRequest, SetCurrentStep,
    SetLesseeListFromSearchResult, SetObjectValues, SetSearchValues,
} from '../Actions/RetailerCalculationActions';

export class RetailerCalculationStateModel {

    currentStep: number;
    lesseeSearchResults: IPageContactSearchResultEntryDto;
    createQuoteRequest: IRetailerCreateQuoteRequestDto;
    searchValues: {
        companyName: string;
        city: string;
        country: 'Deutschland';
    };

}

@State<RetailerCalculationStateModel>({
    name: 'retailerCalculation',
    defaults: {
        currentStep: 0,
        lesseeSearchResults: null,
        createQuoteRequest: null,
        searchValues: {
            companyName: null,
            city: null,
            country: 'Deutschland',
        },
    },
})

@Injectable()
export class RetailerCalculationState {

    @Selector()
    static getCurrentStep(state: RetailerCalculationStateModel): number {
        return state.currentStep;
    }

    @Selector()
    static getLesseeListFormSearchResult(state: RetailerCalculationStateModel): IPageContactSearchResultEntryDto {
        return state.lesseeSearchResults;
    }

    @Selector()
    static getCalculationValuesForRequest(state: RetailerCalculationStateModel): IRetailerCreateQuoteRequestDto {
        return state.createQuoteRequest;
    }

    @Selector()
    static getSearchValues(state: RetailerCalculationStateModel): { companyName: string; city: string; country: 'Deutschland' } {
        return state.searchValues;
    }

    @Action(SetCurrentStep)
    setCurrentStep({ patchState }: StateContext<RetailerCalculationStateModel>, { payload }: SetCurrentStep): void {
        patchState({
            currentStep: payload,
        });
    }

    @Action(SetLesseeListFromSearchResult)
    setLesseeListFromSearchResult({ patchState }: StateContext<RetailerCalculationStateModel>, { payload }: SetLesseeListFromSearchResult): void {
        patchState({
            lesseeSearchResults: payload,
        });
    }

    @Action(SetCalculationValuesForRequest)
    setCalculationValuesForRequest({ getState, patchState }: StateContext<RetailerCalculationStateModel>, { payload }: SetCalculationValuesForRequest): void {
        const ctx = getState();
        patchState({
            createQuoteRequest: {
                ...ctx.createQuoteRequest,
                ...payload,
            },
        });
    }

    @Action(SetCalculationSelectionForRequest)
    setCalculationSelectionForRequest({ getState, patchState }: StateContext<RetailerCalculationStateModel>, { payload }: SetCalculationSelectionForRequest): void {
        const ctx = getState();
        patchState({
            createQuoteRequest: {
                ...ctx.createQuoteRequest,
                ...payload,
            },
        });
    }

    @Action(SetObjectValues)
    setObjectValues({ getState, patchState }: StateContext<RetailerCalculationStateModel>, { payload }: SetObjectValues): void {
        const ctx = getState();
        patchState({
            createQuoteRequest: {
                ...ctx.createQuoteRequest,
                ...payload,
            },
        });
    }

    @Action(SetSearchValues)
    setSearchValues({ getState, patchState }: StateContext<RetailerCalculationStateModel>, { payload }: SetSearchValues): void {
        const ctx = getState();
        patchState({
            searchValues: {
                ...ctx.searchValues,
                ...payload,
            },
        });
    }

}
