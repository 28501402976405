import { IQuoteCalculationRequestDto } from '@abcfinlab/api/global';
import { toDecimal } from '../../../../apps/l7/src/app/helper/calculation.helper';

export const fieldsWithPercentageValues = {
    // don't change the order in the arrays the second element is always the percentage
    downPayment: ['downPaymentValue', 'downPaymentPercentage'],
    residualValue: ['residualValue', 'residualValuePercentage'],
    firstInstalment: ['firstInstalmentValue', 'firstInstalmentPercentage'],
    lastInstalment: ['lastInstalmentValue', 'lastInstalmentPercentage'],
};

export function calculatePercentages(quoteCalculation: IQuoteCalculationRequestDto): IQuoteCalculationRequestDto {
    Object.values(fieldsWithPercentageValues).forEach((value) => {
        if (quoteCalculation[value[0]]) {
            quoteCalculation[value[1]] = toDecimal(((quoteCalculation[value[0]] * 100) / quoteCalculation.totalLeasingValue), 10, true);
        }
    });
    return quoteCalculation;
}
