// #region Imports

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    MoneyLaunderingComponent,
} from '../../../../apps/l7/src/app/private/pages/money-laundering/money-laundering.component';
import { AuthGuard } from '../../../../apps/l7/src/app/shared/guards/auth.guard';
import {
    getCalculationSettingsResolver, getInhouseQuoteResolver,
    getLesseeResolver,
    getObjectGroupsResolver,
    getSlbCodesResolver, getSmbCodesResolver,
} from '../Resolvers/Resolvers';
import { SlbDetailsResolver } from '../Resolvers/slb-details/slb-details.resolver';
import {
    CREATE_QUOTE_CHOOSE_LESSEE_ROUTE,
    QUOTE_ADD_VENDOR_ROUTE, QUOTE_CALCULATION_PLAYGROUND_ROUTE,
    QUOTE_CALCULATION_ROUTE,
    QUOTE_CHANGE_CALCULATION_ROUTE,
    QUOTE_CREATE_CALCULATION_ROUTE,
    QUOTE_DETAIL_ROUTE,
    QUOTE_LIST_ROUTE,
    QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE,
    QUOTE_RETAILER_CREATE_QUOTE_ROUTE,
    QUOTE_RETAILER_LESSEES_ROUTE,
    QUOTE_RETAILER_QUOTE_DETAILS_ROUTE,
    QUOTE_SEARCH_LESSEE_ROUTE,
    QUOTE_SEARCH_VENDOR_ROUTE, RETAILER_CONTRACT_SIGNED_SUCCESS_ROUTE,
    RETAILER_LESSEE_UPDATE_ROUTE,
    RETAILER_QUOTES_LIST_ROUTE,
} from '../Routing/Routes';
import { CalculationView } from '../Views/calculation/CalculationView';
import { SlbDetailsFormComponent } from '../Views/slb-details-form/slb-details-form.component';

// #endregion

/**
 * @public
 */
export const QUOTES_ROUTES: Routes = [
    QUOTE_CALCULATION_PLAYGROUND_ROUTE,
    QUOTE_LIST_ROUTE,
    QUOTE_CALCULATION_ROUTE,
    QUOTE_SEARCH_LESSEE_ROUTE,
    QUOTE_RETAILER_CREATE_QUOTE_ROUTE,
    QUOTE_RETAILER_QUOTE_DETAILS_ROUTE,
    CREATE_QUOTE_CHOOSE_LESSEE_ROUTE,
    QUOTE_ADD_VENDOR_ROUTE,
    QUOTE_CREATE_CALCULATION_ROUTE,
    QUOTE_DETAIL_ROUTE,
    QUOTE_CHANGE_CALCULATION_ROUTE,
    QUOTE_SEARCH_VENDOR_ROUTE,
    // ...
    {
        path: 'gwg',
        component: MoneyLaunderingComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('../../../../libs/legacy/gwg/src/lib/gwg.module').then(mod => mod.GwgModule),
            },
        ],
        // loadChildren: './pages/money-laundering#MoneyLaunderingComponent'
    },
    {
        path: 'quotes/slb-details/:quote_id',
        component: SlbDetailsFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            slbDetails: SlbDetailsResolver,
        },
    },
    {
        path: 'quote/create/calculation/:lesseeId',
        component: CalculationView,
        canActivate: [AuthGuard],
        resolve: {
            objectGroups: getObjectGroupsResolver,
            lessee: getLesseeResolver,
            calculationSettings: getCalculationSettingsResolver,
            slbCodes: getSlbCodesResolver,
            smbCodes: getSmbCodesResolver,
        },
    },
    {
        path: 'quote/create/calculation/:quoteId/new',
        component: CalculationView,
        canActivate: [AuthGuard],
        resolve: {
            objectGroups: getObjectGroupsResolver,
            calculationSettings: getCalculationSettingsResolver,
            quote: getInhouseQuoteResolver,
            slbCodes: getSlbCodesResolver,
            smbCodes: getSmbCodesResolver,
        },
    },
    QUOTE_RETAILER_CREATE_QUOTE_ROUTE,
    QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE,
    QUOTE_RETAILER_QUOTE_DETAILS_ROUTE,
    QUOTE_RETAILER_LESSEES_ROUTE,
    RETAILER_LESSEE_UPDATE_ROUTE,
    RETAILER_QUOTES_LIST_ROUTE,
    RETAILER_CONTRACT_SIGNED_SUCCESS_ROUTE,
];

/**
 * @public
 */
@NgModule({
    imports: [
        RouterModule.forChild(QUOTES_ROUTES),
    ],
    exports: [RouterModule],
})
export class QuotesRoutingModule {
}
