// #region Imports

import { BeneficialOwnerService, ContactService, IIdTypeDto } from '@abcfinlab/api/contact';
import {
    CreditCheckService,
    IContractDocumentDto,
    ICreditCheckDto,
    ILeasingQuoteCreditRatingDto,
    IRetailerQuoteDetailInformationResultDto, IRetailerQuoteDto,
    IRetailerQuoteResultDto,
    IRetailerSignerDto,
    IWorkflowDto,
    IWorkflowInfoDto,
    IWorkflowStateDto,
    IWorkflowStepDto,
    IWorkflowStepStatusDto, IWorkflowSubStepDto,
    RetailerQuoteService,
    RetailerQuoteWorkflowService,
} from '@abcfinlab/api/global';
import { EventHub, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, timer } from 'rxjs';
import { concatMap, first, map, retry, switchMap, tap } from 'rxjs/operators';
import { RETAILER_CONTRACT_SIGNED_SUCCESS_PATH } from '../../../../contractManagement/src/Routing/RoutePaths';
import { KnownEvents } from '../../Models/KnowEvents';
import {
    QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH,
    RETAILER_QUOTE_SIGNED_SUCCESS_PATH,
} from '../../Routing/RoutePaths';
import { WorkflowStepsIndicatorsService } from '../../Services/workflow-steps-indicators.service';
import { RetailerBonicheckPendingComponent } from '../Dialogs/retailer-bonicheck-pending/retailer-bonicheck-pending.component';
import { RetailerCreateContractView } from '../Dialogs/retailer-create-contract/RetailerCreateContractView';
import { RetailerIdentificationView } from '../Dialogs/retailer-identification/RetailerIdentificationView';

// #endregion

/**
 * The presenter of the {@link RetailerQuoteDetailsView} view.
 *
 * @internal
 */
@Injectable()
export class RetailerQuoteDetailsViewPresenter {

    // #region Fields

    private readonly _router: Router;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _toastService: ToastService;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _eventHub: EventHub;
    private readonly _retailerQuoteService: RetailerQuoteService;
    private readonly _retailerQuoteWorkflowService: RetailerQuoteWorkflowService;
    private readonly _creditCheckService: CreditCheckService;
    private readonly _contactService: ContactService;
    private readonly _beneficialOwnersService: BeneficialOwnerService;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _dialog: MatDialog;
    private readonly _quoteDetails: BehaviorSubject<IRetailerQuoteDetailInformationResultDto> = new BehaviorSubject<IRetailerQuoteDetailInformationResultDto>(null);
    private readonly _quoteTasks: BehaviorSubject<Array<IWorkflowStepDto>> = new BehaviorSubject<Array<IWorkflowStepDto>>(null);
    private readonly _quoteDocuments: BehaviorSubject<MatTableDataSource<IContractDocumentDto>> = new BehaviorSubject<MatTableDataSource<IContractDocumentDto>>(null);
    private readonly _quoteId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _workflowStepTooltip: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _openTasks: BehaviorSubject<number> = new BehaviorSubject<number>(3);
    private readonly _creditCheckStatus: BehaviorSubject<ILeasingQuoteCreditRatingDto> = new BehaviorSubject<ILeasingQuoteCreditRatingDto>(ILeasingQuoteCreditRatingDto.Open);
    private readonly _creditCheckObligations: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _isDraftLessee: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _creditCheckObligationPercent: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private readonly _identificationTaskStatus: BehaviorSubject<IWorkflowStepStatusDto> = new BehaviorSubject<IWorkflowStepStatusDto>(null);
    private readonly _createContractTaskStatus: BehaviorSubject<IWorkflowStepStatusDto> = new BehaviorSubject<IWorkflowStepStatusDto>(null);
    private readonly _emailInRemoteLink: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _workflowInfo: BehaviorSubject<IWorkflowInfoDto | null> = new BehaviorSubject<IWorkflowInfoDto | null>(null);
    private readonly _signer: BehaviorSubject<IRetailerSignerDto | null> = new BehaviorSubject<IRetailerSignerDto | null>(null);
    private readonly _identificationSubSteps: BehaviorSubject<Array<IWorkflowSubStepDto>> = new BehaviorSubject<Array<IWorkflowSubStepDto>>(null);
    private readonly _quote: BehaviorSubject<IRetailerQuoteDto> = new BehaviorSubject<IRetailerQuoteDto>(null);
    private _activatedRouteSubscription: Subscription;
    private _pollingSubscription: Subscription;
    private _documentsDataSource: MatTableDataSource<IContractDocumentDto>;
    private readonly _workflowStepsIndicator: WorkflowStepsIndicatorsService;

    private readonly _boniCheckPending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // toggle boni check list item behaviour

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RetailerQuoteDetailsViewPresenter` class.
     *
     * @public
     */
    public constructor(toastService: ToastService, busyBoxService: BusyBoxService, translationFacade: TranslationFacade,
        messageBoxService: MessageBoxService, activatedRoute: ActivatedRoute, retailerQuoteWorkflowService: RetailerQuoteWorkflowService,
        creditCheckService: CreditCheckService, retailerQuoteService: RetailerQuoteService, contactService: ContactService,
        dialog: MatDialog, beneficialOwnersService: BeneficialOwnerService, eventHub: EventHub, router: Router, workFlowStepsIndicator: WorkflowStepsIndicatorsService) {
        this._router = router;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._messageBoxService = messageBoxService;
        this._toastService = toastService;
        this._eventHub = eventHub;
        this._dialog = dialog;
        this._activatedRoute = activatedRoute;
        this._retailerQuoteWorkflowService = retailerQuoteWorkflowService;
        this._creditCheckService = creditCheckService;
        this._retailerQuoteService = retailerQuoteService;
        this._contactService = contactService;
        this._beneficialOwnersService = beneficialOwnersService;
        this._workflowStepsIndicator = workFlowStepsIndicator;
    }

    // #endregion

    // #region Properties
    /**
     * Returns the `quoteDetails` property.
     *
     * @public
     * @readonly
     */
    public get quoteDetails(): Observable<IRetailerQuoteDetailInformationResultDto> {
        return this._quoteDetails.asObservable();
    }

    /**
     * Returns the `quoteId` property.
     *
     * @public
     * @readonly
     */
    public get quoteId(): Observable<string> {
        return this._quoteId.asObservable();
    }

    /**
     * Returns the `quoteId` property.
     *
     * @public
     * @readonly
     */
    public get isDraftLessee(): Observable<boolean> {
        return this._isDraftLessee.asObservable();
    }

    /**
     * Returns the `quoteTasks` property.
     * @deprecated
     * @public
     * @readonly
     */
    public get quoteTasks(): Observable<Array<IWorkflowStepDto>> {
        return this._quoteTasks.asObservable().pipe(
            map((originalTasks) => {
                if (!originalTasks) {
                    return [];
                }
                const tasks = [...originalTasks];
                const migratedTasks = tasks.find(task => task.stepType === 'CREDIT_CHECK');
                const crefoConfirmationTask = tasks.find(task => task.stepType === 'CREFO_CONFIRMATION');
                if (migratedTasks) {
                    tasks.splice(tasks.indexOf(migratedTasks), 1);
                }
                if (crefoConfirmationTask) {
                    tasks.splice(tasks.indexOf(crefoConfirmationTask), 1);
                }
                return tasks;
            }),
        );
    }

    /**
     * Returns the tasks that are already 'migrated' to the new component implementations
     * @public
     * @readonly
     * @todo LEASA-XXXX:
     * - add further tasks as soon as they are migrated to isolated components
     */
    public get migratedTasks(): Observable<Array<IWorkflowStepDto>> {
        return this._quoteTasks.asObservable().pipe(
            map((tasks) => {
                if (!tasks) {
                    return [];
                }
                return [tasks.find(task => task.stepType === 'CREDIT_CHECK')];
            }),
        );
    }

    /**
     * Returns the `openTasks` property.
     *
     * @public
     * @readonly
     */
    public get openTasks(): Observable<number> {
        return this._openTasks.asObservable();
    }

    /**
     * Returns the `quoteDocuments` property.
     *
     * @public
     * @readonly
     */
    public get quoteDocuments(): Observable<MatTableDataSource<IContractDocumentDto>> {
        return this._quoteDocuments.asObservable();
    }

    /**
     * Returns the `creditCheckStatus` property.
     *
     * @public
     * @readonly
     */
    public get creditCheckStatus(): Observable<ILeasingQuoteCreditRatingDto> {
        return this._creditCheckStatus.asObservable();
    }

    /**
     * Returns the `identificationTaskStatus` property.
     *
     * @public
     * @readonly
     */
    public get identificationTaskStatus(): Observable<IWorkflowStepStatusDto> {
        return this._identificationTaskStatus.asObservable();
    }

    /**
     * Returns the `creditCheckObligations` property.
     *
     * @public
     * @readonly
     */
    public get creditCheckObligations(): Observable<string> {
        return this._creditCheckObligations.asObservable();
    }

    public get workflowInfo(): Observable<IWorkflowInfoDto> {
        return this._workflowInfo.asObservable();
    }

    /**
     * Returns the `creditCheckObligationPercent` property.
     *
     * @public
     * @readonly
     */
    public get creditCheckObligationPercent(): Observable<number> {
        return this._creditCheckObligationPercent.asObservable();
    }

    /**
     * Returns the `emailInRemoteLink` property.
     *
     * @public
     * @readonly
     */
    public get emailInRemoteLink(): Observable<string> {
        return this._emailInRemoteLink.asObservable();
    }

    /**
     * Return the actual state of the boni check. The underlying BehaviourSubject is triggered manually as soons as
     * a credit check is started or within the old initialize function on view creation.
     */
    public get creditCheckIsPending(): Observable<boolean> {
        return this._boniCheckPending.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._activatedRouteSubscription = combineLatest([this._activatedRoute.data, this._activatedRoute.params])
            .subscribe(([data, params]) => {
                if (data.quoteDetails) {
                    this._documentsDataSource = new MatTableDataSource<IContractDocumentDto>(data.quoteDetails.documents);

                    this._quoteDocuments.next(this._documentsDataSource);
                    this.updateQuoteDetails(data.quoteDetails);

                    const creditCheckTask = data.quoteDetails.workflow.workflowSteps.find(step => step.stepType === 'CREDIT_CHECK');
                    if (creditCheckTask.status === IWorkflowStepStatusDto.Pending || creditCheckTask.status === IWorkflowStepStatusDto.InProgress) {
                        this.startPolling();
                    }
                }

                if (params.id) {
                    this._quoteId.next(params.id);
                }
            });

        this._eventHub.getEvent<IRetailerQuoteResultDto>(KnownEvents.RETAILER_QUOTE_DETAILS).subscribe((res) => {
            this.updateQuoteDetails(res);
        });
        this._eventHub.getEvent<ILeasingQuoteCreditRatingDto>(KnownEvents.RETAILER_QUOTE_CREDIT_CHECK_STATUS)
            .subscribe(res => this._creditCheckStatus.next(res));
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._activatedRouteSubscription.unsubscribe();
        this._pollingSubscription?.unsubscribe();
    }

    public onTaskSelected(task: IWorkflowStepDto): void {
        if (!task.startable || (task.stepType === 'CREATE_CONTRACT' && task.status === 'PENDING')) {
            return;
        }
        switch (task.stepType) {
            case 'CREDIT_CHECK':
                const otherCompletedCreditChecksExists = task.metaInformation?.otherCompletedCreditChecksExists;
                if (otherCompletedCreditChecksExists) {
                    const completedCreditCheckQuoteNumber = task.metaInformation?.completedCreditCheckQuoteNumber;
                    const msg = `Für das Angebot existiert bereits eine genehmigte Angebotsvariante. Wenn Sie hiermit eine erneute Bonitätsprüfung anstoßen, wird der bestehende Fortschritt für das Angebot ${String(completedCreditCheckQuoteNumber)} zurückgesetzt. Möchten Sie fortfahren?`;
                    once(this._messageBoxService.show('Wollen Sie wirklich diese Angebotsvariante zur Bonitätsprüfung senden?', msg, MessageBoxButton.YesNo, {
                        labels: {
                            yes: 'Ja',
                            no: 'Nein',
                        },
                    }), (x) => {
                        if (x === MessageBoxResult.Yes) {
                            this.triggerCreditCheck(task.id);
                        }
                    });
                } else {
                    this.triggerCreditCheck(task.id);
                }
                break;
            case 'IDENTIFICATION':
                once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                    this._contactService.getById({ id: this._quoteDetails.getValue().lesseeId, idType: IIdTypeDto.Id })
                        .pipe(concatMap(lessee => combineLatest([
                            this._contactService.identificationRequired({ lesseeId: this._quoteDetails.getValue().lesseeId }),
                            this._beneficialOwnersService.search({ crefoId: lessee.crefo_id }),
                            of(lessee),
                        ]))),
                ),
                ([id, be, le]) => {
                    // open dialog
                    once(this._dialog.open(RetailerIdentificationView, {
                        closeOnNavigation: true,
                        disableClose: true,
                        autoFocus: true,
                        maxWidth: '90vw',
                        maxHeight: '100vh',
                        panelClass: 'fit-to-content',
                        data: {
                            quoteId: this._quoteId.getValue(),
                            identificationRequired: id.identificationRequired,
                            beneficialOwners: be,
                            lessee: le,
                            bankAccount: this._quote.getValue().bankAccount,
                            signer: this._signer.getValue(),
                            subSteps: this._identificationSubSteps.getValue(),
                        },
                    }).afterClosed(), (result) => {
                        if (result && (result !== IWorkflowStepStatusDto.Completed || this._createContractTaskStatus.getValue() !== IWorkflowStepStatusDto.Completed)) {
                            this._eventHub.publish<number>(KnownEvents.RETAILER_QUOTE_NEXT_STEP, 3);
                            this.getWorkflowStatus();
                        } else if (result && result === IWorkflowStepStatusDto.Completed && this._createContractTaskStatus.getValue() === IWorkflowStepStatusDto.Completed) {
                            void this._router.navigateByUrl(`presentation/${RETAILER_CONTRACT_SIGNED_SUCCESS_PATH}/${this._quote.getValue().contractId}?id=${this._quote.getValue().lesseeId}&type=i`);
                        }
                    });
                });
                break;
            case 'CREATE_CONTRACT':
                const panelClass = this._signer.getValue() ? 'dialog-with-width' : 'fit-to-content';
                once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                    combineLatest([
                        this._contactService.getById({ id: this._quoteDetails.getValue().lesseeId, idType: IIdTypeDto.Id }),
                        this._retailerQuoteService.get({ leasingQuoteId: this._quoteId.getValue() }),
                    ])),
                ([le, qo]) => {
                    once(this._dialog.open(RetailerCreateContractView, {
                        closeOnNavigation: true,
                        disableClose: true,
                        autoFocus: true,
                        maxWidth: '90vw',
                        maxHeight: '100vh',
                        panelClass: panelClass,
                        data: {
                            quoteId: this._quoteId.getValue(),
                            lesseeId: this._quoteDetails.getValue().lesseeId,
                            quoteDetails: qo,
                            lessee: le,
                            signer: this._signer.getValue(),
                        },
                    }).afterClosed(), (result: 'LOCAL' | 'REMOTE' | 'REMOTE_WITHOUT_IDENTIFICATION' | 'LOCAL_WITHOUT_IDENTIFICATION' | 'ERROR') => {
                        if (result === 'LOCAL') {
                            void this._router.navigateByUrl(`presentation/${RETAILER_CONTRACT_SIGNED_SUCCESS_PATH}/${this._quote.getValue().contractId}?id=${this._quote.getValue().lesseeId}&type=c`);
                        } else if (result === 'LOCAL_WITHOUT_IDENTIFICATION') {
                            void this._router.navigateByUrl(`presentation/${RETAILER_QUOTE_SIGNED_SUCCESS_PATH}/${this._quoteId.getValue()}?id=${this._quote.getValue().lesseeId}&type=q`);
                        } else if (result === 'REMOTE' || result === 'REMOTE_WITHOUT_IDENTIFICATION' || result === 'ERROR') {
                            this.getWorkflowStatus();
                        }
                    });
                });

                break;
        }
    }

    public onSearchLesseeTaskSelected(): void {
        void this._router.navigateByUrl(`presentation/quote/retailer/lessee/update/${this._quoteDetails.getValue().lesseeId}/${String(this._quoteId.getValue())}`);
    }

    public triggerAction(action: string, stepId: string): void {
        once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
            this._retailerQuoteWorkflowService.triggerContextAction({ stepId: stepId, stepContextAction: action, body: {} })));
    }

    /**
     * Handle the action emitted by the creditcheck component
     * After success the user is redirected to the same screen with the new version of the quote
     */
    public triggerCreditCheckAction(eventPayload: { action: string; id: string }): void {
        once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
            this._retailerQuoteWorkflowService.triggerContextAction({ stepId: eventPayload.id, stepContextAction: eventPayload.action, body: {} })),
        (res) => {
            void this._router.navigateByUrl(`presentation/${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}/${String(res.resultValueMap.leasingQuoteId)}`);
        });
    }

    public onLinkCopy(payload: boolean): void {
        this._toastService.show('In Zwischenablage gespeichert!', 'success');
    }

    /**
     * Polling the BE for credit check.
     * Used in conjunction with the *triggerCreditCheck* method or on view initialization
     */
    private startPolling(): void {
        this._pollingSubscription = timer(1, 10000).pipe(
            tap(() => this._boniCheckPending.next(true)),
            switchMap(() => this._creditCheckService.getCreditCheck({ quoteId: this._quoteId.getValue() })),
            retry(),
            first(res => res.credit_rating !== ILeasingQuoteCreditRatingDto.Pending && res.credit_rating !== ILeasingQuoteCreditRatingDto.Open),
            switchMap((res: ICreditCheckDto) => {
                this._creditCheckStatus.next(res.credit_rating);
                return this._retailerQuoteService.get({ leasingQuoteId: this._quoteId.getValue() });
            }),
        ).subscribe(res => this._handleBoniCheckResponse(res));
    }

    private triggerCreditCheck(stepId: string): void {
        this._boniCheckPending.next(true);
        this._retailerQuoteWorkflowService.triggerCreditCheck({ workflowStepId: stepId }).pipe(
            tap(() => this._boniCheckPending.next(true)),
            switchMap(() => timer(1, 5000)),
            switchMap(() => this._creditCheckService.getCreditCheck({ quoteId: this._quoteId.getValue() })),
            retry(),
            first(res => res.credit_rating !== ILeasingQuoteCreditRatingDto.Pending && res.credit_rating !== ILeasingQuoteCreditRatingDto.Open),
            switchMap((res: ICreditCheckDto) => {
                /**
                 * @todo LEASA-8158:
                 * Emitting the events results in wrong states on the "stepper" in the parent component.
                 * Here a decision should be made (states should be excluded)
                 */
                this._eventHub.publish<ILeasingQuoteCreditRatingDto>(KnownEvents.RETAILER_QUOTE_CREDIT_CHECK_STATUS, ILeasingQuoteCreditRatingDto.Pending);
                this._eventHub.publish<number>(KnownEvents.RETAILER_QUOTE_NEXT_STEP, 2);
                this._creditCheckStatus.next(res.credit_rating);
                return this._retailerQuoteService.get({ leasingQuoteId: this._quoteId.getValue() });
            }),
        ).subscribe(res => this._handleBoniCheckResponse(res));
    }

    /**
     * Handle the response of a boni check either for a new started one or after polling on initial view load.
     */
    private _handleBoniCheckResponse(res: any): void {
        this._eventHub.publish<IRetailerQuoteResultDto>(KnownEvents.RETAILER_QUOTE_DETAILS, res);
        this._boniCheckPending.next(false);
    }

    private getWorkflowStatus(): void {
        once(this._retailerQuoteService.get({ leasingQuoteId: this._quoteId.getValue() }), (res) => {
            this._eventHub.publish<IRetailerQuoteResultDto>(KnownEvents.RETAILER_QUOTE_DETAILS, res);
        });
    }

    private updateQuoteDetails(quoteDetails: IRetailerQuoteResultDto): void {
        this._quoteDetails.next(quoteDetails.detail);
        this._openTasks.next(quoteDetails.workflow.workflowInfo.tasksTotal - quoteDetails.workflow.workflowInfo.tasksCompleted);
        this._quoteTasks.next(quoteDetails.workflow.workflowSteps);
        this._workflowInfo.next(quoteDetails.workflow.workflowInfo);
        this._quote.next(quoteDetails.quote);
        this._signer.next(quoteDetails.quote.signer);
        this._identificationSubSteps.next(quoteDetails.workflow.workflowSteps.find(step => step.stepType === 'IDENTIFICATION').subSteps);
        const creditCheckStatus = quoteDetails.workflow.workflowSteps.find(step => step.stepType === 'CREDIT_CHECK').metaInformation?.creditCheckRating;
        const creditCheckObligations = quoteDetails.workflow.workflowSteps.find(step => step.stepType === 'CREDIT_CHECK').metaInformation?.creditCheckObligations as any;
        let obligationsText = '';
        if (creditCheckObligations?.obligations.length) {
            creditCheckObligations?.obligations?.forEach(o => obligationsText += `${o.description}, `);
        }
        this._creditCheckObligations.next(obligationsText);
        this._creditCheckObligationPercent.next(creditCheckObligations?.obligation_in_percent);
        this._creditCheckStatus.next(creditCheckStatus as ILeasingQuoteCreditRatingDto);
        this._identificationTaskStatus.next(quoteDetails.workflow.workflowSteps.find(step => step.stepType === 'IDENTIFICATION').status);
        this._createContractTaskStatus.next(quoteDetails.workflow.workflowSteps.find(step => step.stepType === 'CREATE_CONTRACT').status);

        if (quoteDetails.quote.signing) {
            this._emailInRemoteLink.next(quoteDetails.quote.signing.sentTo);
        }
        this.setWorkflowStepTooltip(quoteDetails.workflow);
        this._isDraftLessee.next(quoteDetails.workflow.workflowInfo.firstOpenType === 'CREFO_CONFIRMATION');
    }

    private setWorkflowStepTooltip(workflow: IWorkflowDto): void {
        this._workflowStepTooltip.next(this._workflowStepsIndicator.workflowStepTooltip(workflow.workflowInfo.tasksCompleted, workflow.workflowInfo.workflowState, workflow.workflowInfo.firstOpenType, workflow.workflowSteps));
    }

    /**
    * Returns the text for the step-chip according to the actual workflow state
    */
    public get stepchipLabel$(): Observable<string> {
        return this._boniCheckPending.asObservable().pipe(
            map((isPending) => {
                if (isPending) {
                    return `1/${this._workflowInfo.getValue()?.tasksTotal}`;
                }
                return `${this._workflowInfo.getValue()?.tasksCompleted}/${this._workflowInfo.getValue()?.tasksTotal}`;
            }),
        );
    }

    /**
     * Returns the text for the step-chip tooltip according to the actual workflow state
     */
    public get workflowStepTooltip(): Observable<string> {
        return this._boniCheckPending.asObservable().pipe(
            map((isPending) => {
                if (isPending) {
                    return this._translationFacade.instant('quote.retailers.quotesList.versions.tooltips.steps.creditCheck.pending');
                }
                return this._workflowStepTooltip.getValue();
            }),
        );
    }

    /**
     * Returns the config-Object for the underlying RetailerQuoteStepChip-component.
     *
     * @public
     * @readonly
     */
    public get retailerQuoteStepChipConfig$(): Observable<{ icon: string; variant: string }> {
        return combineLatest([this.creditCheckStatus, this.workflowInfo]).pipe(
            map(res => this._getRetailerQuoteStepChipConfig(res[0], res[1]?.workflowState)),
        );
    }

    /**
     * Open the modal for further information on pending state
     */
    public openModal(ev: Event): void {
        this._dialog.open(RetailerBonicheckPendingComponent, {
            width: '460px',
            maxWidth: '100%',
        });
    }

    /**
     * Construct the config-Object for the RetailerQuoteStepChip-component based on status
     * @param creditCheckStatus
     * @param workflowState
     */
    private _getRetailerQuoteStepChipConfig(
        creditCheckStatus: ILeasingQuoteCreditRatingDto | string, workflowState: IWorkflowStateDto | string,
    ): { icon: string; variant: string } {
        if (this._boniCheckPending.getValue()) {
            return { icon: 'Time', variant: 'PENDING' };
        }
        if (creditCheckStatus !== ILeasingQuoteCreditRatingDto.ApprovedWithConditions && workflowState === IWorkflowStateDto.Open) {
            return { icon: 'Checkbox_Square', variant: workflowState };
        }
        if (creditCheckStatus === ILeasingQuoteCreditRatingDto.ApprovedWithConditions && workflowState === IWorkflowStateDto.Open) {
            return { icon: 'Alert_Triangle', variant: 'WARNING' };
        }
        if (workflowState === IWorkflowStateDto.Completed) {
            return { icon: 'Checkbox_Square', variant: workflowState };
        }
        if (workflowState === IWorkflowStateDto.Pending) {
            return { icon: 'Time', variant: workflowState };
        }
        if (workflowState === IWorkflowStateDto.Error || workflowState === IWorkflowStateDto.Declined) {
            return { icon: 'Cross_Circle', variant: workflowState };
        }
    }

}
