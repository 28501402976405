/* libs/quote/src/Components/insurance-and-handling-fee/InsuranceAndHandlingFeeComponent.scss */
:host l7-summary {
  max-height: 54px;
  padding: 8px;
}
:host mat-select ::ng-deep .mat-mdc-select-value-text {
  text-transform: capitalize;
}
:host .no-margin {
  margin-bottom: 0 !important;
}
:host mat-error {
  line-height: 16px;
}
:host .mt-8 {
  margin-top: 8px;
}
::ng-deep #select-slb-code-panel {
  max-height: 350px;
}
::ng-deep #select-slb-code-panel .mat-mdc-option {
  padding: 10px 0 5px 15px;
  height: 65px !important;
}
::ng-deep #select-slb-code-panel .mat-mdc-option .mdc-list-item__primary-text p {
  line-height: 1.1em;
}
::ng-deep #select-slb-code-panel .mat-mdc-option .mdc-list-item__primary-text p.code-description {
  font-size: 12px;
  color: #b0b0b0;
}
::ng-deep #select-slb-code-panel .mat-mdc-option:nth-child(odd) {
  background: #f0f2f3;
}
/*# sourceMappingURL=InsuranceAndHandlingFeeComponent.css.map */
