import { IBankAccountDto, IRetailerSignerDto } from '@abcfinlab/api/global';
import { ControlsOf } from '@abcfinlab/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    IBankAccountFormDto,
} from '../../Views/Dialogs/retailer-identification/RetailerIdentificationViewPresenter';

@Component({
    selector: 'l7-identify-signer',
    templateUrl: './identify-signer.component.html',
    styleUrls: ['./identify-signer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class IdentifySignerComponent {

    @Input() signerForm: FormGroup<ControlsOf<IRetailerSignerDto>>;
    @Input() bankAccountForm: FormGroup<ControlsOf<IBankAccountFormDto>>;

    @Output() bankAccountIsShowed: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    /**
     * Returns the property `showBankAccountFields`
     *
     * @public
     * @readonly
     */
    public get showBankAccountFields(): Observable<boolean> {
        return this._showBankAccountFields.asObservable();
    }

    /**
     * Returns the `bankAccount` property.
     *
     * @public
     * @readonly
     */
    public get bankAccount(): Observable<IBankAccountDto> {
        return this._bankAccount.asObservable();
    }

    private readonly _showBankAccountFields: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _bankAccount: BehaviorSubject<IBankAccountDto> = new BehaviorSubject<IBankAccountDto>(null);

    public setBankAccountFields(evt: MatCheckboxChange): void {
        this._showBankAccountFields.next(evt.checked);
        this.bankAccountIsShowed.emit(evt.checked);
        if (evt.checked) {
            this.bankAccountForm.controls.bankAccountName.addValidators(Validators.required);
            this.bankAccountForm.controls.bankAccountName.updateValueAndValidity();
            this.bankAccountForm.controls.iban.addValidators(Validators.required);
            this.bankAccountForm.controls.iban.updateValueAndValidity();
        } else {
            this.bankAccountForm.controls.bankAccountName.removeValidators([Validators.required]);
            this.bankAccountForm.controls.bankAccountName.updateValueAndValidity();
            this.bankAccountForm.controls.iban.removeValidators([Validators.required]);
            this.bankAccountForm.controls.iban.updateValueAndValidity();
        }
    }

    public transformToUpperCase(evt): void {
        const value: string = evt.target.value.toUpperCase();
        this.bankAccountForm.controls.iban.patchValue(value);
    }

    public handleBankAccountInfo(bankAccount: IBankAccountDto): void {
        this._bankAccount.next(bankAccount);
        this.bankAccountForm.controls.bankName.patchValue(bankAccount?.bank_name);
        this.bankAccountForm.controls.bic.patchValue(bankAccount?.bic);
    }

}
