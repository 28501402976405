// #region Imports

import { ILegalPersonalityDto } from '@abcfinlab/api/contact';
import { ILeasingQuoteConfirmationTypeDto } from '@abcfinlab/api/global';
import { CustomFile } from '@abcfinlab/presentation';
import { ToastService } from '@abcfinlab/ui';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WebcamImage } from 'ngx-webcam';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointService, DevicesByWidth } from '../../../../../../libs/layout/src/Services/BreakpointService';
import {
    BeneficialOwnersView,
} from '../../../../../contacts/src/Views/beneficial-owners-view/beneficial-owners-view.component';
import { RetailerIdentificationViewPresenter } from './RetailerIdentificationViewPresenter';

// #endregion

/**
 * The `RetailerIdentificationView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-retailer-identification-view',
    templateUrl: './RetailerIdentificationView.html',
    styleUrls: ['./RetailerIdentificationView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RetailerIdentificationViewPresenter,
    ],
    standalone: false,
})
export class RetailerIdentificationView implements OnInit, OnDestroy, AfterViewInit {

    // #region Fields

    private readonly _presenter: RetailerIdentificationViewPresenter;

    private readonly _dialogRef: MatDialogRef<RetailerIdentificationView>;

    private readonly _toastService: ToastService;

    private readonly _cdr: ChangeDetectorRef;

    private readonly _idType = new BehaviorSubject<'CAMERA' | 'UPLOAD'>(null);

    private readonly _showCapturedImage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private readonly _imageUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private readonly _side: BehaviorSubject<'FRONT' | 'BACK'> = new BehaviorSubject<'FRONT' | 'BACK'>(null);

    private readonly _cameraWidth: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    private readonly _triggerCamera: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private readonly _frontFile: BehaviorSubject<Array<CustomFile>> = new BehaviorSubject<Array<CustomFile>>(null);
    private readonly _backFile: BehaviorSubject<Array<CustomFile>> = new BehaviorSubject<Array<CustomFile>>(null);
    private readonly _addressFile: BehaviorSubject<Array<CustomFile>> = new BehaviorSubject<Array<CustomFile>>(null);

    private _captureType: 'CAMERA' | 'UPLOAD';

    private _frontImage: string | ArrayBuffer;

    private _backImage: string | ArrayBuffer;

    private _legalPersonality: ILegalPersonalityDto;

    private _isBankAccountPresent: boolean;

    private _isShortCutFlow: boolean;

    public validateCheckBoxIdentificationJuristicPerson: boolean = false;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RetailerIdentificationViewPresenter` class.
     *
     * @public
     */
    public constructor(presenter: RetailerIdentificationViewPresenter, dialogRef: MatDialogRef<RetailerIdentificationView>,
        cdr: ChangeDetectorRef, toastService: ToastService, breakPointService: BreakpointService) {
        this._presenter = presenter;
        this._dialogRef = dialogRef;
        this._cdr = cdr;
        this._toastService = toastService;
        breakPointService.deviceTypeByWidth$.pipe(untilDestroyed(this))
            .subscribe((deviceWidth) => {
                if (deviceWidth === DevicesByWidth.XSMALL || deviceWidth === DevicesByWidth.SMALL) {
                    this._cameraWidth.next(400);
                } else {
                    this._cameraWidth.next(550);
                }
            });
    }

    // #endregion

    // #region Properties
    @ViewChild('stepper') public stepperIdentification: MatStepper;

    @ViewChild('checkBankAccount') public checkBankAccount: MatCheckbox;

    @ViewChild('beneficialOwnersView') public beneficialOwnersView: BeneficialOwnersView;

    public readonly maxDate: Date = new Date();

    /**
     * Returns the presenter of the `RetailerIdentificationViewPresenter`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailerIdentificationViewPresenter {
        return this._presenter;
    }

    /**
     * Returns the property `idType`
     *
     * @public
     * @readonly
     */
    public get idType(): Observable<'CAMERA' | 'UPLOAD'> {
        return this._idType.asObservable();
    }

    /**
     * Returns the property `showCapturedImage`
     *
     * @public
     * @readonly
     */
    public get showCapturedImage(): Observable<boolean> {
        return this._showCapturedImage.asObservable();
    }

    /**
     * Returns the property `supportiveTitle`
     *
     * @public
     * @readonly
     */
    public get supportiveTitle(): string {
        let supportiveTitle: string = '';
        if (this.stepperIdentification) {
            switch (this.stepperIdentification.selected.label) {
                case 'IDENTIFY_SIGNER':
                    supportiveTitle = '';
                    break;
                case 'CHOOSE_TYPE_OF_ID':
                    supportiveTitle = 'Bitte wählen Sie eine der beiden Optionen';
                    break;
                case 'ID_FRONT':
                    if (this._captureType === 'CAMERA') {
                        supportiveTitle = this._showCapturedImage.value
                            ? 'Bitte überprüfen Sie die Aufnahme Ihres Ausweises auf Lesbarkeit und Qualität.'
                            : 'Bitte fotografieren Sie die Vorderseite des Personalausweises des Unterzeichners vollständig und gut lesbar.';
                    }
                    break;
                case 'ID_BACK':
                    if (this._captureType === 'CAMERA') {
                        supportiveTitle = this._showCapturedImage.value
                            ? 'Bitte überprüfen Sie die Aufnahme Ihres Ausweises auf Lesbarkeit und Qualität.'
                            : 'Bitte fotografieren Sie die Rückseite des Personalausweises des Unterzeichners vollständig und gut lesbar.';
                    }
                    break;
                default:
                    supportiveTitle = '';
                    break;
            }
        }
        return supportiveTitle;
    }

    /**
     * Returns the property `imageUrl`
     *
     * @public
     * @readonly
     */
    public get imageUrl(): Observable<string> {
        return this._imageUrl.asObservable();
    }

    /**
     * Returns the property `side`
     *
     * @public
     * @readonly
     */
    public get side(): Observable<'FRONT' | 'BACK'> {
        return this._side.asObservable();
    }

    public get cameraWidth(): Observable<number> {
        return this._cameraWidth.asObservable();
    }

    public get triggerCamera(): Observable<boolean> {
        return this._triggerCamera.asObservable();
    }

    public get frontFile(): Observable<Array<CustomFile>> {
        return this._frontFile.asObservable();
    }

    public get backFile(): Observable<Array<CustomFile>> {
        return this._backFile.asObservable();
    }

    public get addressFile(): Observable<Array<CustomFile>> {
        return this._addressFile.asObservable();
    }

    public get showBtnNext(): boolean {
        return (this.stepperIdentification?.selected.label === 'IDENTIFY_SIGNER'
            || (this.stepperIdentification?.selected.label === 'CONFIRM_ID_SIGNER' && this._legalPersonality === ILegalPersonalityDto.J)
            || this.stepperIdentification?.selected.label === 'ADD_BO') && !this._isShortCutFlow;
    }

    public get showBtnBack(): boolean {
        return (this.stepperIdentification?.selected.label === 'CONFIRM_ID_SIGNER'
            || (this.stepperIdentification?.selected.label === 'ADD_BO' && this.stepperIdentification?.steps.first.label !== 'ADD_BO')
            || this.stepperIdentification?.selected.label === 'CONFIRM_BO') && !this._isShortCutFlow;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
        this.presenter.frontImage.pipe(untilDestroyed(this))
            .subscribe(img => this._frontImage = img);
        this.presenter.backImage.pipe(untilDestroyed(this))
            .subscribe(img => this._backImage = img);
        this.presenter.identificationCompleted.pipe(untilDestroyed(this))
            .subscribe((res) => {
                if (res) {
                    this._dialogRef.close(res);
                }
            });
        this.presenter.legalPersonality.pipe(untilDestroyed(this))
            .subscribe(x => this._legalPersonality = x);
        this.presenter.showBankAccountFields.pipe(untilDestroyed(this))
            .subscribe(x => this._isBankAccountPresent = x);
        this.presenter.isShortCutFlow.pipe(untilDestroyed(this))
            .subscribe(x => this._isShortCutFlow = x);
    }

    public ngAfterViewInit(): void {
        if (this.stepperIdentification.selected.label === 'CHOOSE_TYPE_OF_ID') {
            this._dialogRef.removePanelClass('fit-to-content');
            this._dialogRef.addPanelClass('dialog-with-width');
        }
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    /**
     * @internal
     */
    public onClose(): void {
        this._dialogRef.close();
    }

    public next(): void {
        const nextStep = () => {
            if (this.stepperIdentification.selected.label === 'IDENTIFY_SIGNER') {
                this._dialogRef.addPanelClass('dialog-with-width');
            }
            this.stepperIdentification.next();
        };

        let isFormValid = this.presenter.form.get('signer.firstName').valid
            && this.presenter.form.get('signer.lastName').valid
            && !this.presenter.form.get('signer.emailAddress').hasError('required') && !this.presenter.form.get('signer.emailAddress').hasError('email');

        switch (this.stepperIdentification.selected.label) {
            case 'IDENTIFY_SIGNER':
                if (this._isBankAccountPresent) {
                    isFormValid &&= this.presenter.form.get('bankAccount.iban').valid && this.presenter.form.get('bankAccount.bankAccountName').getRawValue().length >= 2;
                }
                break;
            case 'CONFIRM_ID_SIGNER':
                this.validateCheckBoxIdentificationJuristicPerson = true;
                isFormValid &&= this.presenter.form.controls.leasingQuoteConfirmationTypes.getRawValue().includes(ILeasingQuoteConfirmationTypeDto.IdentificationJuristicPerson);
                break;
            case 'ADD_BO':
                this.beneficialOwnersView?.validateBeneficialOwners();
                isFormValid &&= this.beneficialOwnersView?.isBeneficialOwnersListConfirmed && this.beneficialOwnersView.beneficialOwnersList.length > 0 && this.beneficialOwnersView?.beneficialOwnersForm?.valid;
                break;
        }

        if (isFormValid && this.stepperIdentification.selected.label === 'ADD_BO') {
            this.presenter.saveBulk(this.beneficialOwnersView.beneficialOwnersList).pipe(untilDestroyed(this))
                .subscribe(_ => nextStep());
        } else if (isFormValid && this.stepperIdentification.selected.label !== 'ADD_BO') {
            nextStep();
        } else {
            this.presenter.form.markAllAsTouched();
        }
    }

    public takeSignatoryAsBeneficialOwner(): void {
        this.beneficialOwnersView.takeSignatoryAsBeneficialOwner({
            firstName: this.presenter.form.controls.signer.controls.firstName.value,
            lastName: this.presenter.form.controls.signer.controls.lastName.value,
        });
    }

    public chooseIDType(type: 'CAMERA' | 'UPLOAD'): void {
        this._idType.next(type);
        this._side.next('FRONT');
        this._captureType = type;
        this.stepperIdentification.next();
    }

    public triggerCapture(): void {
        this._triggerCamera.next(true);
    }

    public showImage(image: WebcamImage): void {
        if (this._side.getValue() === 'FRONT') {
            this.presenter.setIdImage('FRONT', image.imageAsDataUrl);
        } else {
            this.presenter.setIdImage('BACK', image.imageAsDataUrl);
        }

        this._imageUrl.next(image.imageAsDataUrl);
        this._showCapturedImage.next(true);
        this._cdr.detectChanges();
    }

    public retryCapture(): void {
        this._showCapturedImage.next(false);
        this._triggerCamera.next(false);
    }

    public nextFileId(): void {
        if (this._captureType === 'UPLOAD') {
            if ((this.stepperIdentification.selected.label === 'ID_FRONT' && this._frontImage === null)
                || (this.stepperIdentification.selected.label === 'ID_BACK' && this._backImage === null)) {
                this._toastService.show('Bitte laden Sie ein Dokument hoch', 'danger', 'long');
                return;
            }
        }
        if (this.stepperIdentification.selected.label === 'ID_BACK') {
            this._dialogRef.removePanelClass('dialog-with-width');
        } else {
            this._dialogRef.addPanelClass('dialog-with-width');
        }
        this._side.next('BACK');
        this._showCapturedImage.next(false);

        this._triggerCamera.next(false);
        this.stepperIdentification.next();
    }

    public saveUploadedFile(files: Array<CustomFile>, type: 'FRONT' | 'BACK' | 'ADDRESS'): void {
        const reader = new FileReader();
        const imagePath = files;
        if (type === 'FRONT') {
            this._frontFile.next(files);
        } else if (type === 'BACK') {
            this._backFile.next(files);
        } else {
            this._addressFile.next(files);
        }
        if (typeof files[0] === 'object') {
            reader.readAsDataURL(files[0]);
            reader.onload = (_event: any) => {
                const imageDataBase64 = reader.result as string;
                const pdfOptionsUrl = _event.target.result;
                this.presenter.setIdImage(type, imageDataBase64);
            };
        } else {
            this.presenter.setIdImage(type, null);
        }
    }

    public backToIdDecision(): void {
        switch (this.stepperIdentification.selected.label) {
            case 'CONFIRM_ID_SIGNER':
                this._dialogRef.addPanelClass('dialog-with-width');
                break;
            case 'ID_FRONT':
                this._side.next('FRONT');
                break;
            case 'ID_BACK':
                this._side.next('FRONT');
                break;
        }

        this.stepperIdentification.previous();
    }

    public setSupportiveTitleText(): void {

    }

    // #endregion
    protected readonly ILegalPersonalityDto = ILegalPersonalityDto;

}
