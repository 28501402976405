/* libs/quote/src/Components/quote-version-menu/quote-version-menu.component.scss */
.mat-mdc-menu-item .mat-icon {
  margin-right: 5px;
  vertical-align: text-bottom;
}
:host {
  display: flex;
  flex-direction: row;
}
:host .icon {
  width: 24px;
  transition: all 200ms ease-in-out;
  text-align: center;
}
:host .icon:hover {
  transform: scale(1.25);
  cursor: pointer;
}
:host .mat-icon {
  font-size: 18px;
  height: 20px;
  width: 24px;
}
:host .mat-icon.Trash {
  margin-left: 5px;
}
:host .mat-icon:last-child {
  width: auto;
}
:host .mat-badge {
  width: 20%;
  text-align: center;
}
:host .mat-badge-content {
  background: #ffb500;
  color: #b81931;
  width: 11px;
  height: 11px;
  left: 23px !important;
  top: 0px;
  line-height: 11px;
  font-size: 6px;
  text-align: center;
}
:host .icon-link.EXPIRED {
  color: #cccccc;
}
:host .icon-link.EXPIRED:hover {
  cursor: not-allowed;
}
:host .icon-link.INVALID {
  color: #cccccc;
}
:host .icon-link.INVALID:hover {
  cursor: not-allowed;
}
:host .icon-link.VALID {
  color: #55ed9c;
}
:host .icon-vendor.OPEN {
  color: #000000;
}
:host .icon-vendor.CAPTURED {
  color: #55ed9c;
}
:host .icon-lessee.POSITIVE,
:host .icon-lessee.APPROVED_WITH_CONDITIONS,
:host .icon-lessee.APPROVED_WITH_CONDITIONS_FULFILLED {
  color: #55ed9c;
}
:host .icon-lessee.NEGATIVE {
  color: #bf4128;
}
:host .icon-lessee.EXPIRED {
  color: #ffce0a;
}
:host .icon-lessee.OPEN {
  color: #000000;
}
:host .icon-lessee.PENDING {
  color: #6495ed;
}
:host .icon-lessee.APPROVED_WITH_CONDITIONS,
:host .icon-lessee.APPROVED_WITH_CONDITIONS_FULFILLED {
  color: #55ed9c;
}
:host .disabled_option {
  cursor: not-allowed;
  pointer-events: all !important;
}
:host .disabled_option:hover {
  cursor: not-allowed;
}
/*# sourceMappingURL=quote-version-menu.component.css.map */
