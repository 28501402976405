!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? exports.TSerializer = t() : e.TSerializer = t();
}(this, function () {
  return function (e) {
    var t = {};
    function r(n) {
      if (t[n]) return t[n].exports;
      var i = t[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return e[n].call(i.exports, i, i.exports, r), i.l = !0, i.exports;
    }
    return r.m = e, r.c = t, r.d = function (e, t, n) {
      r.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: n
      });
    }, r.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, r.t = function (e, t) {
      if (1 & t && (e = r(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var n = Object.create(null);
      if (r.r(n), Object.defineProperty(n, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var i in e) r.d(n, i, function (t) {
        return e[t];
      }.bind(null, i));
      return n;
    }, r.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return r.d(t, "a", t), t;
    }, r.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, r.p = "/", r(r.s = 1);
  }([function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var n = function () {
      function e() {}
      return e.deserializeItem = function (e, t) {
        if (e.optional && !o(t)) return null;
        if (e.type) {
          var r = new e.type();
          return r.deserialize(t), r;
        }
        return t;
      }, e.serializeItem = function (e, t) {
        return e.optional && !o(t) ? null : e.type ? t.serialize() : t;
      }, e.serialize = function (e, t, r) {
        var n = this,
          o = {};
        return Object.keys(e.prototype._serializeMap).forEach(function (u) {
          var a = i(t[u]),
            l = e.prototype._serializeMap[u],
            c = l.root || r.root || null,
            s = l.map || l.name,
            f = o;
          c && "." !== c && (o[c] || (o[c] = {}), f = o[c]), l.list ? f[s] = a ? a.map(function (e) {
            return n.serializeItem(l, e);
          }) : [] : f[s] = n.serializeItem(l, a);
        }), o;
      }, e.deserialize = function (e, t, r, n) {
        var o = this;
        void 0 === n && (n = {}), Object.keys(e.prototype._serializeMap).forEach(function (u) {
          var a = e.prototype._serializeMap[u],
            l = a.root || n.root || null,
            c = a.map || a.name || "",
            s = i(r[c]);
          l && "." !== l && function (e) {
            return null != e;
          }(r[l]) && (s = r[l][c]), a.list ? t[u] = s ? s.map(function (e) {
            return o.deserializeItem(a, e);
          }) : [] : t[u] = o.deserializeItem(a, s);
        });
      }, e;
    }();
    function i(e) {
      return e || (!1 === e || 0 === e ? e : null);
    }
    function o(e) {
      return !!e || !1 === e || 0 === e;
    }
    t.Serializer = n;
  }, function (e, t, r) {
    "use strict";

    function n(e) {
      for (var r in e) t.hasOwnProperty(r) || (t[r] = e[r]);
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), n(r(2)), n(r(3)), n(r(4)), n(r(0));
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var n = r(0);
    t.Serialize = function (e) {
      return function (t) {
        t.prototype.deserialize = function (r) {
          n.Serializer.deserialize(t, this, r, e);
        }, t.prototype.serialize = function () {
          return n.Serializer.serialize(t, this, e);
        };
      };
    };
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.SerializeProperty = function (e) {
      return void 0 === e && (e = {}), function (t, r) {
        t.constructor.prototype._serializeMap || (t.constructor.prototype._serializeMap = {}), e.name = r, t.constructor.prototype._serializeMap[r] = e;
      };
    };
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var n = function () {
      function e() {
        this._serializeMap = {};
      }
      return e.prototype.serialize = function () {
        throw new Error("This is an abstract method. It needs to be overridden.");
      }, e.prototype.deserialize = function (e) {
        throw new Error("This is an abstract method. It needs to be overridden.");
      }, e;
    }();
    t.Serializable = n;
  }]);
});
