<l7-page [isEmpty]="(presenter.objectGroups | async) === null">
    <l7-page-header [subTitle]="(presenter.lessee | async)?.name"
                    [supportiveTitle]="(presenter.lessee | async) ? ('global.nav_contact' | translate) + ': ' + (presenter.lessee | async)?.nav_contact_number : ''"
                    [title]="'quote.calculation.view.title' | translate">
    </l7-page-header>

    <l7-page-content fxFlex fxLayoutGap="16px">
        <div fxLayout="row" fxLayoutGap="16px">
            <div fxFlex="60" fxFlex.lt-md="100" fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="row">
                    <l7-calculation-form [form]="presenter.form"
                                         [objectGroups]="presenter.objectGroups | async"
                                         [lessee]="presenter.lessee | async"
                                         [objectForm]="presenter.objectForm"
                                         [initialFormState]="presenter.initialFormState"
                                         [calculationSettings]="(presenter.calculationSettings | async)"
                                         [leasingFactorValue]="(presenter.calculationResponse | async)?.quoteCalculation?.leasingFactorValue"
                                         fxFlex="100">
                    </l7-calculation-form>
                </div>
                <div fxLayout="row">
                    <l7-calculation-extra-payments [form]="presenter.form"
                                                   [minResidualValue]="(presenter.calculationSettings | async)?.residualValueSettings.min"
                                                   [downPaymentSettings]="(presenter.calculationSettings | async)?.downPaymentSettings"
                                                   [quoteCalculationResponse]="(presenter.calculationResponse | async)?.quoteCalculation"
                                                   [contractType]="presenter.form.controls.contractType.getRawValue()"
                                                   [valueToCalculate]="presenter.form.controls.valueToCalculate.getRawValue()"
                                                   [hasDownPayment]="presenter.hasDownPayment | async"
                                                   [hasResidualValue]="presenter.hasResidualValue | async"
                                                   [hasFirstInstalment]="presenter.hasFirstInstalment | async"
                                                   [hasLastInstalment]="presenter.hasLastInstalment | async"
                                                   fxFlex="100">
                    </l7-calculation-extra-payments>
                </div>
            </div>
            <div fxFlex="40" fxFlex.lt-md="100" fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="row">
                    <l7-insurance-and-handling-fee [form]="presenter.form.controls.insuranceAndHandlingFeeRequest"
                                                   [insuranceAndSlbForm]="presenter.insuranceAndSlbForm"
                                                   [selectedObjectGroup]="presenter.selectedObjectGroup | async"
                                                   [contractType]="presenter.form.controls.contractType.getRawValue()"
                                                   [totalLeasingValue]="presenter.form.controls.quoteCalculationRequest?.controls.totalLeasingValue.getRawValue()"
                                                   [insuranceAndHandlingFeeFromOriginalQuote]="presenter.insuranceAndHandlingFeeFromOriginalQuote | async"
                                                   [lessee]="presenter.lessee | async"
                                                   [insuranceAndHandlingFeeResponse]="(presenter.calculationResponse | async)?.insuranceAndHandlingFee"
                                                   [slbCodes]="presenter.slbCodes | async"
                                                   [smbCodes]="presenter.smbCodes | async"
                                                   fxFlex="100">
                    </l7-insurance-and-handling-fee>
                </div>
                <div fxLayout="row">
                    <l7-refinancing-interest [form]="presenter.form"
                                             [contractType]="presenter.form.controls.contractType.getRawValue()"
                                             [refinancingInterestResponse]="(presenter.calculationResponse | async)?.refinancingInterestResponse"
                                             [exploitationCode]="(presenter.selectedObjectGroup | async)?.exploitation_code"
                                             fxFlex="100">
                    </l7-refinancing-interest>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center" class="margin-top">
            <ng-container *ngIf="!(presenter.quoteId | async)">
                <button mat-button
                        [color]="'accent'"
                        (click)="goToDashboard()"
                        test-id="buttonAbortCalculation">
                    {{ 'global.cancel' | translate }}
                </button>
                <button mat-flat-button
                        [color]="'accent'"
                        *ngIf="presenter.lessee | async"
                        (click)="presenter.saveQuote(false)"
                        test-id="buttonSaveCalculation"
                        [disabled]="presenter.form.invalid || presenter.objectForm.invalid || presenter.insuranceAndSlbForm.invalid">
                    {{ 'Angebot speichern' |translate }}
                </button>
            </ng-container>
            <ng-container *ngIf="(presenter.quoteId | async)">
                <div class="button-row">
                    <button tabindex="-1"
                            mat-button
                            [color]="'accent'"
                            (click)="backToDetailView()"
                            test-id="button_back-to-detail-view">
                        {{ 'Zurück zur Detailansicht' | translate }}
                    </button>
                    <button mat-flat-button
                            [color]="'accent'"
                            (click)="presenter.saveQuote(true)"
                            test-id="button_create-new-version"
                            [disabled]="presenter.form.invalid || presenter.objectForm.invalid || presenter.insuranceAndSlbForm.invalid">
                        {{ 'Neue Version speichern' | translate }}
                    </button>
                </div>
            </ng-container>
        </div>

    </l7-page-content>
</l7-page>
