import { IUserInfoDto } from '@abcfinlab/api/login';
import { IUserGroupLoginsDto, IUserGroupRetailerDto } from '@abcfinlab/auth';
import { FeatureManager, RoleManager, RoleModule, SESSION_AVAILABLE_TOKEN, TranslationFacade, TranslationModule, USER_INFO_TOKEN } from '@abcfinlab/core';
import { PresenterService } from '@abcfinlab/layout';
import { Inject, NgModule } from '@angular/core';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import {
    QUOTE_CALCULATION_PLAYGROUND_ROUTE_PATH,
    QUOTE_CALCULATION_ROUTE_PATH,
    QUOTE_RETAILER_CREATE_QUOTE_ROUTE_PATH,
    QUOTE_RETAILER_LESSEES_ROUTE_PATH,
    QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH,
    QUOTE_SEARCH_LESSEE_ROUTE_PATH,
    RETAILER_QUOTES_LIST_ROUTE_PATH,
} from '../Routing/RoutePaths';
import { CREATE_QUOTE_CHOOSE_LESSEE_ROUTE, QUOTE_CREATE_CALCULATION_ROUTE } from '../Routing/Routes';

@NgModule({
    imports: [
        TranslationModule,
        RoleModule,
    ],
})
export class QuoteNavigationModule {

    // #region Ctor

    /**
     * Constructs a new instance of the `QuoteNavigationModule` class.
     *
     * @public
     */
    public constructor(@Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>, presenterService: PresenterService, roleManager: RoleManager, translationFacade: TranslationFacade, featureManager: FeatureManager) {
        combineLatest([sessionAvailable, userInfo])
            .pipe(first())
            .subscribe(([_, user]) => {
                if (user.groups.includes(IUserGroupLoginsDto.Sales) || user.groups.includes(IUserGroupLoginsDto.SalesAssistance)) {
                    presenterService.setNavigation({
                        label: translationFacade.instant('Angebot / Vertrag erstellen'),
                        icon: 'File_Agreement',
                        route: QUOTE_SEARCH_LESSEE_ROUTE_PATH,
                        order: 20,
                        relatedTo: [
                            { link: `/presentation/${CREATE_QUOTE_CHOOSE_LESSEE_ROUTE.path}` },
                            { link: `/presentation/${QUOTE_CREATE_CALCULATION_ROUTE.path}` },
                        ],
                    });
                    const calculationRoutePath = featureManager.active('newCalculation') ? QUOTE_CALCULATION_PLAYGROUND_ROUTE_PATH : QUOTE_CALCULATION_ROUTE_PATH;
                    presenterService.setNavigation({
                        label: translationFacade.instant('Kalkulieren'),
                        icon: 'Calc',
                        route: calculationRoutePath,
                        order: 40,
                    });
                }
                if (user.groups.includes(IUserGroupRetailerDto.RetailerSales)) {
                    presenterService.setNavigation({
                        label: translationFacade.instant('Angebot / Vertrag erstellen'),
                        icon: 'File_Agreement',
                        order: 20,
                        route: QUOTE_RETAILER_CREATE_QUOTE_ROUTE_PATH,
                    });
                    presenterService.setNavigation({
                        label: translationFacade.instant('Kalkulierte Angebote'),
                        icon: 'File_Calculator',
                        order: 30,
                        route: QUOTE_RETAILER_LESSEES_ROUTE_PATH,
                        relatedTo: [
                            `/presentation/${RETAILER_QUOTES_LIST_ROUTE_PATH}`,
                            `/presentation/${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}`,
                        ],
                    });
                }
            });
    }

    // #endregion

}
