/* libs/quote/src/Views/Dialogs/retailer-bonicheck-pending/retailer-bonicheck-pending.component.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: block;
  position: relative;
}
:host h2 {
  margin-bottom: 32px;
}
:host p {
  line-height: 24px;
  color: var(--l7-grey-900-color);
}
:host a {
  font-weight: 900;
  color: var(--l7-info-500-color);
  text-decoration: none;
}
:host l7-dialog-header .btn-close {
  position: absolute;
  right: 8px;
  z-index: 10000;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  font-weight: 900;
}
:host l7-dialog-header .btn-close:hover {
  cursor: pointer;
}
/*# sourceMappingURL=retailer-bonicheck-pending.component.css.map */
