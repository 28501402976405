<div fxFlex
     fxLayout="column">
    <l7-dialog-header [title]="'Wirtschaftlich Berechtigte' | translate"
                      [supportiveTitle]="'Ich bestätige, dass der Vertragsprozess mit folgenden Informationen zu den wirtschaftlich Berechtigten gestartet werden soll.' | translate"></l7-dialog-header>
    <mat-dialog-content fxFlex
                        fxLayout="column"
                        fxLayoutGap="8px">
        <ul>
            <li *ngFor="let person of (this.presenter.data | async).persons"
                style="display: list-item;">
                <div fxFlex
                     fxLayout="row">
                    <div fxFlex
                         fxLayout="row"
                         fxLayoutAlign="start center"
                         fxLayoutGap="8px">
                        <l7-text-block fxFlex
                                       [text]="person.firstName + ' ' + person.lastName"></l7-text-block>
                        <l7-text-block fxFlex
                                       [text]="'Geb. ' + (person.dateOfBirth | date | na)"></l7-text-block>
                        <div fxFlex>
                            <l7-text-block *ngIf="person.fictitious"
                                           [text]="'(' + 'Fiktiv'  + ')'"></l7-text-block>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button
                color="accent"
                [mat-dialog-close]="false"
                [attr.test-id]="'CancelButton'">{{ 'global.cancel' | translate }}</button>
        <button mat-flat-button
                color="accent"
                [mat-dialog-close]="true"
                [attr.test-id]="'FurtherButton'">{{ 'global.further' | translate }}</button>
    </mat-dialog-actions>
</div>