/* libs/quote/src/Components/quote-summary-card-obligations/quote-summary-card-obligations.component.scss */
:host {
  width: 100%;
  padding: 16px 16px;
}
:host h3 {
  margin-bottom: 0;
  font-weight: 600;
}
:host .obligations-headline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
:host .obligations-icon {
  margin-right: 8px;
}
:host .obligations-warning {
  color: var(--l7-warning-600-color);
}
:host .obligations-list {
  display: flex;
  margin: 16px 0;
}
:host .obligations-item {
  padding-right: 32px;
  max-width: 25%;
  width: 25%;
}
:host .obligations-item fieldset {
  height: 100%;
}
:host .obligations-item legend {
  font-size: small;
  margin-bottom: 8px;
  line-height: 1;
  color: #83929b;
  width: 100%;
}
/*# sourceMappingURL=quote-summary-card-obligations.component.css.map */
