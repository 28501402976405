import { BeneficialOwnerService } from '@abcfinlab/api/contact';
import { Injectable } from '@angular/core';

@Injectable()

export class BeneficialOwnersViewPresenter {

    constructor() {
    }

}
